let projectsMap = {
    "projects": {
      "leadgen": {
        "meta": {
          "spends": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          // "costPerConversion": "CPL"
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          // "costPerConversion": "CPL"
        },
        "leadSquared": {
          "CRMadmission": "CRM Admission",
          "CRMadmissionInvalid": "CRM Admission Invalid",
          "CRMqualified": "CRM Qualified",
          "CRMqualifiedInvalid": "CRM  Qualified Invalid",
          "CRMtotal": "CRM Total",
          "CRMtotalInvalid": "CRM Total Invalid"
        },
        "withLeadSalesforce": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          "sales": "Sales",
          "qualityLeads": "Quality Leads",
          "costPerQualityLeads": "CPQL",
          "siteVisits": "Site Visits",
        },
        "withLeadSquared": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Leads",
          "cvr": "CVR",
          // "ROAS": "ROAS",
          "cpl": "CPL",
          "CRMadmission": "CRM Admission",
          "CRMadmissionInvalid": "CRM Admission Invalid",
          "CRMqualified": "CRM Qualified",
          "CRMqualifiedInvalid": "CRM  Qualified Invalid",
          "CRMtotal": "CRM Total",
          "CRMtotalInvalid": "CRM Total Invalid"
        },
        "withoutLeadSquared": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Conversions",
          "ROAS": "ROAS",
          "conversionValue": "Conversion Value"
        },
      },
      "default": {
        "meta": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "costPerResult": "CPR",
          "conversions": "Conversions",
          "ctr": "CTR",
          "conversionValue": "Conversion Value",
          "ROAS": "ROAS",
          "webPurchases": "Web Purchases",
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Conversions",
          "ROAS": "ROAS",
          "conversionValue": "Conversion Value"
        },
        "withLeadSquared": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Conversions",
          "ROAS": "ROAS",
          "conversionValue": "Conversion Value",
          "CRMadmission": "CRM Admission",
          "CRMadmissionInvalid": "CRM Admission Invalid",
          "CRMqualified": "CRM Qualified",
          "CRMqualifiedInvalid": "CRM  Qualified Invalid",
          "CRMtotal": "CRM Total",
          "CRMtotal": "CRM Total"
        },
        "withoutLeadSquared": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Conversions",
          "ROAS": "ROAS",
          "conversionValue": "Conversion Value"
        },

      }
    },
    "zoneWiseData": {
      "leadgen": {
        "meta": {
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          "actions":"action"
          // "costPerConversion": "CPL"
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
           "actions":"action"
          // "costPerConversion": "CPL"
        }
      },
      "default": {
        "meta": {
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "costPerResult": "CPR",
          "conversions": "Conversions",
          "ctr": "CTR",
          "conversionValue": "Conversion Value",
          "ROAS": "ROAS",
          //  "actions":"action"
          
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Conversions",
          "ROAS": "ROAS",
          "conversionValue": "Conversion Value",
          //  "actions":"action"
        }
      }
    },
    "zoneFilteredWiseData": {
      "leadgen": {
        "meta": {
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          
          // "costPerConversion": "CPL"
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
         
          // "costPerConversion": "CPL"
        },
        "withLeadSalesforce": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          "sales": "Sales",
          "qualityLeads": "Quality Leads",
          "costPerQualityLeads": "CPQL",
          "siteVisits": "Site Visits",
        },

      },
      "default": {
        "meta": {
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "costPerResult": "CPR",
          "conversions": "Conversions",
          "ctr": "CTR",
          "conversionValue": "Conversion Value",
          "ROAS": "ROAS",
          //  "actions":"action"
          
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Conversions",
          "ROAS": "ROAS",
          "conversionValue": "Conversion Value",
          //  "actions":"action"
        }
      }
    },
    "zoneGoogleWiseData": {
      "leadgen": {
        "meta": {
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          // "costPerConversion": "CPL"
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          // "costPerConversion": "CPL"
        }
      },
      "default": {
        "meta": {
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "costPerResult": "CPR",
          "conversions": "Conversions",
          "ctr": "CTR",
          "conversionValue": "Conversion Value",
          "ROAS": "ROAS",
          //  "actions":"action"
          
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Conversions",
          "ROAS": "ROAS",
          "conversionValue": "Conversion Value",
          //  "actions":"action"
        }
      }
    },
    "metaZoneWiseData": {
      "leadgen": {
        "meta": {
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          // "costPerConversion": "CPL"
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          // "costPerConversion": "CPL"
        }
      },
      "default": {
        "meta": {
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "costPerResult": "CPR",
          "conversions": "Conversions",
          "ctr": "CTR",
          "conversionValue": "Conversion Value",
          "ROAS": "ROAS",
          //  "actions":"action"
          
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Conversions",
          "ROAS": "ROAS",
          "conversionValue": "Conversion Value",
          //  "actions":"action"
        }
      }
    },
    "productWiseData": {
      "leadgen": {
        "meta": {
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          // "costPerConversion": "CPL"
        },
        "googleAds": {
          "spend": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          // "costPerConversion": "CPL"
        },
        "withLeadSalesforce": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpm": "CPM",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Leads",
          "cvr": "CVR",
          "cpc": "CPL",
          "sales": "Sales",
          "qualityLeads": "Quality Leads",
          "costPerQualityLeads": "CPQL",
          "siteVisits": "Site Visits",
        },
      },
      "default": {
        "meta": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "costPerResult": "CPR",
          "conversions": "Conversions",
          "ctr": "CTR",
          "conversionValue": "Conversion Value",
          "ROAS": "ROAS"
        },
        "googleAds": {
          "costMicros": "Spends",
          "impressions": "Impressions",
          "clicks": "Clicks",
          "cpcl": "CPC",
          "ctr": "CTR",
          "conversions": "Conversions",
          "ROAS": "ROAS",
          "conversionValue": "Conversion Value"
        }
      }
    }
  }
  export default projectsMap