<template>
  <div class="d2scale-ai-chat-boat-container">
    <b-card
      class="chat_body"
      style="background-color: #F5F5F5; padding:10px 10px;"
    >
      <div
        v-if="selectedAiClient === 'Select Client'"
        style="text-align: center;justify-content:center; align-items:center; padding:10px"
      >
        <img
          src="@/assets/images/logo/d2scale-Bot.png"
          alt="access-control"
          style="max-width: 80px; margin-top: -2px;"
        />
      </div>
      <div
        v-if="selectedAiClient === 'Select Client'"
        style="padding: 10px;text-align: center;justify-content:center;"
      >
        <h3 class="app-title md">
          Hello, {{ this.adminFirstName }}! Am Ready For Help You
        </h3>
        <p style="font-weight: 500;">
          Hi, I'm <b>D2Scale-AI</b>,I can help you find the Matrix you're
          looking for! Please describe the Matrix you need, and I'll provide the
          best match.
          <!-- Ask me anything what's are on your mind. Am here to assist you! -->
        </p>
        <div
          style="align-content:center; justify-content:center; text-align:center; margin-top:20px"
        >
          <h5 style="font-weight: 500;">
            <b>Please select your client to proceed.</b>
            <!-- Ask me anything what's are on your mind. Am here to assist you! -->
          </h5>
          <div>
            <select class="select-ai-dropdown" v-model="selectedAiClient">
              <option value="Select Client"><b>Select Your Client</b></option>
              <option
                v-for="onetype in fetchAiClientsList"
                :value="onetype"
                class="form-control"
                id="lg_type"
                name="lg_type"
                v-bind:key="onetype._id"
              >
                {{ onetype.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div
        v-if="selectedAiClient === 'Select Client'"
        style="align-content:center; justify-content:center; text-align:center; margin-top:20px"
      >
        <img
          src="@/assets/images/logo/ai.svg"
          alt="access-control"
          style="max-width: 400px; margin-top: -2px;"
        />
      </div>
      <div v-if="selectedAiClient !== 'Select Client'">
        <div
          style="display: flex; justify-content:space-between; margin-left:-15px;margin-right:-7px; margin-top:-10px; margin-bottom:20px"
        >
          <div
            style="text-align: left;justify-content:left; align-content:left; padding:10px; display:flex"
          >
            <img
              src="@/assets/images/logo/d2scale-Bot.png"
              alt="access-control"
              style="max-width: 60px; margin-top: -12px;"
            />
            <h3 class="app-title md" style="margin-left: 5px;">
              <div class="text-icon-wrapper">
                <span>D2Scale-AI</span>
                <b-icon
                  icon="circle-fill"
                  style="margin-left: 4px;"
                  font-scale="0.8"
                  color="#41B06E"
                  class="dotLive"
                />
              </div>
            </h3>
          </div>
          <div
            style="padding: 5px;text-align:left;justify-content:left; margin-top: -5px;"
          >
            <div
              style="align-content:left; justify-content:left; text-align:left; "
            >
              <h5 style="font-weight: 500;">
                <b>Select Client</b>
              </h5>
              <div>
                <select
                  class="select-ai-diff-dropdown"
                  v-model="selectedAiClient"
                >
                  <option value="Select Client"
                    ><b>Select Your Client</b></option
                  >
                  <option
                    v-for="onetype in fetchAiClientsList"
                    :value="onetype"
                    class="form-control"
                    id="lg_type"
                    name="lg_type"
                    v-bind:key="onetype._id"
                  >
                    {{ onetype.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="chatContainer"
      ref="chatContainer"
      v-if="selectedAiClient !== 'Select Client'"
      style="min-height:65vh; max-height:65vh; overflow-y: auto; mb-2"
      >
        <div class="chat-div mb-2" v-if="selectedAiClient !== 'Select Client'">
          <div
            v-for="(item, index) in items"
            :key="index"
            :style="getBadgeStyle(item)"
            class="badge"
          >
            <span @click="selectQuery(item)">{{ item }}</span>
          </div>
        </div>
        <div  v-if="this.selectedUserQuery.length > 0" style="background-color: #ffffff; border-radius: 5px; min-height:100px; margin:10px 0px;">
          <!-- Render chat messages dynamically -->
          <div v-if="this.chatMessages.length > 0" >
            <div v-for="(message, index) in chatMessages" :key="index">
              <div
              >
                <div style="padding: 10px;">
                  <div class="chat-wrapper">
                    <div class="chat-message">
                      <div class="message-content">
                        <div class="name" style="margin-left: 10px; text-align:right">
                          {{ message.adminFirstName }}
                        </div>
                        <div class="chat-bubble" style="margin-left: 10px; min-width:200px">
                          {{ message.selectedUserQuery }}
                        </div>
                        <span class="time-stamp-user">{{ message.timeStamp }}</span>
                      </div>
                      <div v-if="message.profileImage && message.profileImage.length > 9">
                        <img :src="message.profileImage" alt="User" class="profile-image" />
                      </div>
                      <div v-else>
                        <b-avatar style="margin-left: 10px;" variant="primary" :text="message.adminFirstName[0]"></b-avatar>
                      </div>
                    </div>
                  </div>
        
                  <div class="message-container">
                    <b-avatar variant="primary" text="D"></b-avatar>
                    <div class="message-content">
                      <div class="name" style="margin-left: 10px;">D2Scale-AI</div>
                      <div class="chat-bubble" style="margin-left: 10px;">
                        <span v-if="message.isChatIsEnabled">
                          <ChatSpinner />
                        </span>
                        <span v-else>
                          <span v-if="message.channelType === 'google-ads' || message.channelType === 'meta'">
                            <div class="controls" v-if="message.channelType === 'google-ads'">
                              <input
                                type="text"
                                v-model="searchQuery"
                                placeholder="Search..."
                                class="search-input"
                              />
                              <feather-icon
                                @click="exportToCSV"
                                color="#7c0cfc"
                                icon="DownloadIcon"
                                size="25"
                                style="margin-left: 5px; margin-top: 10px; margin-right: 5px; cursor: pointer"
                              />
                            </div>
                            <div class="controls" v-if="message.channelType === 'meta'">
                              <feather-icon
                                @click="exportToMetaCSV(message.filteredCampaignData)"
                                color="#7c0cfc"
                                icon="DownloadIcon"
                                size="25"
                                style="margin-left: 5px; margin-top: 10px; margin-right: 5px; cursor: pointer"
                              />
                            </div>
        
                            <!-- Table for Google Ads -->
                            <div class="chat-table-container">
                              <table v-if="message.channelType === 'google-ads'" class="styled-table">
                                <thead>
                                  <tr v-if="message.selectedAiClient.businessType[0] === 'leadgen'">
                                    <th v-for="(value, key) in aiProjectMap.ai['leadgen'].googleAds" :key="key">{{ value }}</th>
                                  </tr>
                                  <tr v-else-if="message.selectedAiClient.businessType[0] === 'ecommerce'">
                                    <th v-for="(value, key) in aiProjectMap.ai['ecommerce'].googleAds" :key="key">{{ value }}</th>
                                  </tr>
                                  <tr v-else>
                                    <th v-for="(value, key) in aiProjectMap.ai['default'].googleAds" :key="key">{{ value }}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="campaign in  getSearchCampaignData(message.filteredCampaignData)" :key="campaign.campaignId">
                                    <template v-if="message.selectedAiClient.businessType === 'leadgen'">
                                      <td v-for="(value, key) in aiProjectMap.ai['leadgen'].googleAds" :key="key">{{ campaign[key] }}</td>
                                    </template>
                                    <template v-if="message.selectedAiClient.businessType === 'ecommerce'">
                                      <td v-for="(value, key) in aiProjectMap.ai['ecommerce'].googleAds" :key="key">{{ campaign[key] }}</td>
                                    </template>
                                    <template v-else>
                                      <td v-for="(value, key) in aiProjectMap.ai['default'].googleAds" :key="key">{{ campaign[key] }}</td>
                                    </template>
                                  </tr>
                                </tbody>
                              </table>
        
                              <!-- Table for Meta -->
                              <table v-if="message.channelType === 'meta'" class="styled-table">
                                <thead>
                                  <tr v-if="message.selectedAiClient.businessType[0] === 'leadgen'">
                                    <th v-for="(value, key) in aiProjectMap.ai['leadgen'].meta" :key="key">{{ value }}</th>
                                  </tr>
                                  <tr v-else-if="message.selectedAiClient.businessType[0] === 'ecommerce'">
                                    <th v-for="(value, key) in aiProjectMap.ai['ecommerce'].meta" :key="key">{{ value }}</th>
                                  </tr>
                                  <tr v-else>
                                    <th v-for="(value, key) in aiProjectMap.ai['default'].meta" :key="key">{{ value }}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="campaign in message.filteredCampaignData" :key="campaign.campaignId">
                                    <template v-if="message.selectedAiClient.businessType === 'leadgen'">
                                      <td v-for="(value, key) in aiProjectMap.ai['leadgen'].meta" :key="key">{{ campaign[key] }}</td>
                                    </template>
                                    <template v-if="message.selectedAiClient.businessType === 'ecommerce'">
                                      <td v-for="(value, key) in aiProjectMap.ai['ecommerce'].meta" :key="key">{{ campaign[key] }}</td>
                                    </template>
                                    <template v-else>
                                      <td v-for="(value, key) in aiProjectMap.ai['default'].meta" :key="key">{{ campaign[key] }}</td>
                                    </template>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </span>
                          <span v-else>
                            {{ message.queryStringResponse }}
                          </span>
                        </span>
                      </div>
                      <span class="time-stamp">{{ message.timeStamp }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="this.isChatMessageFetched">
            <div
            >
              <div style="padding: 10px;">
                <div class="chat-wrapper">
                  <div class="chat-message">
                    <div class="message-content">
                      <div class="name" style="margin-left: 10px; text-align:right">
                        {{ this.adminFirstName }}
                      </div>
                      <div class="chat-bubble" style="margin-left: 10px; min-width:200px">
                        {{ this.selectedUserQuery }}
                      </div>
                      <span class="time-stamp-user">{{ new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }) }}</span>
                    </div>
                    <div v-if="this.profileImage.length > 9">
                      <img :src="this.profileImage" alt="User" class="profile-image" />
                    </div>
                    <div v-else>
                      <b-avatar style="margin-left: 10px;" variant="primary" :text="this.adminFirstName[0]"></b-avatar>
                    </div>
                  </div>
                </div>
                <div class="message-container">
                  <b-avatar variant="primary" text="D"></b-avatar>
                  <div class="message-content">
                    <div class="name" style="margin-left: 10px;">D2Scale-AI</div>
                    <div class="chat-bubble" style="margin-left: 10px;">
                      <span>
                        <ChatSpinner />
                      </span>
                    </div>
                    <span class="time-stamp">{{ new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <b-row v-if="selectedAiClient !== 'Select Client'" class="mt-1">
        <b-col cols="auto">
          <div
            style="background-color: #7c0cfc; width:35px; height:35px; padding:7px; border-radius:20px; cursor:pointer"
          >
            <b-icon
              disabled
              class="upDownIcon"
              icon="mic-fill"
              style="color: white;"
            />
          </div>
        </b-col>
        <b-col>
          <b-form-input
            class="search_query_ai"
            block
            @keyup.enter="sendChatApi"
            v-model="selectedChatQuery"
            placeholder="Ask What's on mind...."
          />
        </b-col>
        <b-col cols="auto">
          <div v-if="sendingQuery"
            style="background-color: #7c0cfc; width:35px; height:35px; padding:7.5px; border-radius:20px; cursor:not-allowed"
          >
          <b-icon
              disabled
              class="upDownIcon"
              icon="stop-fill"
              style="color: white;"
            />
          </div>
          <div v-else
          style="background-color: #7c0cfc; width:35px; height:35px; padding:7.5px; border-radius:20px; cursor:pointer"
          @click="sendChatApi"
        >
          <img
            src="@/assets/images/logo/send-fill.svg"
            alt="access-control"
          />
        </div>
        </b-col>
      </b-row>
      <!-- <div block v-if="selectedAiClient !== 'Select Client'" style="display: flex; justify-content:center; gap:10px; margin-top:10px">
          <div
            style="background-color: #7c0cfc; width:35px; height:35px; padding:7px; border-radius:20px; cursor:pointer"
          >
            <b-icon disabled class="upDownIcon" icon="mic-fill" style="color: white;" />
          </div>
          <div>
             <input  @keyup.enter="sendChatApi"  v-model="selectedChatQuery" placeholder="Ask What's on mind...." class="search_query"/>
          </div>
          <div
            style="background-color: #7c0cfc; width:35px; height:35px; padding:7.5px; border-radius:20px; cursor:pointer"
            @click="sendChatApi"
          >
            <img  src="@/assets/images/logo/send-fill.svg" alt="access-control" />
          </div>
        </div> -->
    </b-card>
    <!-- <b-card v-if="filter" id="filters-card">
          <div class="audio-recorder">
            <h1 class="title">Ask your Query</h1>
            <b-form-select
              v-model="selectedLanguage"
              :options="formattedLanguages"
              class="language-select mx-1"
              :placeholder="'Select Language'"
            />
        
            <div class="button-container">
              <button 
                @click="isRecording ? stopRecording() : startRecording()" 
                class="record-button"
              >
                <span v-if="isRecording">⏹ Stop Recording</span>
                <span v-else>  <b-icon
                  class=""
                  icon="chat-dots"
                /> Start speaking to transcribe....</span>
              </button>
            </div>
            
            <div v-if="isRecording" class="status">Recording...</div>
        
            <div v-if="transcription" class="transcription">
              <h2>Transcription:</h2>
              <p>{{ transcription }}</p>
            </div>
        
            <div v-if="error" class="error">{{ error }}</div>
          </div>
          <b-row>
          </b-row>
        </b-card> -->
    <!-- <div id="google-ads-filter-float">
        <b-button @click="filter = !filter" size="md" variant="primary" class="">
          <div v-if="!filter">
            <img
              src="@/assets/images/logo/Frame.png"
              alt="access-control"
              style="max-width: 18px; margin-top: -2px;"
            />
            <span style="font-size: 12px;"> D2Scale-GPT</span>
          </div>
          <feather-icon v-else size="14" icon="XCircleIcon" />
        </b-button>
      </div> -->
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BFormGroup,
  BRow,
  BCol,
  BCardText,
  BCardTitle,
  BIcon,
  BFormSelect,
  BBadge,
  BAvatar,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import ChatSpinner from "@/components/client/ChatSpinner.vue";
import vSelect from "vue-select";
import axios from "axios";
axios.defaults.withCredentials = true;  

import useJwt from "@/auth/jwt/useJwt";
import Cookies from "js-cookie";
import { getUserToken } from "@/auth/utils";
import aiProjectMap from "@/@core/utils/aiMap.js";
export default {
  components: {
    BButton,
    BCard,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BCardText,
    BCardTitle,
    BIcon,
    BBadge,
    BFormSelect,
    BAvatar,
    BSpinner,
    ChatSpinner,
    BFormInput,
  },
  props: {
    clientID: {
      type: String,
    },
  },
  data() {
    return {
      sendingQuery:false,
      todayDateCompare: new Date().toISOString().split('T')[0],
      isChatMessageFetched:false,
      aiProjectMap: aiProjectMap,
      queryStringResponse:"",
      chatMessages: [],
      channelType: "",
      metaSelectedData: [],
      searchQuery: "",
      sortKey: "",
      sortOrder: "asc",
      isChatIsEnabled: false,
      selectedChatDetails: null,
      selectedQuery: "",
      selectedChatQuery: "",
      selectedUserQuery: "",
      selectedAiClient: "Select Client",
      fetchAiClientsList: [],
      isClientSelected: false,
      profileImage: "",
      colors: ["#ffffff", "#ffffff", "#ffffff"],
      items: [
        "What was the performance of Google Ads on 24 Aug 2024?",
        "Yesterday roas?",
        "How did Google Ads perform last month?",
        "What about the Google Ads performance yesterday?",
        "Can you tell me how Google Ads did over the last 7 days?",
        "Last month roas?",
        "How did Google Ads perform on 24 Sep 2024?",
        "What was the performance of Meta Ads on 24 Aug 2024?",
        "How did Meta Ads perform last month?",
        "What about the Meta Ads performance yesterday?",
        "Can you tell me how Meta Ads did over the last 7 days?",
        "How did Meta Ads perform on 24 Sep 2024?",
      ],
      adminFirstName: "",
      selectedLanguage: "hi-IN",
      languages: [
        { code: "hi-IN", name: "HINDI" },
        { code: "bn-IN", name: "BENGALI" },
        { code: "kn-IN", name: "KANNADA" },
        { code: "ml-IN", name: "MALAYALAM" },
        { code: "mr-IN", name: "MARATHI" },
        { code: "od-IN", name: "ODIYA" },
        { code: "pa-IN", name: "PUNJABI" },
        { code: "ta-IN", name: "TAMIL" },
        { code: "te-IN", name: "TELUGU" },
        { code: "gu-IN", name: "GUJARATI" },
      ],
      isRecording: false,
      mediaRecorder: null,
      audioChunks: [],
      transcription: "",
      error: "",
      filter: false,
      text: "",
      translatedText: "",
      language: "es", // Default translation to Spanish
      isListening: false,
    };
  },
  created() {
    this.profileImage = Cookies.get(useJwt.jwtConfig.adminUrl);
    this.adminFirstName = Cookies.get(useJwt.jwtConfig.adminFirstName);
  },
  computed: {
    filteredCampaignData() {
      if (
        !this.selectedChatDetails ||
        !Array.isArray(this.selectedChatDetails)
      ) {
        return []; // Return an empty array if campaignData is undefined
      }
      return this.selectedChatDetails
        .filter((campaign) => {
          return Object.values(campaign).some((value) =>
            String(value)
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase())
          );
        })
        .sort((a, b) => {
          if (this.sortKey) {
            const modifier = this.sortOrder === "asc" ? 1 : -1;
            return (a[this.sortKey] > b[this.sortKey] ? 1 : -1) * modifier;
          }
          return 0;
        });
    },
    formattedLanguages() {
      let language = this.languages.map((lang) => ({
        value: lang.code,
        text: lang.name,
      }));
      return language;
    },
    selectedLanguageName() {
      const selected = this.languages.find(
        (lang) => lang.code === this.selectedLanguage
      );
      return selected ? selected.name : "";
    },
  },
  methods: {
    addActivityLog() {
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/api/logs/`, {
              "description": "D2Scale-AI Opened",
              "action": "view"
          }, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`
          }
        })
        .then(response => {
        })
        .catch(error => {
        });
    },
    getSearchCampaignData(message) {
      return message.filter(campaign =>
        campaign.campaignName.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
  },
    sortTable(key) {
      this.sortOrder =
        this.sortKey === key && this.sortOrder === "asc" ? "desc" : "asc";
      this.sortKey = key;
    },
    sendChatApi() {
      this.sendingQuery = true
      this.isChatMessageFetched = true;
      this.isChatIsEnabled = true;
      this.metaSelectedData = []
      let queryByUser = "";
      if (this.selectedQuery.length > 0) {
        queryByUser = this.selectedQuery;
        this.selectedUserQuery = this.selectedQuery;
      }
      if (this.selectedChatQuery.length > 0) {
        this.scrollToBottom();
        queryByUser = this.selectedChatQuery;
        this.selectedUserQuery = this.selectedChatQuery;
      }
      this.selectedChatQuery = ""
      // this.selectedChatQuery = this.selectedQuery
      let query = queryByUser.replace(/%20/g, "+");
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/ai/query`,
          {
            clientID: this.selectedAiClient._id,
            query: query,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
              // withCredentials: true,
            },
          }
        )
        .then((response) => {
          this.sendingQuery = false
          this.isChatMessageFetched = false
          this.updateChat(response)
          if (typeof response.data.response === "string") {
            this.queryStringResponse = response.data.response
          }
          this.channelType = response.data.response && response.data.response.channel_type;
          if (this.channelType === "google-ads") {
            this.selectedChatDetails = response.data && response.data.response && response.data.response.campaigns;
          }
          if (this.channelType === "meta") {
            this.selectedChatDetails =
              response.data && response.data.response && response.data.response.dateFormatted;
          }
          // this.isChatIsEnabled = false;
          this.selectedQuery = "";
          this.selectedChatQuery = "";
        })
        .catch((error) => {
          this.sendingQuery = false
          this.isChatMessageFetched = false
          this.selectedQuery = "";
          this.selectedChatDetails = []
          // this.isChatIsEnabled = false;
          console.log(error);
        });
    },
    updateChat(response) {
      let channel_type = response.data.response.channel_type
      let newMessage = {}
      if (channel_type === 'google-ads') {
        newMessage = {
        adminFirstName: this.adminFirstName,
        selectedUserQuery: this.selectedUserQuery,
        timeStamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }),
        profileImage: this.profileImage,
        isChatIsEnabled: false,
        channelType:response.data && response.data.response && response.data.response.channel_type,
        searchQuery: '',
        selectedAiClient: this.selectedAiClient,
        filteredCampaignData: response.data && response.data.response && response.data.response.campaigns || [],
        queryStringResponse: response.data.response,
      };
      }
      else if (channel_type === 'meta') {
        newMessage = {
        adminFirstName: this.adminFirstName,
        selectedUserQuery: this.selectedUserQuery,
        timeStamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }),
        profileImage: this.profileImage,
        isChatIsEnabled: false,
        channelType: response.data && response.data.response && response.data.response.channel_type,
        searchQuery: '',
        selectedAiClient: this.selectedAiClient,
        filteredCampaignData:response.data && response.data.response &&  response.data.response.dateFormatted || [],
        queryStringResponse: response.data.response,
      }
    }else{
       newMessage = {
        adminFirstName: this.adminFirstName,
        selectedUserQuery: this.selectedUserQuery,
        timeStamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }),
        profileImage: this.profileImage,
        isChatIsEnabled: false,
        channelType:response.data && response.data.response &&  response.data.response.channel_type,
        searchQuery: '',
        selectedAiClient: this.selectedAiClient,
        // filteredCampaignData: response.data.response?.campaigns || [],
        queryStringResponse: response.data.response,
      };
    }
      //  newMessage = {
      //   adminFirstName: this.adminFirstName,
      //   selectedUserQuery: this.selectedUserQuery,
      //   timeStamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }),
      //   profileImage: this.profileImage,
      //   // isChatIsEnabled: this.isChatIsEnabled,
      //   channelType: response.data.response?.channel_type,
      //   searchQuery: '',
      //   selectedAiClient: this.selectedAiClient,
      //   filteredCampaignData: response.data.response?.campaigns || [],
      //   queryStringResponse: response.data.response,
      // };

      // Push the new message to the chatMessages array without removing previous messages
      this.chatMessages.push(newMessage);
      this.isChatIsEnabled = false;
      this.selectedQuery = '';
      this.selectedChatQuery = '';
    },
    getBadgeStyle(item) {
      const textWidth = this.calculateTextWidth(item);
      return {
        width: `${textWidth + 20}px`, // Add some padding around the text
        cursor: "pointer",
      };
    },
    calculateTextWidth(text) {
      const span = document.createElement("span");
      span.style.visibility = "hidden";
      span.style.position = "absolute";
      span.style.whiteSpace = "nowrap";
      span.textContent = text;
      document.body.appendChild(span);
      const width = span.getBoundingClientRect().width;
      document.body.removeChild(span);
      return width;
    },
    scrollToBottom() {
    this.$nextTick(() => {
      const chatContainer = this.$refs.chatContainer;
      if (chatContainer) {
        chatContainer.scrollTo({
          top: chatContainer.scrollHeight,
          behavior: "smooth"
        });
      } else {
        console.warn("chatContainer ref is not available");
      }
  });
},
    selectQuery(query) {
      this.selectedQuery = query;
      this.selectedUserQuery = query;
      // this.selectedChatQuery = this.selectedQuery
      this.sendChatApi();
    },
    fetchClients() {
      const storedDate = Cookies.get('lastVisitedD2ScaleAIDate');
      if (storedDate === this.todayDateCompare) {
        console.log("");
      } else {
        this.addActivityLog();
        Cookies.set('lastVisitedD2ScaleAIDate', this.todayDateCompare, { expires: 1 }); 
      }
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/client/access-control`, {
          headers: {
            Authorization: ` Bearer ${getUserToken()}`,
            "Content-type": "application/json",
          },
        })
        .then((response) => {
          this.fetchAiClientsList = response.data.clients;
          if (this.fetchAiClientsList.length === 1) {
            this.selectedAiClient = this.fetchAiClientsList[0]
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRandomColor() {
      const randomIndex = Math.floor(Math.random() * this.colors.length);
      return this.colors[randomIndex];
    },
    async startRecording() {
      this.resetState();
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream);

      this.mediaRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };

      this.mediaRecorder.onstop = this.handleRecordingStop;

      this.mediaRecorder.start();
      this.isRecording = true;
    },
    stopRecording() {
      this.mediaRecorder.stop();
      this.isRecording = false;
    },
    exportToCSV() {
      const csvRows = [];
      // Get the headers
      const headers = Object.keys(this.filteredCampaignData[0] || {});
      csvRows.push(headers.join(","));

      // Get the rows
      this.filteredCampaignData.forEach((campaign) => {
        const values = headers.map((header) => {
          const escaped = ("" + campaign[header]).replace(/"/g, '\\"');
          return `"${escaped}"`; // Escape values
        });
        csvRows.push(values.join(","));
      });

      // Create a CSV file
      const csvString = csvRows.join("\n");
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", "D2Scale_AI_data.csv");
      a.click();
      URL.revokeObjectURL(url);
    },
    exportToMetaCSV(data) {
      const csvRows = [];
      // Get the headers dynamically from the data keys
      const headers = Object.keys(data[0] || {});
      csvRows.push(headers.join(","));
      
      // Get the rows
      data.forEach((campaign) => {
        const values = headers.map((header) => {
          // Check for null or undefined and handle it
          const value = campaign[header] != null ? campaign[header] : '';
          // Escape double quotes
          const escaped = ("" + value).replace(/"/g, '\\"');
          return `"${escaped}"`; // Escape values and wrap in quotes
        });
        csvRows.push(values.join(","));
      });
      
      // Create a CSV file
      const csvString = csvRows.join("\n");
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", "D2Scale_AI_data.csv");
      a.click();
      URL.revokeObjectURL(url);
    }
,
    async handleRecordingStop() {
      const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
      const form = new FormData();
      form.append("file", audioBlob);
      form.append("language_code", this.selectedLanguage);
      form.append("model", "saarika:v1");

      const options = {
        method: "POST",
        headers: {
          "api-subscription-key": "", // Add your API key here
        },
        body: form,
      };

      try {
        const response = await fetch(
          "https://api.sarvam.ai/speech-to-text",
          options
        );
        const data = await response.json();
        this.transcription = data.transcript;
      } catch (err) {
        this.error = "Error fetching transcription.";
        console.error(err);
      }
    },
    resetState() {
      this.transcription = "";
      this.audioChunks = [];
      this.error = "";
    },
  },
  mounted() {
    this.fetchClients();
  },
  watch: {
    selectedQuery() {
      this.scrollToBottom();
    },
    selectedAiClient(newval) {
      this.chatMessages = []
      this.selectedUserQuery = "";
    },
  },
};
</script>

<style lang="scss">
.d2scale-ai-chat-boat-container{
  @import "@core/scss/vue/libs/vue-select.scss";
  .search_query_ai {
    border: none;
    border-radius: 10px;
    font-weight: 500;
    outline: none;
    margin-top: -3px;
    padding: 10px;
  }
  #google-ads-filter-float {
    position: fixed;
    right: 30px;
    bottom: 40px;
    z-index: 9;
  
    .btn-primary {
      box-shadow: 0 0 10px #7367f0;
    }
  }
  
  #filters-card {
    box-shadow: 0px 0px 60px 20px rgb(34 41 47 / 15%);
    position: fixed;
    right: 30px;
    bottom: 65px;
    width: 100%;
    max-width: 520px;
    z-index: 9;
  }
  .audio-recorder {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    border: 2px solid #704c94;
    border-radius: 10px;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 24px;
    color: #704c94;
    margin-bottom: 20px;
  }
  
  .language-select {
    width: 100%;
    max-width: 400px;
    margin-bottom: 15px;
  }
  
  .button-container {
    margin-bottom: 15px;
  }
  
  .record-button {
    background-color: #704c94;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .record-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .record-button:hover:not(:disabled) {
    background-color: #704c94;
  }
  
  .status {
    font-size: 18px;
    color: #ff0000; /* Red for recording status */
    margin-top: 10px;
  }
  
  .transcription {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #e8f5e9;
    width: 100%;
    max-width: 600px;
  }
  .text-icon-wrapper {
    display: inline-flex;
    align-items: center;
  }
  
  .dotLive {
    margin-left: 4px;
    display: inline-block;
    vertical-align: middle;
  }
  .error {
    color: #ff0000;
    margin-top: 10px;
  }
  .chat-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 0 10px;
    .badge {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.7rem 1.4rem; /* Optional: keep if you want some space around text */
      border-radius: 12px;
      font-size: 1rem;
      color: #7c0cfc;
      margin-bottom: 10px;
      background: #ffffff;
      transition: width 0.3s ease;
      flex: 0 1 auto;
    }
  }
  .chat-div > * {
    flex: 1 1 48%;
    box-sizing: border-box;
  }
  
  .chat-div > *:nth-child(3) {
    clear: both;
  }
  
  .message-container {
    display: flex;
    align-items: flex-start;
    margin: 0px 10px;
  }
  
  .message-container-main {
    display: flex;
    align-items: flex-start;
    margin: 10px;
  }
  .profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .message-content {
    display: flex;
    flex-direction: column;
  }
  
  .name {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .chat-bubble {
    background-color: #f1f5f9;
    color: #333;
    min-width: 10vw;
    max-width: 65vw;
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 5px;
  }
  .time-stamp-user {
    font-size: 12px;
    color: gray;
    align-self: flex-start;
    margin-left: 8px;
  }
  .time-stamp {
    font-size: 12px;
    color: gray;
    align-self: flex-end;
  }
  .blink_text {
    color: black;
    animation-name: blinker;
    animation-duration: 2.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  .chat-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: -20px;
  }
  
  .chat-message {
    display: flex;
    align-items: flex-start;
    max-width: 60%;
    margin-bottom: 20px;
  }
  
  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 10px;
    object-fit: cover;
  }
  
  .message-content-user {
    color: white;
    border-radius: 18px;
    padding: 10px 15px;
    position: relative;
    display: inline-block;
    max-width: 220px;
    word-wrap: break-word;
  }
  
  .message-content p {
    margin: 0;
    font-size: 14px;
  }
  
  .message-time {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
    display: block;
    text-align: right;
    margin-top: 5px;
  }
  
  .select-ai-container {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .select-ai-diff-dropdown {
    width: 200px;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease-in-out;
    outline: none;
    appearance: none;
    background-color: #fff;
    cursor: pointer;
  }
  .select-ai-diff-dropdown:focus {
    border-color: #ffffff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  .select-ai-diff-dropdown option[disabled] {
    color: #999;
  }
  
  .select-ai-diff-dropdown option {
    padding: 0.5rem;
  }
  .select-ai-dropdown {
    width: 280px;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease-in-out;
    outline: none;
    appearance: none;
    background-color: #fff;
    cursor: pointer;
  }
  .select-ai-dropdown:focus {
    border-color: #ffffff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  .select-ai-dropdown option[disabled] {
    color: #999;
  }
  
  .select-ai-dropdown option {
    padding: 0.5rem;
  }
  
  @media (min-width: 768px) {
    .select-ai-container {
      max-width: 500px;
    }
  }
  
  .chat-table-container {
    max-width: 950px;
    border-radius: 8px;
    overflow-x: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    .table-title {
      text-align: center;
      font-size: 24px;
      margin-bottom: 20px;
    }
  
    .search-input {
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  
    .styled-table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
    }
  
    .styled-table th,
    .styled-table td {
      padding: 12px 15px;
      text-align: left;
      border-bottom: 1px solid #e0e0e0;
    }
  
    .styled-table th {
      background-color: #ffffff;
      color: #000000;
      cursor: pointer;
    }
    .styled-table tr:nth-child(even) {
      background-color: #f2f2f2;
    }
  
    .styled-table tr:hover {
      background-color: #f1f1f1;
    }
  }
  
  .controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .search-input {
    width: 30%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .export-button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .export-button:hover {
    background-color: #0056b3;
  }
  
  .chat_body {
    .card-body {
      padding: 0.5rem !important;
    }
  }
  
  @media screen and (max-width: 767px) {
    .chat-bubble {
      background-color: #f1f5f9;
      color: #333;
      min-width: 10vw;
      max-width: 65vw;
      padding: 10px 15px;
      border-radius: 10px;
      font-size: 14px;
      line-height: 1.4;
      margin-bottom: 5px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .chat-bubble {
      background-color: #f1f5f9;
      color: #333;
      min-width: 10vw;
      max-width: 75vw;
      padding: 10px 15px;
      border-radius: 10px;
      font-size: 14px;
      line-height: 1.4;
      margin-bottom: 5px;
    }
    .search_query {
      border: none;
      border-radius: 10px;
      font-weight: 500;
      width: 80vw;
      margin-top: -5px;
      outline: none;
      padding: 12px;
    }
  }
  
  @media screen and (min-width: 1025px) {
    .chat-bubble {
      background-color: #f1f5f9;
      color: #333;
      min-width: 10vw;
      max-width: 85vw;
      padding: 10px 15px;
      border-radius: 10px;
      font-size: 14px;
      line-height: 1.4;
      margin-bottom: 5px;
    }
  }
}



</style>
