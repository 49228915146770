<template>
    <div class="activityTrackerContainer">
        <div class="activity-trackor">
            <div>
                <b-card>
                    <div class="modal-item" style="display: flex; justify-content: space-between;">
                      <div class="pr-1 pt-0 edit-form">
                        <h3 class="app-title sm">
                          Usage Tracker
                        </h3>
                      </div>
                        <div>

                            <b-button style="margin-top: -10px; margin-right:-10px" @click="downloadActivityData"
                                variant="flat-primary"><feather-icon size="25"
                                    icon="DownloadIcon"></feather-icon></b-button>
                        </div>
                    </div>
                    <div>
                      <b-row style="padding: 5px 10px;">
                        <b-col cols="6">
                          <div>
                            <div
                              style="padding: 10px; background-color:#F5F5F7; border-radius:10px; cursor:pointer; width:330px"
                            >
                              <date-range-picker
                                ref="picker"
                                opens="right"
                                :minDate="minDate"
                                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                                :showWeekNumbers="false"
                                :showDropdowns="true"
                                :autoApply="true"
                                v-model="dateRange"
                                @update="updateValues"
                                :dateFormat="dateFormat"
                                control-container-class="picker-controller"
                                :ranges="dateRanges"
                                :auto-apply="false"
                                :readonly="false"
                                :maxDate="
                                  new Date(new Date().setDate(new Date().getDate()))
                                "
                                class="filterCardSelect multiDateSelector"
                              >
                                <template v-slot:input="picker">
                                  <feather-icon
                                    icon="CalendarIcon"
                                    size="13"
                                    class="mr-1"
                                  ></feather-icon>
                                  <span style="font-size: 12px;">
                                    Showing Activity for
                                    {{ formatDateDisplay(picker.startDate) }} -
                                    {{ formatDateDisplay(picker.endDate) }}</span
                                  >
                                </template>
                              </date-range-picker>
                            </div>
                          </div>
                        </b-col>
                        <b-col cols="6" v-if="this.allActivites.length > 0">
                          <div>
                            <div class="activity-badge-container" style="float: right;">
                              <span class="badge unique" >
                                <div @mouseover="showModal"
                                @mouseout="hideModal">
                                <feather-icon
                                style="margin-right: 5px;"
                                icon="UsersIcon"
                                size="100"
                              />
                              <span style="margin-right: 5px; "
                                >Unique Users
                              </span>
                                </div>
                                <div>
                                  <span v-if="isVisibleCopied">
                                    <feather-icon 
                                    style="margin-right: 0px;color:white"
                                    icon="CheckCircleIcon"
                                    size="120"
                                   />
                                    <span style="color: white;">
                                      Copied
                                    </span>
                                  </span>
                                <span v-else>
                                  <feather-icon 
                                  v-b-tooltip.hover.top="'Click to copy all unique users.'"
                                 @click="copyToClipboardUniqueUsers"
                                 style="margin-right: 5px; cursor:pointer"
                                 icon="CopyIcon"
                                 size="120"
                                 class="copy-button"
                               />
                                </span>
                                </div>
                                </span>
                              <span class="badge success">
                                <feather-icon
                                  style="margin-right: 5px;"
                                  icon="ActivityIcon"
                                  size="100"
                                />
                                <span style="margin-right: 5px; "
                                  >Top Feature Usage:
                                </span>
                                <span style="font-weight:bold">
                                  {{ this.uniqueLoginsPerDay.mostUsedTab.split(" ")[0] }}</span
                                >
                              </span>
                              <span class="badge info">
                                <feather-icon
                                  style="margin-right: 5px;"
                                  icon="ActivityIcon"
                                  size="100"
                                />
                                Max Usage Date:
                                <span style="font-weight:bold ; margin-left:4px">
                                  {{ this.uniqueLoginsPerDay.maxLoginDay }}
                                </span>
                                <span> </span>
                                <span style="font-weight:bold; margin-left:5px">
                                  - (
                                  {{ this.uniqueLoginsPerDay.maxUniqueLogins }} )</span
                                >
                                <span
                                  v-if="this.uniqueLoginsPerDay.maxUniqueLogins > 0"
                                  style="margin-left: 3px;"
                                >
                                  Users
                                </span>
          
                                <span v-else style="margin-left: 3px;">User</span>
                              </span>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                      <div></div>
                    </div>
                    <div>
                        <div v-if="isLogsLoad" class="spinnerCentered">
                            <multiChannelSpinner class="mr-1" />
                        </div>
                        <div v-else-if="this.allActivites.length === 0" style="display: flex;; justify-content:center;"
                            class="m-4">
                            <span>No Activity Found!</span>
                        </div>
                        <div v-else>
                          <b-row>
                            <b-col cols="8">
                              <apexchart
                                type="bar"
                                :options="chartOptions"
                                :series="series"
                                height="250"
                              ></apexchart>
                            </b-col>
                            <b-col cols="4" class="mt-3">
                              <apexchart
                                type="donut"
                                :options="chartPieOptions"
                                :series="seriesPie"
                                height="250"
                              ></apexchart>
                            </b-col>
                          </b-row>
                        </div>
                    </div>
                </b-card>
                <b-card>
                    <div v-if="isLogsLoad" class="spinnerCentered">
                        <multiChannelSpinner class="mr-1" />
                    </div>
                    <div v-else-if="this.allActivites.length === 0" style="display: flex;; justify-content:center;"
                        class="m-4">
                        <span>No Activity Found!</span>
                    </div>
                    <div class="log-table-container" v-else>
                        <div class="search-activity-container mb-1">
                            <div>
                                <input class="input-search-activity"
                                    placeholder="Search Activity Records for a Specific User ID"
                                    v-model="searchUserActivity" />
                            </div>
                            <div>
                                <div style="display: flex; justify-content: space-between;">
                                    <div style="display: flex; justify-content: space-between; margin-top:5px">
                                        <div class="radio-label mr-1">
                                            <input type="radio" v-model="userType" value="all" />
                                            All
                                        </div>
                                        <div class="radio-label mr-1">
                                            <input type="radio" v-model="userType" value="team" />
                                            Team
                                        </div>
                                        <div class="radio-label">
                                            <input type="radio" v-model="userType" value="client" />
                                            Clients
                                        </div>
                                    </div>
                                    <b-button style="margin-top: -10px; margin-right:-10px"
                                        @click="downloadActivityData" variant="flat-primary"><feather-icon size="25"
                                            icon="DownloadIcon"></feather-icon></b-button>
                                </div>
                            </div>
                        </div>
                        <table class="log-table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>User ID</th>
                                    <th>Description</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="log in this.paginatedActivities" :key="log.id">
                                    <td class="log-date">
                                        <span class="currency">{{ formatDate(log.createdAt) }}</span>
                                    </td>
                                    <td class="log-user-info">
                                        <div class="log-user-id">{{ log.userID }}</div>
                                    </td>
                                    <td class="log-description">
                                        {{ log.description }}
                                    </td>
                                    <td>
                                        <span :class="['status', log.action.toLowerCase()]"><span
                                                style="text-transform:capitalize;font-weight:bold">{{
                                                    log.action
                                                }}</span></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <b-pagination v-if="this.allActivites.length > 0" v-model="currentPage" :total-rows="filteredActivityLength"
                        :per-page="pageLength" first-number last-number align="right" prev-class="prev-item"
                        next-class="next-item" class="mt-1 mb-0" @change="pageChanged">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </b-card>
            </div>
            <b-modal
            id="hover-modal"
            :visible="isHovered"
            hide-footer
            hide-header
            @hide="hideModal"
            no-auto-focus
            size="xs"
          >
            <template>
              <h5 class="glow">
                  All Unique Users
              </h5>
            </template>
            <ul style="width: 300px !important; margin-left:-55px">
              <ul v-for="(item, index) in uniqueLoginsPerDay.uniqueUserIDs" :key="index">
                  <li>{{ item }}</li>
              </ul>
            </ul>
          </b-modal>
        </div>
    </div>
</template>

<script>
import {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BModal,
    BCardText,
    BButton,
    BIcon,
    BFormInput,
    BSpinner,
    BPagination,
    BCard,
    BRow,
    BCol,
} from "bootstrap-vue";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import dayjs from "dayjs";
import useJwt from "@/auth/jwt/useJwt";
import axios from "axios";
import Cookies from "js-cookie";
import {
    getUserData,
    getUserToken,
    getUserEmail,
    getUserImage,
} from "@/auth/utils";
export default {
    data() {
        return {
          isHovered: false,
        isVisibleCopied:false,
        minDate: "2024-11-26",
        seriesPie: [],
        chartPieOptions: {
          chart: {
            type: "donut",
          },
          labels: [
            "Dashboard",
            "Triggers",
            "Access Control",
            "D2Scale-AI",
            "GMB",
            "GMB Dealers",
          ],
          colors: [
            "#85e085",
            "#f5a9a9",
            "#ffe680",
            "#a5d8ff",
            "#37AFE1",
            "#FAB12F",
          ],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          legend: {
            position: "right",
          },
        },
            userType: "all",
            filteredActivityLength: 0,
            searchUserActivity: "",
            startDateForAll: "",
            endDateForAll: "",
            selected: {
                label: "Last 7 days",
                code: "LAST_7_DAYS",
            },
            dateRange: {
                endDate: moment()
                    .subtract(0, "days")
                    .format("YYYY-MM-DD"),
                startDate: moment()
                    .subtract(7, "days")
                    .format("YYYY-MM-DD"),
            },
            currentPage: 1,
            pageLength: 10,
            allActivites: [],
            chartOptions: {
                chart: {
                    height: 350,
                    type: "line",
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: "45%",
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                },
                xaxis: {
                    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
                    position: "top",
                },
            },
            series: [
                {
                    name: "Unique Active Users",
                    data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2],
                },
            ],
            isLogsLoad: false,
            isActivityOpen: false,
        };
    },
    computed: {
        dateRanges() {
            let today = new Date();
            today.setHours(0, 0, 0, 0);

            let yesterday = new Date();
            yesterday.setDate(today.getDate() - 1);
            yesterday.setHours(0, 0, 0, 0);

            let last7thDay = new Date();
            last7thDay.setDate(today.getDate() - 7);
            last7thDay.setHours(0, 0, 0, 0);

            let last14thDay = new Date();
            last14thDay.setDate(today.getDate() - 14);
            last14thDay.setHours(0, 0, 0, 0);

            let last30thDay = new Date();
            last30thDay.setDate(today.getDate() - 30);
            last30thDay.setHours(0, 0, 0, 0);

            var thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
            var thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            let ranges = {
                Today: [today, today],
                Yesterday: [yesterday, today],
                "Last 7 Days": [last7thDay, yesterday],
                "Last 14 Days": [last14thDay, yesterday],
                "Last 30 Days": [last30thDay, yesterday],
                "Last Month": [
                    new Date(today.getFullYear(), today.getMonth() - 1, 1),
                    new Date(today.getFullYear(), today.getMonth(), 0),
                ],
                "This Month": [thisMonthStart, thisMonthEnd],
                "This Year": [new Date(today.getFullYear(), 0, 1), new Date(today)],
            };

            return ranges;
        },
        uniqueLoginsPerDay() {
        const today = new Date();
        today.setUTCHours(0, 0, 0, 0); // Normalize to UTC midnight
  
        let DashboardCount = 0;
        let D2ScaleAICount = 0;
        let TriggersCount = 0;
        let GMBDealersCount = 0;
        let GMBCount = 0;
        let AccessControlCount = 0;
  
        const loginsByDay = {};
        let maxUniqueLogins = 0;
        let maxLoginDay = "";
        let mostUsedTab = ""; // To track the most used tab
        const tabUsageCount = {}; // To count occurrences of each tab
        let uniqueUserIDs = new Set();
        // Process login activities to track unique users per day and tab counts
        this.allActivites.forEach((log) => {
          // Normalize log date to UTC start of day
          const logDate = new Date(log.createdAt);
          logDate.setUTCHours(0, 0, 0, 0);
          const formattedDate = logDate.toISOString().split("T")[0]; // YYYY-MM-DD format in UTC
  
          // Track unique logins by day
          if (log.action === "login") {
            if (!loginsByDay[formattedDate]) {
              loginsByDay[formattedDate] = new Set();
            }
            loginsByDay[formattedDate].add(log.userID);
            uniqueUserIDs.add(log.userID);
          }
  
          // Increment tab usage counts based on description
          if (log.action === "view") {
            switch (log.description) {
              case "Dashboard Overview Opened":
                DashboardCount++;
                break;
              case "D2Scale-AI Opened":
                D2ScaleAICount++;
                break;
              case "Triggers Opened":
                TriggersCount++;
                break;
              case "GMB For Dealers Opened":
                GMBDealersCount++;
                break;
              case "GMB Dashboard Opened":
                GMBCount++;
                break;
              case "Access Control Opened":
                AccessControlCount++;
                break;
            }
  
            // Track the most used tab
            if (!tabUsageCount[log.description]) {
              tabUsageCount[log.description] = 0;
            }
            tabUsageCount[log.description]++;
          }
        });
  
        // Find the most used tab
        if (Object.keys(tabUsageCount).length > 0) {
          mostUsedTab = Object.keys(tabUsageCount).reduce((a, b) =>
            tabUsageCount[a] > tabUsageCount[b] ? a : b
          );
        }
  
        const result = {};
        const allDates = Object.keys(loginsByDay).sort(
          (a, b) => new Date(a) - new Date(b)
        );
  
        // Populate the result and track max logins
        allDates.forEach((date) => {
          const uniqueCount = loginsByDay[date].size;
          result[date] = uniqueCount;
  
          if (uniqueCount > maxUniqueLogins) {
            maxUniqueLogins = uniqueCount;
            maxLoginDay = date;
          }
        });
  
        // Handle no login activities case
        if (!allDates.length) {
          const todayFormatted = today.toISOString().split("T")[0];
          result[todayFormatted] = 0;
          maxLoginDay = todayFormatted;
        }
  
        uniqueUserIDs = Array.from(uniqueUserIDs);
    
  
        return {
          loginsByDay: result,
          maxUniqueLogins,
          maxLoginDay,
          mostUsedTab,
          DashboardCount,
          D2ScaleAICount,
          TriggersCount,
          GMBDealersCount,
          GMBCount,
          AccessControlCount,
          uniqueUserIDs
        };
      },
        totalRows() {
            if (this.searchUserActivity.length > 0) {
                return this.filteredActivityLength
            } else {
                return this.allActivites ? this.allActivites.length : 0;
            }
        },
        paginatedActivities() {
            if (!Array.isArray(this.allActivites) || this.allActivites.length === 0) {
                return [];
            }

            let filteredActivities = this.allActivites;

            // Filter based on userType
            if (this.userType === "team") {
                filteredActivities = filteredActivities.filter(activity =>
                    activity.userID && activity.userID.includes("@socialbeat.in")
                );
            } else if (this.userType === "client") {
                filteredActivities = filteredActivities.filter(activity =>
                    activity.userID && typeof activity.userID === "string" && !activity.userID.includes("@socialbeat.in")
                );
            }

            // Apply search filter if provided
            if (this.searchUserActivity) {
                filteredActivities = filteredActivities.filter(activity =>
                    activity.userID && activity.userID.toLowerCase().includes(this.searchUserActivity.toLowerCase())
                );
            }

            // Paginate the filtered results
            const start = (this.currentPage - 1) * this.pageLength;
            const end = start + this.pageLength;
            this.filteredActivityLength = filteredActivities.length;
            return filteredActivities.slice(start, end);
        }
        ,
    },
    watch: {
        searchUserActivity(newval, oldval) {
            if (newval) {
                this.currentPage = 1
            }
        }
    },
    components: {
        apexchart: VueApexCharts,
        BLink,
        BNavbarNav,
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,
        BModal,
        BCardText,
        BButton,
        BIcon,
        BFormInput,
        BSpinner,
        BPagination,
        BCard,
        DateRangePicker,
        BRow,
        BCol,
        multiChannelSpinner
    },
    methods: {
      copyToClipboardUniqueUsers(){
        const arrayString = JSON.stringify(this.uniqueLoginsPerDay.uniqueUserIDs);
        navigator.clipboard
          .writeText(arrayString)
          .then(() => {
            setTimeout(() => (this.copySuccess = ''), 2000); // Clear the message after 2 seconds
          })
          .catch(() => {
            this.copySuccess = 'Failed to copy array.';
          });
          this.isVisibleCopied = true
          setTimeout(() => {
          this.isVisibleCopied = false;
        }, 1200); 
      },
      showModal() {
        this.isHovered = true;
      },
      hideModal() {
        this.isHovered = false;
      },
        formatDateDisplay(date) {
            return moment(date).format("DD-MM-YYYY");
        },
        updateValues(values) {
            let startDate = moment(values.startDate).format("YYYY-MM-DD");
            let endDate = moment(values.endDate).format("YYYY-MM-DD");
            this.startDateForAll = startDate;
            this.endDateForAll = endDate;
            this.openActivityDetails();
        },
        dateChanged(val) {
            this.$emit("fetchNewData", val.code);
        },
        dateFormat(classes, date) {
            let yesterday = new Date();

            let d1 = moment(date).format("DD-MM-YYYY");
            let d2 = moment(yesterday.setDate(yesterday.getDate() - 1)).format(
                "DD-MM-YYYY"
            );
            return classes;
        },
        updateChartData() {
          this.seriesPie = [
          this.uniqueLoginsPerDay.DashboardCount,
          this.uniqueLoginsPerDay.TriggersCount,
          this.uniqueLoginsPerDay.AccessControlCount,
          this.uniqueLoginsPerDay.D2ScaleAICount,
          this.uniqueLoginsPerDay.GMBCount,
          this.uniqueLoginsPerDay.GMBDealersCount,
        ]
          this.chartPieOptions = {
          chart: {
            type: "donut",
          },
          labels: [
            "Dashboard",
            "Triggers",
            "Access Control",
            "D2Scale-AI",
            "GMB",
            "GMB Dealers",
          ],
          colors: [
            "#85e085",
            "#f5a9a9",
            "#ffe680",
            "#a5d8ff",
            "#37AFE1",
            "#FAB12F",
          ],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          legend: {
            position: "right",
          },
        };
            (this.chartOptions = {
                chart: {
                    height: 350,
                    type: "line",
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: "45%",
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                },
                xaxis: {
                    categories: Object.keys(this.uniqueLoginsPerDay.loginsByDay),
                    position: "top",
                },
            }),
                (this.series = [
                    {
                        name: "Unique Active Users",
                        data: Object.values(this.uniqueLoginsPerDay.loginsByDay),
                    },
                ]);
        },
        formatDate(date) {
            const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            };
            return new Date(date).toLocaleDateString(undefined, options);
        },
        pageChanged(value) {
            this.currentPage = value;
        },
        openActivityDetails(
            dateRange,
            campaignType = null,
            campaigns = null,
            adGroups = null,
            startDate = null,
            endDate = null
        ) {
            if (!dateRange) {
                dateRange = "LAST_7_DAYS";
            }
            this.currentDateRange = dateRange;
            let additionalQueryParams = ``;
            this.allActivites = [];
            this.isLogsLoad = true;
            if (!this.endDateForAll && !this.startDateForAll) {
                const today = new Date();
                const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
                const yesterday = new Date(today.getTime() - 0 * 24 * 60 * 60 * 1000);
                const formattedStartDate = prevWeek.toISOString().split("T")[0];
                const formattedEndDate = yesterday.toISOString().split("T")[0];

                additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
            }
            if (this.startDateForAll && this.endDateForAll) {
                additionalQueryParams =
                    additionalQueryParams +
                    `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
            }
            axios
                .get(
                    `${process.env.VUE_APP_SERVER_URL}/api/logs?${additionalQueryParams}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getUserToken()}`,
                        },
                    }
                )
                .then((response) => {
                    this.allActivites = response.data;
                    this.isLogsLoad = false;
                    this.updateChartData();
                    this.currentPage = 1
                })
                .catch((error) => {
                    console.log(error);
                    this.isLogsLoad = false;
                    this.isActivityOpen = false;
                });
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            return date.toISOString().split("T")[0]; // Format the date as needed (you can adjust the format here)
        },
        downloadActivityData() {
            // Select only the required fields (_id, userID, action, description, createdAt)
            const filteredData = this.allActivites.map((item) => ({
                _id: item._id,
                userID: item.userID,
                action: item.action,
                description: item.description,
                createdAt: this.formatDate(item.createdAt), // Format the date without time
            }));

            // Convert the filtered data to CSV
            const csvContent = this.convertToCSV(filteredData);

            // Create a Blob and download the CSV file
            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "exported_data.csv");
            link.click();
        },
        convertToCSV(data) {
            // Get headers from the first item
            const headers = Object.keys(data[0]);

            // Create CSV content
            const rows = data.map((item) =>
                headers.map((header) => item[header]).join(",")
            );

            // Combine headers and rows
            return [headers.join(","), ...rows].join("\n");
        },
    },
    mounted() {
        this.openActivityDetails();
    },
};
</script>

<style lang="scss">
.activityTrackerContainer {
    .card-body {
        padding: 1rem !important;
    }
}

.activity-trackor {
    .log-table-container {
        width: 100%;
        padding: 10px 0px;
        font-family: Arial, sans-serif;
    }

    .log-table {
        width: 100%;
        border-collapse: collapse;
        background-color: #ffffff;
    }

    .log-table th,
    .log-table td {
        padding: 16px 10px;
        text-align: left;
    }

    .log-table th {
        font-weight: bold;
        color: #6b7280;
        background-color: #f3f4f6;
    }

    .log-table tbody tr {
        border-bottom: 1px solid #e5e7eb;
    }

    .log-user-info {
        font-weight: 600;
        color: #1d1d1d;
    }

    .log-user-id {
        font-size: 0.9em;
        color: #8c8c8c;
    }

    .log-description {
        color: #6b7280;
        font-size: 0.9em;
    }

    .status {
        display: inline-block;
        padding: 4px 8px;
        border-radius: 12px;
        font-weight: 500;
        font-size: 0.9em;
        text-align: center;
    }

    .status.logout {
        background-color: #eef2ff;
        color: #4f46e5;
    }

    .status.login {
        background-color: #d1fae5;
        color: #059669;
    }

    .status.view {
        background-color: #d1fae5;
        color: #059669;
    }

    .log-date {
        color: #1f2937;
        font-weight: 600;
    }

    .currency {
        font-size: 0.9em;
        color: #8c8c8c;
    }
}

.activity-badge-container {
    margin-right: 5px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;

    .badge {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 12px 16px;
        border-radius: 15px;
        font-size: 12px;
        font-weight: 500;
        color: white;
        text-align: center;
    }

    .badge.default {
        background-color: #d6d6d6;
        color: #333;
    }

    .badge.success {
        background-color: #85e085;
        color: #2b7a2b;
    }

    .badge.danger {
        background-color: #f5a9a9;
        color: #d32f2f;
    }

    .badge.warning {
        background-color: #ffe680;
        color: #b38f00;
    }

    .badge.info {
        background-color: #a5d8ff;
        color: #0056b3;
    }
}

.spinnerCentered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px;
}

.search-activity-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input-search-activity {
        padding: 10px;
        border: 2px solid #EEEEEE;
        border-radius: 10px;
        width: 320px;
    }
}
.activity-badge-container {
  margin-right: 5px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;

  .badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 500;
    color: white;
    text-align: center;
  }

  .badge.default {
    background-color: #d6d6d6;
    color: #333;
  }
  .badge.unique {
    background-color: #77CDFF;
    color: #2e6882;
  }
  
  .badge.success {
    background-color: #85e085;
    color: #2b7a2b;
  }

  .badge.danger {
    background-color: #f5a9a9;
    color: #d32f2f;
  }

  .badge.warning {
    background-color: #ffe680;
    color: #b38f00;
  }

  .badge.info {
    background-color: #a5d8ff;
    color: #0056b3;
  }
}

</style>
