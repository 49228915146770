<template>
    <div>
      <b-card title="Add Graph" class="screenSelectorAlignment">
        <b-card-text> Select metrics to display the graph </b-card-text>
        <b-row>
          <b-col>
            <b-form-input v-model="title" placeholder="Graph Title" class="graphInput" />
          </b-col>
          <b-col>
            <v-select v-model="metric1" label="title" placeholder="Select Metric 1" :options="options" class="selectGraph" :deselectFromDropdown="true"/>
          </b-col>
          <b-col>
            <v-select v-model="metric2" label="title" placeholder="Select Metric 2" :options="options" class="selectGraph" :deselectFromDropdown="true"/>
          </b-col>
          <b-col class="d-flex align-items-center">
            <b-button :disabled="!isValidOptions" @click="addGraph" variant="primary">Add Graph <feather-icon
                icon="PlusIcon"></feather-icon></b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BCardText,
    BFormInput,
    BFormGroup,
    BButton,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BCardText,
      BFormInput,
      BFormGroup,
      BButton,
      vSelect,
    },
    props: {
      metrics: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
    computed: {
      options() {
        if (this.metrics.length) {
          let metrics = this.metrics[0];
          let options = [];
  
          let metricKeys = Object.keys(metrics);
          for (let i = 0; i < metricKeys.length; i++) {
            const metricKey = metricKeys[i];
            if (metricKey != "key") {
              options.push(
                { title: this.metricMap[metricKey], value: metricKey }
              );
              // metricKey
            }
          }
          return options;
        } else {
          return [];
        }
      },
      isValidOptions() {
        return this.title && this.metric1 && this.metric2;
      },
    },
    data() {
      return {
        title: "",
        metric1: "",
        metric2: "",
        option: [],
        metricMap: {
          acos:"ACOS",
          clicks: "Clicks",
          conversionRate: "CVR",
          costPerClick: "Cost Per Click",
          ctr: "CTR",
          cvr: "CVR",
          orders:"Orders",
          impressions: "Impressions",
          key: "Date",
          purchases30d:"Purchases 30 days",
          roas: "ROAS",
          sales:"Sales",
          spends:"Spends",
          aov:"AOV",
        }
      };
    },
    methods: {
      addGraph() {
        this.$emit("graphAdded", {
          title: this.title,
          metric1: this.metric1.value,
          metric2: this.metric2.value,
        });
        this.title = "";
        this.metric1 = "";
        this.metric2 = "";
      },
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  
  .selectGraph{
    .vs__dropdown-menu {
    top: calc(100% - 28rem) !important;
  }
  }
  .graphInput {
    border-radius: 5px;
    padding-left: 8px;
    padding-right: 8px;
    width: 315px;
    height: 38px;
    background-color: white;
    border: 1px solid rgb(218, 211, 211);
    color: rgb(163, 153, 153);
  }
  </style>