<template>
  <div class="mainDashboardContainer">
    <b-row v-if="client.status" class="content-header">
      <b-col class="content-header-left mb-2" cols="10">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0 text-white">
              <b-avatar class="mr-1" size="32" :src="client.logo" :text="avatarText(client.name)"
                :variant="client.logo ? `white` : `primary`" />
              {{ client.name }}
            </h2>
            <div class="breadcrumb-wrapper breadcrumb-container">
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon icon="HomeIcon" size="16" class="align-text-top text-white" />
                </b-breadcrumb-item>
                <b-breadcrumb-item v-for="item in breadcrumbs" :key="item.text" :active="item.active" :to="item.to"
                  class="text-white">
                  {{ item.text }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="2"> </b-col>
    </b-row>
    <div v-if="
      client.status &&
      client.integrations.salesforce &&
      client.salesforce.adAccountIDs.length > 0
    ">
      <b-overlay class="dashboard-overlay" :show="isClientLoad" rounded="sm">
        <date-selector :client="client._id" :slug="$route.params.slug" :chartSummaryData="groupData"
          :businessType="client.businessType" :iconCardType="iconType" :groupCardFlag="isLeadsFetch"
          @fetchCampaigns="fetchLeadsCampaignData" @fetchQuaterlyData="fetchQuarterlyData"
          @fetchActiveCampaignDataChange="fetchActiveCampaignData" @fetchLeadFunnelsAll="fetchLeadsFunnelDataAll"
          @fetchLeadFunnelsMeta="fetchLeadsFunnelDataMeta" @fetchLeadFunnelsGoogle="fetchLeadsFunnelDataGoogle"
          @fetchLeadsBySourceLeads="fetchLeadsBySource" @fetchOpportunitiesTrendsData="fetchOpportunitiesTrends" />
        <b-card class="tabs-container salesforceAlignments">
          <b-tabs fill>
            <!-- <b-tab
                  title="Dashboard"
                  @click="changeTab('Dashboard')"
                  active
                  ></b-tab> -->
            <b-tab title="Leads" @click="changeTab('Leads')"></b-tab>
            <b-tab title="Opportunities" @click="changeTab('Opportunities')"></b-tab>
            <!-- <b-tab title="Active Campaigns" @click="changeTab('ActiveCampaigns')"></b-tab> -->
            <!--                 
                <b-tab
                title="Contacts"
                @click="changeTab('Contacts')"
              ></b-tab>
              <b-tab
              title="Reports"
              @click="changeTab('Reports')"
            ></b-tab> -->
          </b-tabs>
        </b-card>
        <div>
          <SalesforceTable :isTableDataLoad="isDashboardDataLoad" v-if="this.tabName === 'Dashboard'" title="Dashboard"
            exportsType="Dashboard" type="Dashboard" :chartData="this.dashboardData" />
          <b-row>
            <b-col cols="12" v-if="tabName === 'Leads'">
              <b-card>
                <!-- Title -->
                <div>
                  <b-card-title>
                    <h3 class="app-title mdsm">Leads Funnel</h3>
                  </b-card-title>
                </div>
            
                <!-- Spinner while data is loading -->
                <b-row v-if="isLeadsFetch">
                  <b-col cols="12">
                    <div class="spinnerCentered">
                      <multiChannelSpinner />
                    </div>
                  </b-col>
                </b-row>
            
                <!-- Funnel Data -->
                <b-row v-else>
                  <!-- Overall Section -->
                  <b-col cols="12" md="4" class="mt-md-0 mt-3">
                    <b-card-text style="font-weight: bold; margin-left: 20px; text-align: center;">Overall</b-card-text>
                    <div class="funnel-container">
                      <div 
                        v-for="(data, index) in funnelAllData" 
                        :key="index" 
                        class="funnel-step" 
                        :style="getStepStyle(data)">
                        <div class="funnel-bar">
                          {{ data.label }}: {{ data.value }}
                        </div>
                      </div>
                    </div>
                  </b-col>
            
                  <!-- Google Ads Section -->
                  <b-col cols="12" md="4" class="mt-md-0 mt-3">
                    <b-card-text style="font-weight: bold; margin-left: 20px; text-align: center;">Google Ads</b-card-text>
                    <div class="funnel-container">
                      <div 
                        v-for="(data, index) in funnelGoogleData" 
                        :key="index" 
                        class="funnel-step" 
                        :style="getStepGoogleStyle(data)">
                        <div class="funnel-bar">
                          {{ data.label }}: {{ data.value }}
                        </div>
                      </div>
                    </div>
                  </b-col>
            
                  <!-- Meta Ads Section -->
                  <b-col cols="12" md="4" class="mt-md-0 mt-3">
                    <b-card-text style="font-weight: bold; margin-left: 20px; text-align: center;">Meta Ads</b-card-text>
                    <div class="funnel-container">
                      <div 
                        v-for="(data, index) in funnelMetaData" 
                        :key="index" 
                        class="funnel-step" 
                        :style="getStepMetaStyle(data)">
                        <div class="funnel-bar">
                          {{ data.label }}: {{ data.value }}
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            
          </b-row>
          <b-row v-if="tabName === 'Opportunities'">
            <b-col cols="12">
              <b-card>
                <div v-if="isOpportunitiesFunnelData" class="spinnerCentered">
                  <multiChannelSpinner />
                </div>
                <div v-else>
                  <b-row>
                    <!-- Desktop and Tablet View -->
                    <b-col 
                      cols="12" 
                      md="5" 
                      class="d-none d-md-block">
                      <div id="chart">
                        <apexchart 
                          type="bar" 
                          height="280" 
                          :options="chartOpportunitesOptions" 
                          :series="seriesOpportunities" />
                      </div>
                    </b-col>
                    <b-col 
                      cols="12" 
                      md="7" 
                      class="d-none d-md-block">
                      <template style="display: flex; justify-content: center; align-items: center; height: 100%;">
                        <div>
                          <apexchart 
                            :options="chartQuarterOptions" 
                            :series="chartQuaterSeries" 
                            type="area" 
                            height="280" 
                            width="600" />
                        </div>
                      </template>
                    </b-col>
                    
                    <!-- Mobile View -->
                    <b-col 
                      cols="12" 
                      class="d-md-none">
                      <b-row>
                        <b-col cols="12">
                          <div id="chart">
                            <apexchart 
                              type="bar" 
                              height="250" 
                              :options="chartOpportunitesOptions" 
                              :series="seriesOpportunities" />
                          </div>
                        </b-col>
                        <b-col cols="12" class="mt-3">
                          <apexchart 
                            :options="chartQuarterOptions" 
                            :series="chartQuaterSeries" 
                            type="area" 
                            height="250" 
                            width="100%" />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
          
          <b-row v-if="tabName === 'Opportunities'">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <!-- Table Section -->
                  <b-col cols="12" md="9">
                    <SalesforceTable 
                      :isTableDataLoad="isOpportunitiesData" 
                      title="All Opportunities" 
                      exportsType="Opportunities" 
                      type="Opportunities" 
                      :chartData="opprtunities" 
                      @sourceChangedData="getSourceData" />
                  </b-col>
                  
                  <!-- Charts Section -->
                  <b-col cols="12" md="3" class="mt-md-0 mt-4">
                    <div class="d-grid gap-3" style="margin-top: 70px;">
                      <div>
                        <b-card-text 
                          style="text-align: center; font-weight: bold;">
                          Conversion Rate
                        </b-card-text>
                        <apexchart 
                          type="donut" 
                          width="100%" 
                          :options="chartConversionOptions" 
                          :series="conversionRateSeries" />
                      </div>
                      <div>
                        <b-card-text 
                          style="text-align: center; font-weight: bold;">
                          Win Rate
                        </b-card-text>
                        <apexchart 
                          type="donut" 
                          width="100%" 
                          :options="chartWinOptions" 
                          :series="winRateSeries" />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          
          <!-- <SalesforceTable
            :isTableDataLoad="isOpportunitiesData"
            v-if="this.tabName === 'Opportunities'"
            title="Opportunities"
            exportsType="Opportunities"
            type="Opportunities"
            :chartData="this.opprtunities"
            @sourceChangedData="getSourceData"
          /> -->

          <!-- <SalesforceTable :isTableDataLoad="isLeadsDataLoad" v-if="this.tabName === 'Leads'" title="Leads"
            exportsType='leads' type="Leads" :chartData="this.leadsData"  @sourceChangedLeadsData="getLeadsSourceData" /> -->

          <SalesforceTable :isTableDataLoad="isCampaignsDataLoad" v-if="this.tabName === 'ActiveCampaigns'"
            title="Active Campaigns" exportsType="ActiveCampaigns" type="ActiveCampaigns"
            :chartData="this.campaignsTableData" @sourceChangedActiveData="getActiveCampaign" />
         <b-row v-if="tabName === 'Leads'">
  <b-col cols="12">
    <b-card>
      <b-row>
        <!-- Salesforce Table Section -->
        <b-col cols="12" md="7" class="mt-md-0 mt-3">
          <SalesforceTable 
            :isTableDataLoad="isFetchedLeadsCampaignData" 
            title="Leads" 
            exportsType="Leads" 
            type="Leads" 
            :chartData="leadsDataDuration" 
            @sourceChangedLeadsData="getLeadsSourceData" />
        </b-col>

        <!-- Chart Section -->
        <b-col cols="12" md="5" class="mt-md-0 mt-4">
          <apexchart 
            class="mt-2" 
            type="bar" 
            height="450" 
            :options="chartComparisonOptions" 
            :series="seriescomparison" />
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</b-row>

          <!-- <SalesforceTable :isTableDataLoad="isReportDataLoading" v-if="this.tabName === 'Reports'" title="Reports"  exportsType='Reports' type="Reports" :chartData="this.reportsData" /> -->
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardTitle,
  BFormCheckbox,
  BBreadcrumb,
  BBreadcrumbItem,
  BAlert,
  BLink,
  BAvatar,
  BCard,
  BButton,
  BOverlay,
  BTabs,
  BTab,
  BCardText,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
import DataTable from "../GoogleAds/DataTable.vue";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import { getUserToken } from "@/auth/utils";
import DateSelector from "./DateSelector.vue";
import moment from "moment";
import SalesforceTable from "./SalesforceTable.vue";
import MultiHeaderProjectChannel from "@/views/clientFeature/MultiheaderProjectChannel.vue";
import { data } from "vue2-dropzone";
export default {
  data() {
    return {
      xaxisDataComparison: [],
      seriescomparison: [{
        data: []
      }, {
        data: []
      }],
      chartComparisonOptions: {
        chart: {
          type: "bar",
          stacked: true, // Enables stacking of bars
          toolbar: { show: true },
        },
        plotOptions: {
          bar: {
            horizontal: true, // Makes the bars horizontal
            barHeight: "50%", // Adjust bar height
          },
        },
        xaxis: {
          categories: [], // Sources
        },
        legend: {
          position: "top",
        },
      colors: ["#da47ff", "#8505fc"], // Colors for the two series
        tooltip: {
          shared: true, // Enables shared tooltip
          intersect: false, // Prevent conflict with shared tooltip
        },
      },
      isLeadsBySource: false,
      winRateSeries: [],
      conversionRateSeries: [],
      chartWinOptions: {
        chart: {
          type: 'donut',
        },
        legend: {
          position: 'bottom', // Set the legend to appear at the bottom
        },
        labels: ["All", "Win Rate"],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      chartConversionOptions: {
        chart: {
          type: 'donut',
        },
        legend: {
          position: 'bottom', // Set the legend to appear at the bottom
        },
        labels: ["All", "Conversion Rate"],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      isTrendsFetch: false,
      winRate: 0,
      conversionRate: 0,
      minBarWidth: 15,
      minBarMetaWidth: 15,
      minBarGoogleWidth: 15,
      opprtunities: [],
      funnelAllData: [],
      funnelMetaData: [],
      funnelGoogleData: [],
      groupData: {},
      chartQuarterOptions: {
        chart: {
          type: "bar",
          height: 250,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: ["Q2 2024", "Q3 2024", "Q4 2024"],
        },
        yaxis: [
          {
            title: {
              text: "Revenue",
            },
          },
          {
            opposite: true,
            title: {
              text: "Deals",
            },
          },
        ],
        title: {
          text: "Revenue and Deals by Quarter",
          align: "left",
        },
      },
      chartQuaterSeries: [
        {
          name: "Total Revenue",
          type: "column",
          data: [60000, 3240000, 345000],
        },
        {
          name: "Total Deals",
          type: "line",
          data: [1, 13, 4],
        },
      ],
      leadsDataDuration: [],
      isLeadsDataDuration: false,
      isFetchedLeadsCampaignData: false,
      isLeadsFetch: false,
      selectedSourceType: "all",
      selectedLeadsFunnel: "all",
      selectedActiveCampaign: "all",
      seriesgoogle: [],
      seriesall: [],
      seriesOpportunities: [
        {
          name: "Funnel Series",
          data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
        },
      ],
      seriesmeta: [],
      series: [
        {
          name: "Funnel Series",
          data: [1380, 1100, 990, 880],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            barHeight: "60%",
            borderRadius: 4,
            borderRadiusApplication: "end",
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ["All Leads", "Quality Leads", "Site Visits", "Sales"],
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Google Ads",
          align: "center",
        },
      },
      chartMetaOptions: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: "end",
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ["All Leads", "Quality Leads", "Site Visits", "Sales"],
        },

        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Meta Ads",
          align: "center",
        },
      },
      chartAllOptions: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: "end",
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true, // Display values on the bars
        },
        xaxis: {
          categories: ["All Leads", "Quality Leads", "Site Visits", "Sales"],
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Overall",
          align: "center",
        },
        hiddenSeries: ["All Funnel"],
        legend: {
          show: false, // Hide the legend completely
        },
      },
      allOpportnutiesFunnel: [],
      allOpportnutiesFunnelValue: [],
      chartOpportunitesOptions: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: "end",
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Opportunities Funnels",
          align: "left",
        },
      },
      totalLeadSquaredData: {},
      selectedStartDate: "",
      selectedEndDate: "",
      productProjectNamesChange: [],
      productProjectIntialNamesChange: [],
      productWiseTypeOptions: [],
      creativeOptions: [],
      intiallOptionsForGroup: [],
      optionsLength: 0,
      zoneWiseLength: 0,
      selectedZoneTypeForFilter: [],
      selectedProductType: "all",
      adRunningError: "",
      zoneWiseTypeOptions: null,
      zoneWiseTypeOptionsFlag: false,
      openScheduleModal: false,
      isProductDatafetched: false,
      isZonewiseDatafetched: false,
      productWiseData: [],
      zoneWiseData: [],
      startDateProjectName: "",
      endDateProjectName: "",
      tabName: "",
      projectsTab: false,
      isProjectDataLoad: false,
      client: {
        status: false,
        integrations: {
          googleAds: null,
        },
        googleAdsCustomerID: null,
      },
      errorMessage: "",
      iconType: "",
      breadcrumbs: [
        {
          text: "Overview",
          to: "/dashboard",
        },
      ],
      isReportDataLoading: true,
      isClientLoad: true,
      campaigns: [],
      charts: {
        data: [],
        totalData: {},
      },
      graphs: [],
      currentDateRange: "",
      chartData: [],
      projectData: [],
      selectedZoneTypeForFilter: [],
      zoneListChange: [],
      leadsData: [],
      campaignsTableData: [],
      isOpportunitiesData: false,
      isOpportunitiesFunnelData: false,
      opportunitiesFunnelData: [],
      campaignsData: [],
      contactsData: [],
      reportsData: [],
      dashboardData: [],
      quarterlyData: [],
      isqQuarterlyDataLoad: false,
      isDashboardDataLoad: false,
      isReportsDataLoad: false,
      isLeadsDataLoad: false,
      isContactsDataLoad: false,
      isCampaignsDataLoad: false,
      isTotalIsLoad: false,
    };
  },
  components: {
    BRow,
    BCol,
    BCardTitle,
    BFormCheckbox,
    BBreadcrumb,
    BBreadcrumbItem,
    BAlert,
    BLink,
    BAvatar,
    BCard,
    BButton,
    BOverlay,
    BTabs,
    BTab,
    DateSelector,
    MultiHeaderProjectChannel,
    multiChannelSpinner,
    SalesforceTable,
    DataTable,
    apexchart: VueApexCharts,
    BCardText,
  },
  methods: {
    updateComparisonChart() {
      this.chartComparisonOptions = {
        chart: {
          type: "bar",
          stacked: true, // Enables stacking of bars
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true, // Makes the bars horizontal
            barHeight: "50%", // Adjust bar height
          },
        },
        xaxis: {
          categories: this.xaxisDataComparison, // Sources
        },
        legend: {
          position: "top",
        },
      colors: ["#da47ff", "#8505fc"], // Colors for the two series
        tooltip: {
          shared: true, // Enables shared tooltip
          intersect: false, // Prevent conflict with shared tooltip
        },
      }

    },
    fetchLeadsBySource(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isLeadsBySource = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!startDate && !this.endDate) {
        this.startDateProjectName = " ";
        this.endDateProjectName = " ";
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate;
        this.endDateProjectName = formattedEndDate;
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
        this.startDateProjectName = " ";
        this.endDateProjectName = " ";
        this.startDateProjectName = startDate;
        this.endDateProjectName = endDate;
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/salesforce/leads/bysource/?clientID=${this.client._id}&channel=all&${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          const sources = [];
          response.data.data.forEach(item => {
            sources.push(item.source);
            this.seriescomparison[0].data.push(item.leads);
            this.seriescomparison[1].data.push(item.convertedLeads);
          });
          this.seriescomparison[0].name = "Leads"
          this.seriescomparison[1].name = "Converted Leads"
          this.xaxisDataComparison = sources
          this.updateComparisonChart();
          this.isLeadsBySource = false;
        })
        .catch((error) => {
          this.isLeadsBySource = false;
          console.log(error);
        });
    },
    fetchOpportunitiesTrends(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isTrendsFetch = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!startDate && !this.endDate) {
        this.startDateProjectName = " ";
        this.endDateProjectName = " ";
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate;
        this.endDateProjectName = formattedEndDate;
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
        this.startDateProjectName = " ";
        this.endDateProjectName = " ";
        this.startDateProjectName = startDate;
        this.endDateProjectName = endDate;
      }
      this.funnelAllData = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/salesforce/opportunities/trends/?clientID=${this.client._id}&channel=all&${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.winRate = Number(
            response.data.response.winRate.replace("%", "")
          );
          this.conversionRate = Number(
            response.data.response.conversionRate.replace("%", "")
          );
          let remainingPerWin = 100 - this.winRate
          let remainingPerCR = 100 - this.conversionRate
          this.winRateSeries = [remainingPerWin, this.winRate]
          this.conversionRateSeries = [remainingPerCR, this.conversionRate]

          this.isTrendsFetch = false;
        })
        .catch((error) => {
          this.isTrendsFetch = false;
          console.log(error);
        });
    },
    getStepStyle(data) {
      // Set width based on the value
      const maxValue = Math.max(
        ...this.funnelAllData.map((item) => item.value)
      );
      let width = Math.max((data.value / maxValue) * 100, this.minBarWidth);

      // Add custom style properties for each step of the funnel
      return {
        width: `${width}%`,
        backgroundColor: `${data.color}`,
        height: "40px",
        marginBottom: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        color: "#fff",
      };
    },
    getStepMetaStyle(data) {
      // Set width based on the value
      const maxValue = Math.max(
        ...this.funnelMetaData.map((item) => item.value)
      );
      let width = Math.max((data.value / maxValue) * 100, this.minBarMetaWidth);

      // Add custom style properties for each step of the funnel
      return {
        width: `${width}%`,
        backgroundColor: `${data.color}`,
        height: "40px",
        marginBottom: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        color: "#fff",
      };
    },
    getStepGoogleStyle(data) {
      // Set width based on the value
      const maxValue = Math.max(
        ...this.funnelGoogleData.map((item) => item.value)
      );
      let width = Math.max(
        (data.value / maxValue) * 100,
        this.minBarGoogleWidth
      );

      // Add custom style properties for each step of the funnel
      return {
        width: `${width}%`,
        backgroundColor: `${data.color}`,
        height: "40px",
        marginBottom: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        color: "#fff",
      };
    },
    updateOpportunitiesFunnel() {
      this.chartOpportunitesOptions = {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: "end",
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: this.allOpportnutiesFunnel,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Opportunities Funnels",
          align: "left",
        },
      };
    },
    initializeChart() {
      const categories = this.quarterlyData.map(
        (item) => `Q${item.quarter} ${item.year}`
      );
      const revenueData = this.quarterlyData.map((item) => item.totalRevenue);
      const dealsData = this.quarterlyData.map((item) => item.totalDeals);

      this.chartQuarterOptions.xaxis.categories = categories;

      this.chartQuaterSeries = [
        {
          name: "Total Revenue",
          type: "column",
          data: revenueData,
        },
        {
          name: "Total Deals",
          type: "line",
          data: dealsData,
        },
      ];
    },
    updateChartData() {
      this.initializeChart(); // Reinitialize chart data with new entries
    },
    getSourceData(data) {
      this.selectedSourceType = data;
    },
    getLeadsSourceData(data) {
      this.selectedLeadsFunnel = data;
    },
    getActiveCampaign(data) {
      this.selectedActiveCampaign = data;
    },
    fetchLeadsFunnelDataAll(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isLeadsFetch = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!startDate && !this.endDate) {
        this.startDateProjectName = " ";
        this.endDateProjectName = " ";
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate;
        this.endDateProjectName = formattedEndDate;
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
        this.startDateProjectName = " ";
        this.endDateProjectName = " ";
        this.startDateProjectName = startDate;
        this.endDateProjectName = endDate;
      }
      this.funnelAllData = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/salesforce/leads/funnel/?clientID=${this.client._id}&channel=all&${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          let resultData = response.data.response;
          this.groupData = resultData;
          this.funnelAllData = [
            {
              label: "Leads",
              value: resultData.allLeads,
              color: "#1d7801",
            },
            {
              label: "Qulaified Leads",
              value: resultData.qualityLeads,
              color: "#28a602",
            },
            {
              label: "Site Visits",
              value: resultData.siteVisits,
              color: "#46bf21",
            },
            {
              label: "Sales",
              value: resultData.sales,
              color: "#50e324",
            },
          ];
          (this.seriesall = [
            {
              name: "All Funnel",
              hidden: true,
              data: [
                resultData.allLeads,
                resultData.qualityLeads,
                resultData.siteVisits,
                resultData.sales,
              ],
            },
          ]),
            (this.isLeadsFetch = false);
        })
        .catch((error) => {
          this.isLeadsFetch = false;
          console.log(error);
        });
    },
    fetchLeadsFunnelDataMeta(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isLeadsFetch = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!startDate && !this.endDate) {
        this.startDateProjectName = " ";
        this.endDateProjectName = " ";
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate;
        this.endDateProjectName = formattedEndDate;
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
        this.startDateProjectName = " ";
        this.endDateProjectName = " ";
        this.startDateProjectName = startDate;
        this.endDateProjectName = endDate;
      }
      this.funnelMetaData = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/salesforce/leads/funnel/?clientID=${this.client._id}&channel=meta&${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          let resultData = response.data.response;
          this.funnelMetaData = [
            {
              label: "Leads",
              value: resultData.allLeads,
              color: "#004e8a",
            },
            {
              label: "Qulaified Leads",
              value: resultData.qualityLeads,
              color: "#006ec2",
            },
            {
              label: "Site Visits",
              value: resultData.siteVisits,
              color: "#008FFB",
            },
            {
              label: "Sales",
              value: resultData.sales,
              color: "#2fa3fa",
            },
          ];
          console.log();
          // (this.seriesmeta = [
          //   {
          //     name: "Meta Funnel",
          //     data: [
          //       resultData.allLeads,
          //       resultData.qualityLeads,
          //       resultData.siteVisits,
          //       resultData.sales,
          //     ],
          //   },
          // ]),
          this.isLeadsFetch = false;
        })
        .catch((error) => {
          this.isLeadsFetch = false;
          console.log(error);
        });
    },
    fetchLeadsFunnelDataGoogle(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isLeadsFetch = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!startDate && !this.endDate) {
        this.startDateProjectName = " ";
        this.endDateProjectName = " ";
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate;
        this.endDateProjectName = formattedEndDate;
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
        this.startDateProjectName = " ";
        this.endDateProjectName = " ";
        this.startDateProjectName = startDate;
        this.endDateProjectName = endDate;
      }
      this.funnelGoogleData = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/salesforce/leads/funnel/?clientID=${this.client._id}&channel=googleAds&${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          let resultData = response.data.response;
          this.funnelGoogleData = [
            {
              label: "Leads",
              value: resultData.allLeads,
              color: "#CC6600",
            },
            {
              label: "Qulaified Leads",
              value: resultData.qualityLeads,
              color: "#FF8000",
            },
            {
              label: "Site Visits",
              value: resultData.siteVisits,
              color: "#fa9907",
            },
            {
              label: "Sales",
              value: resultData.sales,
              color: "#FFA64D",
            },
          ];
          console.log();
          this.isLeadsFetch = false;
        })
        .catch((error) => {
          this.isLeadsFetch = false;
          console.log(error);
        });
    },
    fetchOpportuniesData() {
      this.isOpportunitiesData = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/salesforce/opportunities/?clientID=${this.client._id}&channel=${this.selectedSourceType}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.opprtunities =
            response && response.data && response.data.opportunities;
          this.isOpportunitiesData = false;
        })
        .catch((error) => {
          this.isOpportunitiesData = false;
          console.log(error);
        });
    },
    fetchOpportuniesFunnelData() {
      this.seriesOpportunities = [];
      this.allOpportnutiesFunnel = [];
      this.isOpportunitiesFunnelData = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/salesforce/opportunities/funnel/?clientID=${this.client._id}&channel=${this.selectedSourceType}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          let opportunities = response.data.opportunities;
          let formattedData = opportunities.map((item) => ({
            stage: item.StageName,
            value: item.expr1,
          }));

          formattedData.map((i) => {
            this.allOpportnutiesFunnel.push(i.stage);
          });
          this.seriesOpportunities = [
            {
              name: "Opportunities Funnel",
              data: formattedData.map((d) => d.value),
            },
          ];
          this.updateOpportunitiesFunnel();
          this.opportunitiesFunnelData = formattedData;
          this.isOpportunitiesFunnelData = false;
        })
        .catch((error) => {
          this.isOpportunitiesFunnelData = false;
          console.log(error);
        });
    },
    fetchQuarterlyData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isqQuarterlyDataLoad = true;
      // this.showSummaryDashboardLoading = true;
      this.isProjectWiseIsLoad = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!startDate && !this.endDate) {
        this.startDateProjectName = " ";
        this.endDateProjectName = " ";
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate;
        this.endDateProjectName = formattedEndDate;
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
        this.startDateProjectName = " ";
        this.endDateProjectName = " ";
        this.startDateProjectName = startDate;
        this.endDateProjectName = endDate;
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/salesforce/quarterly-performance/?clientID=${this.client._id}&${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.quarterlyData =
            response && response.data && response.data.performance;
          this.initializeChart();
          this.isqQuarterlyDataLoad = false;
        })
        .catch((error) => {
          this.isqQuarterlyDataLoad = false;
          console.log(error);
        });
    },
    // fetchDashboardData(
    //   dateRange,
    //   campaignType = null,
    //   campaigns = null,
    //   adGroups = null,
    //   startDate = null,
    //   endDate = null
    // ) {
    //   this.isDashboardDataLoad = true;
    //   if (!dateRange) {
    //     dateRange = "LAST_7_DAYS";
    //   }
    //   this.currentDateRange = dateRange;
    //   let additionalQueryParams = ``;
    //   if (!startDate && !this.endDate) {
    //     this.startDateProjectName = " "
    //     this.endDateProjectName = " "
    //     const today = new Date();
    //     const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    //     const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
    //     const formattedStartDate = prevWeek.toISOString().split("T")[0];
    //     const formattedEndDate = yesterday.toISOString().split("T")[0];
    //     additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    //     this.startDateProjectName = formattedStartDate
    //     this.endDateProjectName = formattedEndDate
    //   }
    //   if (startDate && endDate) {
    //     additionalQueryParams =
    //       additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
    //     this.startDateProjectName = " "
    //     this.endDateProjectName = " "
    //     this.startDateProjectName = startDate
    //     this.endDateProjectName = endDate
    //   }
    //   axios
    //     .get(
    //       `${process.env.VUE_APP_SERVER_URL}/api/salesforce/dashboards/?clientID=${this.client._id}&${additionalQueryParams}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${getUserToken()}`
    //         }
    //       }
    //     )
    //     .then(response => {
    //       this.dashboardData = response && response.data && response.data.dashboards
    //       this.isDashboardDataLoad = false;
    //     })
    //     .catch(error => {
    //       this.isDashboardDataLoad = false;
    //       console.log(error);
    //     });
    // },
    // fetchContactsData(
    //   dateRange,
    //   campaignType = null,
    //   campaigns = null,
    //   adGroups = null,
    //   startDate = null,
    //   endDate = null
    // ) {
    //   this.isContactsDataLoad = true;
    //   if (!dateRange) {
    //     dateRange = "LAST_7_DAYS";
    //   }
    //   this.currentDateRange = dateRange;
    //   let additionalQueryParams = ``;
    //   if (!startDate && !this.endDate) {
    //     this.startDateProjectName = " "
    //     this.endDateProjectName = " "
    //     const today = new Date();
    //     const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    //     const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
    //     const formattedStartDate = prevWeek.toISOString().split("T")[0];
    //     const formattedEndDate = yesterday.toISOString().split("T")[0];
    //     additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    //     this.startDateProjectName = formattedStartDate
    //     this.endDateProjectName = formattedEndDate
    //   }
    //   if (startDate && endDate) {
    //     additionalQueryParams =
    //       additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
    //     this.startDateProjectName = " "
    //     this.endDateProjectName = " "
    //     this.startDateProjectName = startDate
    //     this.endDateProjectName = endDate
    //   }
    //   axios
    //     .get(
    //       `${process.env.VUE_APP_SERVER_URL}/api/salesforce/contacts/?clientID=${this.client._id}&${additionalQueryParams}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${getUserToken()}`
    //         }
    //       }
    //     )
    //     .then(response => {
    //       this.contactsData = response && response.data && response.data.contacts
    //       this.isContactsDataLoad = false;
    //     })
    //     .catch(error => {
    //       this.isContactsDataLoad = false;
    //       console.log(error);
    //     });
    // },
    // fetchReportsData(
    //   dateRange,
    //   campaignType = null,
    //   campaigns = null,
    //   adGroups = null,
    //   startDate = null,
    //   endDate = null
    // ) {
    //   this.isReportDataLoading = true;
    //   if (!dateRange) {
    //     dateRange = "LAST_7_DAYS";
    //   }
    //   this.currentDateRange = dateRange;
    //   let additionalQueryParams = ``;
    //   if (!startDate && !this.endDate) {
    //     this.startDateProjectName = " "
    //     this.endDateProjectName = " "
    //     const today = new Date();
    //     const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    //     const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
    //     const formattedStartDate = prevWeek.toISOString().split("T")[0];
    //     const formattedEndDate = yesterday.toISOString().split("T")[0];
    //     additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    //     this.startDateProjectName = formattedStartDate
    //     this.endDateProjectName = formattedEndDate
    //   }
    //   if (startDate && endDate) {
    //     additionalQueryParams =
    //       additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
    //     this.startDateProjectName = " "
    //     this.endDateProjectName = " "
    //     this.startDateProjectName = startDate
    //     this.endDateProjectName = endDate
    //   }
    //   axios
    //     .get(
    //       `${process.env.VUE_APP_SERVER_URL}/api/salesforce/reports/?clientID=${this.client._id}&${additionalQueryParams}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${getUserToken()}`
    //         }
    //       }
    //     )
    //     .then(response => {
    //       this.reportsData = response && response.data && response.data.reports
    //       this.isReportDataLoading = false;
    //     })
    //     .catch(error => {
    //       this.isReportDataLoading = false;
    //       console.log(error);
    //     });
    // },
    fetchLeadsCampaignData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isFetchedLeadsCampaignData = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!startDate && !this.endDate) {
        this.startDateProjectName = " ";
        this.endDateProjectName = " ";
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate;
        this.endDateProjectName = formattedEndDate;
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
        this.startDateProjectName = " ";
        this.endDateProjectName = " ";
        this.startDateProjectName = startDate;
        this.endDateProjectName = endDate;
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/salesforce/leads/duration/?clientID=${this.client._id}&channel=${this.selectedLeadsFunnel}&${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.leadsDataDuration =
            response && response.data && response.data.response;

          if (this.leadsDataDuration) {
            // Convert the object into an array, preserving the date and adding the index
            const updatedData = Object.keys(this.leadsDataDuration).map(
              (date, index) => {
                return {
                  ...this.leadsDataDuration[date], // Copy the data for the specific date
                  date: date, // Include the date key itself
                };
              }
            );

            // Assign the updated data to `leadsDataDuration`
            this.leadsDataDuration = updatedData;
          }
          this.isFetchedLeadsCampaignData = false;
        })
        .catch((error) => {
          this.isFetchedLeadsCampaignData = false;
          console.log(error);
        });
    },
    fetchActiveCampaignData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isCampaignsDataLoad = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!startDate && !this.endDate) {
        this.startDateProjectName = " ";
        this.endDateProjectName = " ";
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate;
        this.endDateProjectName = formattedEndDate;
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
        this.startDateProjectName = " ";
        this.endDateProjectName = " ";
        this.startDateProjectName = startDate;
        this.endDateProjectName = endDate;
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/salesforce/leads/campaign/?clientID=${this.client._id}&channel=${this.selectedActiveCampaign}&${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isCampaignsDataLoad = false;
          let processedLeads = [];
          if (response && response.data) {
            const processedLeads = response.data.map((record) => ({
              CampaignName: record.campaign,
              All: record.leadsFunnel.allLeads || 0,
              Qualified: record.leadsFunnel.qualifiedLeads || 0,
              Sales: record.leadsFunnel.sales || 0,
              SiteVisits: record.leadsFunnel.siteVisits || 0,
            }));
            this.campaignsTableData = processedLeads;
          }
        })
        .catch((error) => {
          this.isCampaignsDataLoad = false;
          console.log(error);
        });
    },
    changeTab(name) {
      this.tabName = name;
    },
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
    formatDateDisplay(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    formatDate(inputDate) {
      const [year, month, day] = inputDate.split("-");
      const updatedDate = `${day}-${month}-${year}`;
      return updatedDate;
    },
    getClient() {
      const slug = this.$route.params.slug;
      this.isClientLoad = true;
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.client = { ...response.data.client, status: true };
          this.graphs = response.data.client.googleAdsGraphs
            ? response.data.client.googleAdsGraphs
            : [];
          this.breadcrumbs.push({
            text: response.data.client.name,
            to: `/dashboard`,
            active: false,
          });
          this.breadcrumbs.push({
            text: "Salesforce Dashboard",
            to: `/client/${response.data.client.slug}/dashboard/salesforce`,
            active: true,
          });
          this.fetchActiveCampaignData();
          this.fetchLeadsCampaignData();
          // this.fetchContactsData();
          // this.fetchReportsData();
          this.fetchQuarterlyData();
          // this.fetchDashboardData();
          this.fetchOpportuniesData();
          this.fetchOpportuniesFunnelData();
          // this.fetchAllDataWiseData()
          this.fetchLeadsFunnelDataMeta();
          this.fetchLeadsFunnelDataGoogle();
          this.fetchLeadsFunnelDataAll();
          this.fetchOpportunitiesTrends();
          this.fetchLeadsBySource();
          this.isClientLoad = false;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.client.status = false;
          this.errorMessage = error.response.data.error;
          this.isClientLoad = false;
        });
    },
  },
  filters: {
    date(val) {
      return val ? moment(val).format("DD-MM-YYYY") : "";
    },
  },
  watch: {
    selectedSourceType(newVal, oldVal) {
      this.selectedSourceType = "";
      this.selectedSourceType = newVal;
      this.fetchOpportuniesData();
      this.fetchOpportuniesFunnelData();
    },
    selectedLeadsFunnel(newVal, oldVal) {
      this.selectedLeadsFunnel = "";
      this.selectedLeadsFunnel = newVal;
      this.fetchLeadsCampaignData();
      // this.fetchLeadsFunnelDataAll();
      // this.fetchLeadsFunnelDataGoogle();
      // this.fetchLeadsFunnelDataMeta();
    },
    selectedActiveCampaign(newVal, oldVal) {
      this.selectedActiveCampaign = "";
      this.selectedActiveCampaign = newVal;
      this.fetchActiveCampaignData();
    },
  },
  mounted() {
    this.tabName = "Leads";
    this.getClient();
  },
};
</script>
<style lang="scss">
// @import "@core/scss/vue/libs/vue-select.scss";

.salesforceAlignments {
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  .card-body {
    padding: 0.5rem !important;
  }
}

.breadcrumb-container a {
  color: antiquewhite !important;
}

.dashboard-overlay .rounded-sm {
  inset: -20px !important;
}

.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  /* Set the height of the container */
}

.funnel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 90%;
}

.funnel-step {
  text-align: center;
  margin: 2px 50px;
  transition: all 0.3s ease;
}

.funnel-step .funnel-label {
  position: absolute;
  top: -25px;
  font-size: 12px;
  font-weight: bold;
}

.funnel-step .funnel-bar {
  font-size: 12px;
  font-weight: 600;
}
</style>
