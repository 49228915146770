var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mainDashboardContainer"},[(_vm.client.status)?_c('b-row',{staticClass:"content-header"},[_c('b-col',{staticClass:"content-header-left mb-2",attrs:{"cols":"10"}},[_c('b-row',{staticClass:"breadcrumbs-top"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"content-header-title float-left pr-1 mb-0 text-white"},[_c('b-avatar',{staticClass:"mr-1",attrs:{"size":"32","src":_vm.client.logo,"text":_vm.avatarText(_vm.client.name),"variant":_vm.client.logo ? "white" : "primary"}}),_vm._v(" "+_vm._s(_vm.client.name)+" ")],1),_c('div',{staticClass:"breadcrumb-wrapper breadcrumb-container"},[_c('b-breadcrumb',[_c('b-breadcrumb-item',{attrs:{"to":"/"}},[_c('feather-icon',{staticClass:"align-text-top text-white",attrs:{"icon":"HomeIcon","size":"16"}})],1),_vm._l((_vm.breadcrumbs),function(item){return _c('b-breadcrumb-item',{key:item.text,staticClass:"text-white",attrs:{"active":item.active,"to":item.to}},[_vm._v(" "+_vm._s(item.text)+" ")])})],2)],1)])],1)],1),_c('b-col',{attrs:{"cols":"2"}})],1):_vm._e(),(_vm.client.status &&
      _vm.client.integrations.dv360 &&
      _vm.client.dv360.partnerID.length > 0
      )?_c('div',[_c('b-overlay',{staticClass:"dashboard-overlay",attrs:{"show":_vm.isClientLoad || _vm.isReportDataLoading || _vm.isReportDataDateWiseLoading,"rounded":"sm"}},[_c('date-selector',{attrs:{"client":_vm.client._id,"slug":_vm.$route.params.slug,"businessType":_vm.client.businessType,"iconCardType":_vm.iconType,"groupCardFlag":_vm.isReportDataDateWiseLoading,"summaryData":_vm.overviewData,"chartSummaryData":_vm.overviewData},on:{"fetchDailyReport":_vm.getDateWiseReportData,"fetchReportData":_vm.getReportData}}),_c('b-card',{staticClass:"tabs-container screenAlignmentFacbook p-1"},[_c('b-tabs',{attrs:{"fill":""}},[_c('b-tab',{attrs:{"title":"Overview","active":""},on:{"click":function($event){return _vm.changeTab('Overview')}}}),_c('b-tab',{attrs:{"title":"Daily Report"},on:{"click":function($event){return _vm.changeTab('DailyReport')}}}),_c('b-tab',{attrs:{"title":"Campaign Report"},on:{"click":function($event){return _vm.changeTab('CampaignReport')}}})],1)],1),(this.tabName === 'DailyReport')?_c('data-table',{attrs:{"title":"Daily Report","exportsType":"dv360Daily","type":"dv360-daily-report","chartData":_vm.datewiseData,"businessType":_vm.client.businessType[0]}}):_vm._e(),(this.tabName === 'CampaignReport')?_c('data-table',{attrs:{"title":"Campaign Report","type":"DV360CampaignReportcebook","exportsType":"DV360CampaignReportcebook","chartData":_vm.campaignsDV360Data,"businessType":_vm.client.businessType[0]}}):_vm._e(),(
          this.tabName === 'Overview' &&
            _vm.client.status &&
            _vm.client.integrations.dv360 &&
            _vm.client.dv360.partnerID.length > 0
        )?_c('Dv360OverView',{attrs:{"isDataLoading":_vm.isReportDataLoading,"summaryData":_vm.overviewData,"chartData":_vm.dv360ChartData,"iconCardType":_vm.iconType,"businessType":_vm.client.businessType,"mediumType":"aAds"}}):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }