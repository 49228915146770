<template>
    <div>
      <b-row class="match-height-container">
        <template v-if="this.businessType[0] == 'leadgen'">
            <b-col cols="3" class="chartContainer">
              <Dv360Charts v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
                :data="faceBookChartValue.spendsData" title="Spends" :summary="summaryData.spends" />
            </b-col>
            <b-col cols="3" class="chartContainer">
              <Dv360Charts v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
                :data="faceBookChartValue.impressionsData" title="Impressions" :summary="summaryData.impressions" />
            </b-col>
            <b-col cols="3" class="chartContainer">
              <Dv360Charts v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
                :data="faceBookChartValue.clicksData" title="Clicks" :summary="summaryData.clicks" />
            </b-col>
            <b-col cols="3" class="chartContainer">
              <Dv360Charts v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
                :data="faceBookChartValue.frequencyData" title="Frequency" :summary="summaryData.frequency" />
            </b-col>
            <b-col cols="3" class="chartContainer">
              <Dv360Charts v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
                :data="faceBookChartValue.CTRData" title="Reach" :summary="summaryData.reach" />
            </b-col>
            <b-col cols="3" class="chartContainer">
              <Dv360Charts v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
                :data="faceBookChartValue.reachData" title="Reach" :summary="summaryData.reach" />
            </b-col>
            <b-col cols="3" class="chartContainer">
              <Dv360Charts v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
                :data="faceBookChartValue.cpvData" title="CPV" :summary="summaryData.cpv" />
            </b-col>
            <b-col cols="3" class="chartContainer">
              <Dv360Charts v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
                :data="faceBookChartValue.trueViewsData" title="True View" :summary="summaryData.trueViews" />
            </b-col>
          </template>
        <template v-else>
            <b-col cols="3" class="chartContainer">
              <Dv360Charts v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
                :data="faceBookChartValue.spendsData" title="Spends" :summary="summaryData.spends" />
            </b-col>
            <b-col cols="3" class="chartContainer">
              <Dv360Charts v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
                :data="faceBookChartValue.impressionsData" title="Impressions" :summary="summaryData.impressions" />
            </b-col>
            <b-col cols="3" class="chartContainer">
              <Dv360Charts v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
                :data="faceBookChartValue.clicksData" title="Clicks" :summary="summaryData.clicks" />
            </b-col>
            <b-col cols="3" class="chartContainer">
              <Dv360Charts v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
                :data="faceBookChartValue.frequencyData" title="Frequency" :summary="summaryData.frequency" />
            </b-col>
            <b-col cols="3" class="chartContainer">
              <Dv360Charts v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
                :data="faceBookChartValue.CTRData" title="Reach" :summary="summaryData.ctr" />
            </b-col>
            <b-col cols="3" class="chartContainer">
              <Dv360Charts v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
                :data="faceBookChartValue.reachData" title="Reach" :summary="summaryData.reach" />
            </b-col>
            <b-col cols="3" class="chartContainer">
              <Dv360Charts v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
                :data="faceBookChartValue.cpvData" title="CPV" :summary="summaryData.cpv" />
            </b-col>
            <b-col cols="3" class="chartContainer">
              <Dv360Charts v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
                :data="faceBookChartValue.trueViewsData" title="True View" :summary="summaryData.trueViews" />
            </b-col>
          </template>
      </b-row>
    </div>
  </template>
  
  <script>
  import { BCard, BRow, BCol } from "bootstrap-vue";
  //   import CardStatisticsGroup from "../GoogleAds/CardStatisticsGroup.vue";
  import Dv360Charts from "./Dv360Charts.vue";
  
  export default {
    props: {
      data: {
        type: Object,
        default: () => {
          return {};
        }
      },
      iconCardType: { type: String },
      summaryData: {
        type: Object,
        default: () => {
          return {
            spends: 0,
            impressions: 0,
            trueViews: 0,
            clicks: 0,
            reach: 0,
            frequency: 0,
            vtr: 0,
            ctr: 0,
            cpv: 0,
            cpm: 0
          };
        }
      },
      chartData: {
        type: Array,
        default: () => {
          return [];
        }
      },
      businessType: {
        type: Array,
        default: () => {
          return [];
        }
      }
    },
    data() {
      return {
        iconType: "Hello"
      };
    },
    computed: {
      faceBookChartValue() {
        let data = this.chartData;
        let labels = [];
        let clicksData = [{ name: "Clicks", data: [] }];
        let spendsData = [{ name: "Spends", data: [] }];
        let impressionsData = [{ name: "Spends", data: [] }];
        let reachData = [{ name: "reach", data: [] }];
        let trueViewsData = [{ name: "True Views", data: [] }];
        let frequencyData = [{ name: "Frequency", data: [] }];
        let vtrData = [{ name: "VTR", data: [] }];
        let CTRData = [{ name: "CTR", data: [] }];
        let cpvData = [{ name: "CTR", data: [] }];

        for (let i = 0; i < data.length; i++) {
          const dataObject = data[i];
          labels.push(dataObject.date);
          clicksData[0].data.push(dataObject.data.clicks);
          spendsData[0].data.push(dataObject.data.spends);
          impressionsData[0].data.push(dataObject.data.impressions);
          reachData[0].data.push(dataObject.data.reach);
          trueViewsData[0].data.push(dataObject.data.trueViews);
          frequencyData[0].data.push(dataObject.data.frequency);
          vtrData[0].data.push(dataObject.data.vtr);
          CTRData[0].data.push(dataObject.data.ctr);
          cpvData[0].data.push(dataObject.data.cpv);
        }
        return {
          labels,
          clicksData,
          spendsData,
          reachData,
          trueViewsData,
          vtrData,
          CTRData,
          CTRData,
          frequencyData,
          cpvData,
          impressionsData

        };
      }
    },
    components: {
      BCard,
      BRow,
      BCol,
      //   CardStatisticsGroup,
      Dv360Charts
    }
  };
  </script>
  <style>
  .chartContainer {
    padding: 6px !important;
  }
  </style>
  