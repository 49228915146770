<template>
  <div>
    <b-row v-if="client.status" class="content-header">
      <b-col class="content-header-left mb-2" cols="12" md="12">
        <b-row class="breadcrumbs-top">
          <b-col cols="9">
            <h2 class="content-header-title float-left pr-1 mb-0 text-white">
              <b-avatar class="mr-1" size="32" :src="client.logo"
                style="background-color: white; border: none; color: #6C4E96; font-weight: bold;"
                :text="avatarText(client.name)" :variant="client.logo ? `white` : `light-primary`" />
              {{ client.name }}
            </h2> 
            <div class="breadcrumb-wrapper breadcrumb-container">
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
                </b-breadcrumb-item>
                <b-breadcrumb-item class="text-white" v-for="item in breadcrumbs" :key="item.text" :active="item.active"
                  :to="item.to">
                  {{ item.text }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
          <b-col cols="3">
            <div class="text-right">
              <b-button variant="outline-primary" class="text-white" @click="
                initAccessControl({ clientID: client._id, name: client.name })
                "><feather-icon icon="Share2Icon"></feather-icon> Share with
                client</b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-alert :show="!client.status" variant="danger">
      <h4 class="alert-heading">Error fetching Client data</h4>
      <div class="alert-body">
        {{ errorMessage }}. Check
        <b-link class="alert-link" :to="{ name: 'client-list' }">
          Clients List
        </b-link>
        for other clients.
      </div>
    </b-alert>

    <div v-if="client.status">
      <ChannelConnector
        class="mt-2"
        :integrations="client.integrations"
        @dv360Connect="dv360Connect"
        @fetchAnalyticsAccounts="fetchAnalyticsAccounts"
        @fetchSalesforceAccounts="fetchSalesforceAccounts"
        @googleAnalyticsConnect="googleAnalyticsConnect"
        @fetchFacebookAdAccounts="fetchFacebookAdAccounts"
        @googleAdsConnect="googleAdsConnect"
        @salesforceConnect="salesforceConnect"
        @gmbConnect="gmbConnect"
        @amazonConnect="amazonConnect"  
        @selectDv360Accounts="fetchDv360Partner"
        @shopifyConnect="shopifyConnect"  
        :shopName="shopName"
        @facebookConnect="facebookConnect"
        @fetchedLQclients="getClient"
        @selectGoogleAdsCustomers="fetchGoogleAdsCustomers"
        @selectAmazonProfile="fetchAmazonProfile"
        @selectGMBCustomers="fetchGMBCustomers"
        @selectFacebookAdsAccount="fetchFacebookCustomers"
        :isFetchingAccounts="isFetchingAccounts"
        :isFetchingSalesforceAccounts="isFetchingSalesforceAccounts"
        :isFetchingAdsCustomers="isFetchingAdsCustomers"
        :isFetchingAdsFacebook="isFetchingAdsFacebook"
        :isFetchingGMBAccounts="isFetchingGMBAccounts"
        :isFetchingFacebookAccounts="isFetchingFacebookAccounts"
        :isAmazonProfileLoading="isFetchingAmazonProfile"
        :isFetchingDv360="isFetchingDv360"
        :googleViewID="client.googleViewID"
        :googleViewProfile="client.googleViewProfile"
        :googleAdsCustomerID="client.googleAdsCustomerID"
        :googleAds="client.googleAds"
        :dv360="client.dv360"
        :next="nextAction"
        :slug="client.slug"
        :role="client.role"
        :gmbName="gmbAccountName"
        :salesforceAccountName="salesforceAccountName"
        :leadSquaredDetails="leadSquaredDetailsObject"
        :accountID="accountName"
        :googleAdError="googleAdsError"
        :facebookAccountName="facebookAccountNames"
        :amazonProfileName="amazonProfileNames"
        :mainId="client._id"
        :allPermission="responseData"
        :isProjectEnabled="isProjectEnabled"
        :isSessionExpiredForMeta="isSessionExpiredForMeta"
      />
    </div>
    <!-- 
    <MetricDimensionSelector
      @googleMetricSearch="getGoogleMetricsDimension"
      @saveGoogleMetricDimensionData="saveGoogleMetricDimensionData"
      :googleMetricsData="googleMetricsDimenion"
      :facebookMetricsData="facebookMetricsDimenion"
      :googleIntegration="client.integrations.google ? true : false"
      :facebookIntegration="client.integrations.facebook ? true : false"
      :googleAdsIntegration="client.integrations.googleAds ? true : false"
      :selectedGoogleMetricsDimension="selectedGoogleMetricsDimension"
      v-if="
        client.integrations.google ||
        client.integrations.facebook ||
        client.integrations.googleAds
      "
    /> -->
    <SalesforceAccountSelector :salesforceAccounts="salesforceAccounts" :accountSalesforceModal="accountSalesforceSelectorModal"
    @toggleSalesforceModal="toggleSalesforceModal" @selectSalesforceViewAccount="saveSalesforceAccount" />
    <AccountSelector :accounts="googleAccounts" :accountSelectorModal="accountSelectorModal"
      @toggleAccountSelectorModal="toggleAccountSelectorModal" @selectGoogleViewAccount="saveGoogleViewAccount" />
    <AdsCustomerSelectorModalV2 :adsCustomers="googleAdsCustomers" :adsCustomerSelectorModal="adsCustomerSelectorModal"
      @toggleAdsCustomerSelectorModal="toggleAdsCustomerSelectorModal" @saveAdsCustomerID="saveAdsCustomerID"
      :googleSelected="googleSelected" :googleSelectedIds="googleSelectedIds"/>
    <DV360Selector :dv360SelectedIds="dv360SelectedIds" :dv360SelectedNames="dv360SelectedNames" :dv360ModalToggle="dv360Selector" :dv360Accounts="dv360Accounts" @saveDv360Data="saveDv360Data" :fetchDataByClientId="this.client._id"/>
    <GMBAdsSelector :gmbCustomers="gmbAccounts" :gmbAccountModal="gmbAccountsModal"
      @toggleGMBAccountModal="toggleGMBAccountModal" @saveGMBAccountID="saveGMBAccountID" @update-modal="updateModal"
      :SendingData="GMBloading" :gmbName="gmbAccountName" :gmbSelected="gmbSelected" />
    <MultiChannelSelectorModal :isFetchingFacebookAccountData="isFetchingFacebookAccountDatas"
      :facebookCustomersTokens="facebookCustomersAllTokens" :facebookAdsModal="facebookAdsModal"
      :facebookCustomers="facebookCustomers" @update-facebook-modal="updateFacebookModal"
      @toggleFacebookModal="toggleFacebookModal" @token-prev-value="getPrevTokenValue"
      @token-next-value="getNextTokenValue" @saveFacebookccountID="saveFacebookccountID"
      @saveAmazonProfileID="saveAmazonProfileID" :facebookSelected="facebookSelected"
      :amazonProfileSelected="amazonProfileSelected" :modalType="modalTypeCheck" :amazonProfileData="amazonProfileDataRes"
      :isAmazonProfileLoading="isFetchingAmazonProfile" />
    <AccessControlPopUp @closePopUp="closeAccessControlPopUp" :client="shareAccessClientDetails"
      :isProcessingAccess="isProcessingAccess" :isProcessingRevokeAccess="isProcessingRevokeAccess"
      :show="shareAccessPopUp" @send-invite="sendInvite" @remove-access="removeAccess" />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardTitle,
  BFormCheckbox,
  BBreadcrumb,
  BBreadcrumbItem,
  BAlert,
  BLink,
  BAvatar,
  BCard,
  BButton,
} from "bootstrap-vue";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
import ClientDetail from "./UserViewUserInfoCard.vue";
import MultiChannelSelectorModal from "./MultiChannelSelectorModal.vue";
import MetricDimensionSelector from "./MetricsDimensionSelector.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AccountSelector from "./AccountSelector.vue";
import SalesforceAccountSelector from "./SalesforceAccountSelector.vue";
import ChannelConnector from "./ChannelConnector.vue";
import AdsCustomerSelectorModalV2 from "./AdsCustomerSelectorV2.vue";
import UserInvitePopUp from "@/components/client/UserInvitePopUp.vue";
import GMBAdsSelector from "./GMBAdsSelector.vue";
import DV360Selector from './DV360Selector.vue'
import AccessControlPopUp from "@/components/client/AccessControlPopUp.vue"
import store from "@/store";
import Cookies from 'js-cookie';
import useJwt from "@/auth/jwt/useJwt";
import client from "@/router/client";

export default {
  props: {
    channel: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isSessionExpiredForMeta:false,
      dv360Accounts:[],
      googleSelected:[],
      googleSelectedIds:[],
      dv360SelectedIds:[],
      dv360SelectedNames:[],
      responseData: {
        create: null,
        delete: null,
        read: null,
        // Add more keys as needed
      },
      isProjectEnabled:"",
      shopName:'quickstart-b708d7f8',
      amazonProfileDataRes:[],
      isFetchingAdsFacebook: false,
      isFetchingFacebookAccountDatas: false,
      isFetchingDv360:false,
      facebookAccountsData: [],
      facebookAdsAccountSelectorModal: false,
      breadcrumbs: [],
      client: {
        status: true,
        integrations: {
          google: null,
          facebook: null,
          googleAds: null,
        },
        analytics: {
          google: {
            users: {},
            sessions: {},
            bounceRate: {},
            avgSessionDuration: {},
          },
        },
        googleViewID: null,
        googleViewProfile: null,
        googleAdsCustomerID: null,
        googleAds: {
          googleAdsCustomerID: null,
          manager: false,
          customerClientID: null,
          managerName: null,
          customerName: null,
          customerNames: null,
        },
        role: "VIEWER",
      },
      errorMessage: "",
      googleMetricsDimenion: [],
      facebookMetricsDimenion: [],
      selectedGoogleMetricsDimension: {
        metrics: [],
        dimensions: [],
      },
      isFetchingAccounts: false,
      isFetchingSalesforceAccounts:false,
      isFetchingAdsCustomers: false,
      isFetchingGMBAccounts: false,
      isFetchingFacebookAccounts: false,
      accountSelectorModal: false,
      accountSalesforceSelectorModal: false,
      googleAccounts: [],
      salesforceAccounts: [],
      adsCustomerSelectorModal: false,
      dv360Selector: false,
      gmbAccountsModal: false,
      facebookAdsModal: false,
      googleAdsCustomers: [],
      gmbAccounts: [],
      nextAction: "",
      clientID: "",
      gmbAccountName: [],salesforceAccountName:[],
      leadSquaredDetailsObject:{},
      gmbSelected: [],
      GMBloading: false,
      accountName: [],
      googleAdsError: false,
      facebookCustomers: [],
      paginationToken: "",
      facebookCustomersAllTokens: null,
      facebookAccountNames: [],
      amazonProfileNames: [],
      facebookSelected: null,
      googleSelecteds:null,
      amazonProfileSelected: null,
      modalTypeCheck: '',
      isFetchingAmazonProfile: false,
      userInvitePopUp: false,
      shareAccessClientDetails: {
        name: "",
        clientID: "",
      },
      isProcessingAccess: false,
      shareAccessPopUp: false,
      isProcessingRevokeAccess: false,
      clients: [],
      isBusy: false
    };
  },
  components: {
    BRow,
    BCol,
    BCardTitle,
    BFormCheckbox,
    BBreadcrumb,
    BBreadcrumbItem,
    BAlert,
    BLink,
    BAvatar,
    ClientDetail,
    MetricDimensionSelector,
    ToastificationContent,
    AccountSelector,
    SalesforceAccountSelector,
    BCard,
    ChannelConnector,
    BButton,
    AdsCustomerSelectorModalV2,
    GMBAdsSelector,
    MultiChannelSelectorModal,
    AccessControlPopUp,
    DV360Selector
  },
  methods: {
    closeAccessControlPopUp() {
      this.shareAccessPopUp = false;
      this.shareAccessClientDetails = {
        name: "",
        clientID: "",
      };
    },
    fetchClients() {
      this.isBusy = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/get`,
          {
            limit: 10,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.clients = response.data.clients;
          this.isBusy = false;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    async initAccessControl(client) {
      try {
        this.shareAccessClientDetails = client;
        let accessList = await this.getAccessList(client.clientID);
        this.shareAccessClientDetails.accessList = accessList;
        this.shareAccessPopUp = true;
      } catch (error) {
        this.showToast("Error", "Unknown error occured!", "danger");
      }
    },
    sendInvite(accessListData) {
      this.isProcessingAccess = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/add-access`,
          {
            accessList: accessListData.accessList,
            clientID: accessListData.clientID,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then(async (response) => {
          let newAccessList = await this.getAccessList(accessListData.clientID);
          await this.fetchClients();
          this.shareAccessClientDetails.accessList = newAccessList;
          this.isProcessingAccess = false;
          this.shareAccessPopUp = false;
        })
        .catch((error) => {
          console.log(error);
          this.shareAccessPopUp = false;
        });
    },
    removeAccess(data) {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/remove-access`,
          data,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then(async (response) => {
          this.isProcessingRevokeAccess = false;
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAccessList(clientID) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_SERVER_URL}/api/client/get-access-list`,
            { clientID },
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
              },
            }
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getNextTokenValue(value) {
      this.fetchFacebookCustomers(value, true, false);
    },
    getPrevTokenValue(value) {
      this.fetchFacebookCustomers(value, false, true);
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    getGoogleMetricsDimension(search) {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/metrics-dimension/google`,
          {
            search,
            clientID: this.client._id,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.googleMetricsDimenion = response.data.data;
          this.selectedGoogleMetricsDimension = {
            metrics: response.data.selectedData.metrics,
            dimensions: response.data.selectedData.dimensions,
          };
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data && error.response.data.error) {
            this.showToast("Error", error.response.data.error, "danger");
            return;
          }

          if (error.response.data && error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              const error = error.response.data.errors[i];
              this.showToast("Error", `${error.msg - error.param}`, "danger");
            }
            return;
          }
          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    setClientIdInCookie(id) {
      const clientId = id;
      Cookies.set("salesforceId", clientId);
    },
    getClient() {
      const slug = this.$route.params.slug;
      this.googleSelected = []
      this.googleSelectedIds = []
      this.dv360SelectedIds = []
      this.dv360SelectedNames = []
      Cookies.remove("salesforceId");
      this.isProjectEnabled = ""
      this.responseData = {
        create: null,
        delete: null,
        read: null,
        // Add more keys as needed
      }
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          if (response && response.data && response.data.client.accessConfiguration.length ) {
            this.responseData = response.data.client.accessConfiguration.projects.items
            Object.entries(this.responseData).forEach(([key, value]) => {
              Cookies.set(key, value);
            });
            this.isProjectEnabled = response.data.client.accessConfiguration.projects.enabled
          }
          this.accessResponseDataFromCookies();
          this.amazonProfileNames = response.data.client.amazon.profileNames;
          this.amazonProfileSelected = response.data.client.amazon.profileIDs[0];
          this.facebookAccountNames = response.data.client.facebook.adAccountNames;
          this.isSessionExpiredForMeta = response.data.client.facebook.sessionExpired;
          this.facebookSelected = this.facebookAccountNames;
          this.googleSelected = response.data && response.data.client && response.data.client.googleAds.customerNames
          this.googleSelectedIds = response.data && response.data.client && response.data.client.googleAds.customerClientIDs
          this.dv360SelectedIds = response.data && response.data.client && response.data.client.dv360.advertiserIDs
          this.dv360SelectedNames = response.data && response.data.client && response.data.client.dv360.advertiserNames
          this.gmbAccountName = response.data.client.gmb.accountName;
          this.salesforceAccountName = response.data.client.salesforce.adAccountNames;
          this.leadSquaredDetailsObject = response.data.client.leadSquared;
          this.accountName = response.data.client.gmb.accountID;
          if (typeof this.gmbAccountName === "string") {
            this.gmbAccountName = this.gmbAccountName.split();
            this.accountName = this.accountName.split();
          }
          this.client = { ...response.data.client, status: true };
          this.setClientIdInCookie(this.client._id)
          store.commit("verticalMenu/UPDATE_CLIENT_DETAILS", {
            status: true,
            slug: response.data.client.slug,
            name: response.data.client.name,
            id: response.data.client._id,
          });
          this.breadcrumbs = [
            {
              text: "Overview",
              to: "/dashboard",
            },
          ];
          this.breadcrumbs.push({
            text: response.data.client.name,
            to: "/client",
            active: true,
          });
          this.checkFacebookAuthentication();
        })
        .catch((error) => {
          this.googleSelected = []
          this.googleSelectedIds = []
          this.client.status = false;
          this.errorMessage = error.response.data.error;
        });
    },
    accessResponseDataFromCookies() {
      // Get each key-value pair from cookies
      Object.keys(this.responseData).forEach(key => {
        this.responseData[key] = Cookies.get(key);
      });
    },
    checkFacebookAuthentication() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/facebook/checkFacebookAccessToken`,
          {
            clientID: this.client._id,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .catch((error) => {
          this.client.integrations.facebook = null;
        });
    },
    facebookConnect() {
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/facebook/login`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          const url = new URL(response.data);
          const search_params = url.searchParams;
          search_params.set("state", this.client._id);
          url.search = search_params.toString();
          const new_url = url.toString();
          window.location.replace(new_url);
        })
        .catch((error) => {
          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    fetchFacebookAdAccounts() {
      this.isFetchingAdsFacebook = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/facebook/get-ad-accounts`,
          {
            clientID: this.client._id,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isFetchingAdsFacebook = false;
          const accountsData = response.data.accountsData;
          this.facebookAccountsData = accountsData.data;
          this.toggleFacebookAdsAccountSelectorModal(true);
        })
        .catch((error) => {
          this.facebookAccountsData = [];
          this.toggleFacebookAdsAccountSelectorModal(false);
          this.isFetchingAdsFacebook = false;
          this.showToast(error.response.data.error);
          this.modalTypeCheck = ""
        });
    },
    fetchAmazonProfile() {
      this.isFetchingAmazonProfile = true;
      this.modalTypeCheck = "amazon"
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/amazon/profiles?clientID=${this.client._id}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isFetchingAmazonProfile = false;
          this.toggleFacebookModal(true);
          this.amazonProfileDataRes = response.data;
        })
        .catch((error) => {
          this.isFetchingAmazonProfile = false;
          console.log(error.response.data);
          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    fetchDv360Partner() { 
      this.isFetchingDv360 = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/dv-360/get-partners`,
          {
            clientID: this.client._id,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.dv360Accounts = response.data.partners
          this.isFetchingDv360 = false;
          this.toggle360SelectorModal(true);
        })
        .catch((error) => {
          this.showToast("Error", error.response.data.error, "danger");
          console.log(error.response.data);
          this.isFetchingDv360 = false;
        });
    },
    toggleFacebookAdsAccountSelectorModal(state) {
      this.facebookAdsAccountSelectorModal = state;
    },
    toggleFacebookModal(state) {
      if (state) {
        this.facebookSelected = [];
        this.facebookCustomers.filter((account) => {
          console.log(this.facebookAccountNames);
          if (this.facebookAccountNames.includes(account.name)) {
            this.facebookSelected.push(account);
          }
        });
      }
      this.facebookAdsModal = state;
    },
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
    // saveGoogleMetricDimensionData(data) {
    //   axios
    //     .put(
    //       `${process.env.VUE_APP_SERVER_URL}/api/metrics-dimension/google`,
    //       { ...data, clientID: this.client._id },
    //       {
    //         headers: {
    //           Authorization: `Bearer ${getUserToken()}`,
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       this.showToast("Saved", "Data saved successfully", "success");
    //     })
    //     .catch((error) => {
    //       console.log(error.response.data);
    //       if (error.response.data && error.response.data.error) {
    //         this.showToast("Error", error.response.data.error, "danger");
    //         return;
    //       }

    //       if (error.response.data && error.response.data.errors) {
    //         for (let i = 0; i < error.response.data.errors.length; i++) {
    //           const error = error.response.data.errors[i];
    //           this.showToast("Error", `${error.msg - error.param}`, "danger");
    //         }
    //         return;
    //       }

    //       this.showToast("Error", "Unknown error occured.", "danger");
    //     });
    // },
    googleAnalyticsConnect() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/integrations/integrate/google/init`,
          {
            clientID: this.client._id,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          window.location.replace(response.data);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data && error.response.data.error) {
            this.showToast("Error", error.response.data.error, "danger");
            return;
          }

          if (error.response.data && error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              const error = error.response.data.errors[i];
              this.showToast("Error", `${error.msg - error.param}`, "danger");
            }
            return;
          }

          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    amazonConnect() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/amazon/login`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          const url = new URL(response.data);
          const search_params = url.searchParams;
          search_params.set("state", this.client._id);
          url.search = search_params.toString();
          const new_url = url.toString();
          window.location.replace(new_url);
        })
        .catch((error) => {
          alert("error")
          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    shopifyConnect(){
      axios.get(
          `${process.env.VUE_APP_SERVER_URL}/api/shopify/shopify-login/?shop=${this.shopName}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        ).then((response) => {
          const url = new URL(response.data);
          const search_params = url.searchParams;
          // search_params.set("client_id", this.client._id);
          search_params.set("state", this.client._id);
          url.search = search_params.toString();
          const new_url = url.toString();
          window.location.replace(new_url);
        })
        .catch((error) => {
          alert("error")
          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    googleAdsConnect() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/integrations/integrate/google-ads/init`,
          {
            clientID: this.client._id,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          window.location.replace(response.data);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data && error.response.data.error) {
            this.showToast("Error", error.response.data.error, "danger");
            return;
          }

          if (error.response.data && error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              const error = error.response.data.errors[i];
              this.showToast("Error", `${error.msg - error.param}`, "danger");
            }
            return;
          }

          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    dv360Connect() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/integrations/integrate/dv-360/init`,
          {
            clientID: this.client._id,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          window.location.replace(response.data);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data && error.response.data.error) {
            this.showToast("Error", error.response.data.error, "danger");
            return;
          }

          if (error.response.data && error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              const error = error.response.data.errors[i];
              this.showToast("Error", `${error.msg - error.param}`, "danger");
            }
            return;
          }

          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    salesforceConnect() {
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/salesforce/login/?clientID=${this.client._id}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          window.location.replace(response.data);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data && error.response.data.error) {
            this.showToast("Error", error.response.data.error, "danger");
            return;
          }

          if (error.response.data && error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              const error = error.response.data.errors[i];
              this.showToast("Error", `${error.msg - error.param}`, "danger");
            }
            return;
          }

          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    gmbConnect() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/integrations/integrate/google-my-business/init`,
          {
            clientID: this.client._id,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          window.location.replace(response.data);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data && error.response.data.error) {
            this.showToast("Error", error.response.data.error, "danger");
            return;
          }

          if (error.response.data && error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              const error = error.response.data.errors[i];
              this.showToast("Error", `${error.msg - error.param}`, "danger");
            }
            return;
          }

          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    fetchAnalyticsAccounts() {
      this.isFetchingAccounts = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/analytics/get-accounts/google`,
          {
            clientID: this.client._id,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isFetchingAccounts = false;
          for (let i = 0; i < response.data.length; i++) {
            const account = response.data[i];
            const webProperties = account.webProperties;
            for (let j = 0; j < webProperties.length; j++) {
              const webProperty = webProperties[j];
              const profiles = webProperty.profiles;
              for (let k = 0; k < profiles.length; k++) {
                response.data[i].webProperties[j].profiles[k].loading = false;
              }
            }
          }
          this.googleAccounts = response.data;
          this.toggleAccountSelectorModal(true);
        })
        .catch((error) => {
          this.isFetchingAccounts = false;
          console.log(error.response.data);
        });
    },
    fetchSalesforceAccounts() {
      this.isFetchingSalesforceAccounts = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/salesforce/accounts/?clientID=${this.client._id}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.salesforceAccounts = response.data && response.data.records
          this.isFetchingSalesforceAccounts = false;
          this.toggleSalesforceModal(true);
        })
        .catch((error) => {
          this.isFetchingSalesforceAccounts = false;
          console.log(error.response.data);
        });
    },
    toggleSalesforceModal(state) {
      this.accountSalesforceSelectorModal = state;
    },
    toggleAccountSelectorModal(state) {
      this.accountSelectorModal = state;
    },
    removeAccountPrefix(accountString) {
      const prefixes = ['accounts/', 'properties/'];
  
  for (const prefix of prefixes) {
    if (accountString.startsWith(prefix)) {
      return accountString.slice(prefix.length);
    }
  }
  
  return accountString; // Return the original string if it doesn't start with the prefix
},
    saveGoogleViewAccount(profileDetails) {
      const {
        accountIndex,
        propertyIndex,
      } = profileDetails.buttonData;
      this.googleAccounts[accountIndex].ga4Properties[propertyIndex].loading = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/integrations/integrate/google/save-view-id`,
          {
            viewID: profileDetails.viewID,
            profileName: profileDetails.profileName,
            googlePropertyDisplayname:profileDetails.propertyName,
            googlePropertyID:this.removeAccountPrefix(profileDetails.ProperyID),
            clientID: this.client._id,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.client.googleViewID = profileDetails.viewID;
          this.client.googleViewProfile = profileDetails.profileName;
          this.client.analytics.google = response.data.data;
          this.googleAccounts[accountIndex].ga4Properties[propertyIndex].loading = false;
          this.showToast("Updated", "View ID updated successfully", "success");
          this.toggleAccountSelectorModal(false);
        })
        .catch((error) => {
          console.log(error);
          this.googleAccounts[accountIndex].ga4Properties[propertyIndex].loading = false;
          this.toggleAccountSelectorModal(false);
          if (error.response.data && error.response.data.error) {
            this.showToast("Error", error.response.data.error, "danger");
            return;
          }

          if (error.response.data && error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              const error = error.response.data.errors[i];
              this.showToast("Error", `${error.msg - error.param}`, "danger");
            }
            return;
          }

          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    saveSalesforceAccount(accountDetails) {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/salesforce/salesforce-accounts/select`,
          {
            adAccountIDs:accountDetails.accountID,
            adAccountNames:accountDetails.accountNames,
            clientID: this.client._id,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.salesforceAccountName =  response.data.accountName
          this.showToast("Updated", "Account Details updated successfully", "success");
          this.toggleSalesforceModal(false);
        })
        .catch((error) => {
          console.log(error);
          this.toggleSalesforceModal(false);
          if (error.response.data && error.response.data.error) {
            this.showToast("Error", error.response.data.error, "danger");
            return;
          }

          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    updateModal(value) {
      this.gmbAccountsModal = value;
    },
    updateFacebookModal(value) {
      this.facebookAdsModal = value;
    },
    fetchGoogleAdsCustomers() {
      this.isFetchingAdsCustomers = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/google-ads/get-customers`,
          {
            clientID: this.client._id,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isFetchingAdsCustomers = false;
          for (let i = 0; i < response.data.customers.length; i++) {
            const customer = response.data.customers[i];
            if (!customer.manager) {
              response.data.customers[i].loading = false;
            } else {
              if (customer.childAccounts) {
                for (let j = 0; j < customer.childAccounts.length; j++) {
                  response.data.customers[i].childAccounts[j].loading = false;
                }
              }
            }
          }
          this.googleAdsCustomers = response.data.customers;
          this.toggleAdsCustomerSelectorModal(true);
        })
        .catch((error) => {
          this.googleAdsError = true;
          this.showToast("Error", error.response.data.error, "danger");
          this.isFetchingAdsCustomers = false;
          console.log(error);
        });
    },
    fetchGMBCustomers() {
      this.isFetchingGMBAccounts = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/accounts`,
          {
            clientID: this.client._id,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isFetchingGMBAccounts = false;
          this.gmbAccounts = response.data.accounts;
          this.toggleGMBAccountModal(true);
        })
        .catch((error) => {
          this.isFetchingGMBAccounts = false;
          console.log(error);
        });
    },
    fetchFacebookCustomers(value, next, prev) {
      this.modalTypeCheck = "facebook"
      this.isFetchingFacebookAccountDatas = true;
      this.isFetchingFacebookAccounts = true;
      this.facebookCustomers = [];
      this.facebookCustomersAllTokens = null;

      let url = `${process.env.VUE_APP_SERVER_URL}/api/facebook/ad-accounts?clientID=${this.client._id}&limit=100&getAllData=true`

      if (value == true) {
        this.paginationToken = "";
      } else {
        this.paginationToken = value;
        if (next) {
          url += `&nextPageToken=${this.paginationToken}`
        } else if (prev) {
          url += `&prevPageToken=${this.paginationToken}`
        }
      }

      axios
        .get(
          url,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.paginationToken = "";
          this.isFetchingFacebookAccountDatas = false;
          this.isFetchingFacebookAccounts = false;
          this.facebookCustomers = response.data.accountsData.data;
          this.facebookCustomersAllTokens = response.data.accountsData.paging;
          this.toggleFacebookModal(true);
        })
        .catch((error) => {
          this.paginationToken = "";
          this.isFetchingFacebookAccounts = false;
          this.isFetchingFacebookAccountDatas = false;
          console.log(error);
        });
    },
    toggleAdsCustomerSelectorModal(state) {
      this.adsCustomerSelectorModal = state;
    },
    toggle360SelectorModal(state) {
      this.dv360Selector = state;
    },
    toggleGMBAccountModal(state) {
      if (state) {
        this.gmbSelected = [];
        this.gmbAccounts.filter((account) => {
          if (this.gmbAccountName.includes(account.accountName)) {
            this.gmbSelected.push(account);
          }
        });
      }

      this.gmbAccountsModal = state;
    },
    saveAdsCustomerID(customerData) {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/integrations/integrate/google-ads/save-customer-id`,
          {
            ...customerData,
            clientID: this.client._id,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.showToast(
            "Customer ID updated",
            "Customer ID updated successfully",
            "success"
          );
          this.toggleAdsCustomerSelectorModal(false);
          this.getClient();
        })
        .catch((error) => {
          console.log(error.response);

          // this.googleAccounts[accountIndex].webProperties[
          //   propertyIndex
          // ].profiles[profileIndex].loading = false;
          this.toggleAccountSelectorModal(false);
          if (error.response.data && error.response.data.error) {
            this.showToast("Error", error.response.data.error, "danger");
            return;
          }

          if (error.response.data && error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              const error = error.response.data.errors[i];
              this.showToast("Error", `${error.msg - error.param}`, "danger");
            }
            return;
          }

          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    saveDv360Data(customerData) {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/dv-360/save-advertisers-id`,
          {
            clientID: this.client._id,
            ...customerData, 
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.showToast(
            "Advertisers ID updated",
            "Advertisers ID updated successfully",
            "success"
          );
          this.getClient();
          this.toggle360SelectorModal(false);
        })
        .catch((error) => {
          console.log(error.response)
          this.toggle360SelectorModal(false);
          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    saveGMBAccountID(AccountData) {
      this.toggleGMBAccountModal(false);
      this.GMBloading = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/account/select`,
          {
            clientID: this.client._id,
            ...AccountData,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.getClient();
          this.GMBloading = false;
          this.showToast(
            "Account Selection successfully",
            "Account Selection successfully",
            "success"
          );
        })
        .catch((error) => {
          console.log(error.response);

          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    saveFacebookccountID(FacebookAccountData) {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/facebook/ad-accounts/select`,
          {
            clientID: this.client._id,
            ...FacebookAccountData,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.getClient();
          this.toggleFacebookModal(false);
          this.showToast(
            "Meta Account Selected successfully",
            "Meta Account Selected successfully",
            "success"
          );
        })
        .catch((error) => {
          console.log(error.response);

          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    saveAmazonProfileID(amazonProfileData) {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/amazon/profile/select`,
          {
            clientID: this.client._id,
            ...amazonProfileData,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.getClient();
          this.toggleFacebookModal(false);
          this.showToast(
            "Amazon Profile Selected successfully",
            "Amazon Profile Selected successfully",
            "success"
          );
        })
        .catch((error) => {
          console.log(error.response);

          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
  },
  mounted() {
    // this.setClientIdInCookie();
    history.pushState("", document.title, window.location.pathname);
    if (this.$route.query.next) {
      this.nextAction = this.$route.query.next;
    }
    this.getClient();
  },
  watch: {
    $route(to, from) {
      if (to.name === "client-details" && to.path != from.path) {
        this.getClient();
      }
    },
  },
};
</script>

<style>
.breadcrumb-container a {
  color: antiquewhite !important;
}

.share-button {
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
  background-color: transparent !important;
}
</style>
