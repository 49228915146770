<template>
   <div>
    <b-row>
       <div class="channelContainer-onbaord">
        <b-col cols="8"  class="channel-cards">
          <b-card variant="primary" class="text-center">
            <b-row>
              <b-col cols="12">
                <div v-if="googleAdsCustomerID" class="account-details">
                  <div class="d-flex align-items-center">
                    <div class="avatar-section">
                      <b-avatar square class="mb-1" variant="white" size="45" src="@/assets/images/logo/ads-logo.png">
                      </b-avatar>
                    </div>
                    <div class="account-data text-left ml-1">
                      <b-link>
                        <h3 class="app-title md">Google Ads</h3>
                      </b-link>
                      <p class="account-name text-style">
                        {{ googleAds.managerName }}
                      </p>
                      <p class="manager-account">
                      <div v-if="googleAds.manager">
                        <div v-if="googleAds.customerNames.length === 1">
                          <div>
                            <ol>
                              <li class="account-name text-style" v-for="(item, index) in googleAds.customerNames"
                                :key="index" style="width: 150px;">
                                {{ item }}
                              </li>
                            </ol>
                          </div>
                        </div>
                        <div v-else-if="showGoogleMore || googleAds.customerNames.length <= maxLength">
                          <div style="display: flex; justify-content:center">
                            <div>
                              <ol>
                                <li class="account-name text-style" v-for="(item, index) in googleAds.customerNames"
                                  :key="index" style="width: 150px;">
                                  {{ item }}
                                </li>
                              </ol>
                            </div>
                            <div style="width:120px">
                              <a href="#" v-if="googleAds.customerNames.length > 0"
                                @click="toggleGoogleShowMore">See
                                Less<feather-icon icon="ChevronsUpIcon" /></a>
                            </div>
  
                          </div>
                        </div>
                        <div v-else>
                          <div style="display: flex; justify-content:center">
                            <div>
                              <ol>
                                <li class="account-name text-style" v-for="(item, index) in truncatedGoogleData"
                                  :key="index" style="width: 130px;">
                                  {{ item }}
                                </li>
                              </ol>
                            </div>
                            <div>
                            </div>
                            <a href="#" @click="toggleGoogleShowMore" style="width:100px">See More<feather-icon
                                icon="ChevronsDownIcon" /></a>
                          </div>
                        </div>
                      </div>
                      <span v-else> NOT_MCC </span>
                      <p v-if="googleAdError" class="text-danger mt-1">
                        Please try again.
                      </p>
                      </p>
                    </div>
                  </div>
                  <div v-if="integrations.googleAds">
                    <!-- <b-button block :to="`/client/${slug}/dashboard/google-ads`"
                      v-b-tooltip.hover.top="'View Google Ads Data'" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary" class="mt-1">
                      Continue
                    </b-button> -->
  
                    <b-button :disabled="role === 'VIEWER'" :class="next === 'google-ads-customer-selection' ||
                      !googleAdsCustomerID
                      ? 'google-pulse-button'
                      : ''
                      " @click="selectGoogleAdsCustomers" variant="flat-dark-outline">
                      <b-spinner v-if="isFetchingAdsCustomers" small />
                      <span class="customize-text">
                        <span v-if="role === 'VIEWER'"><b-icon class="upDownIcon" icon="dash-circle" /></span> Select
                        Ads Account(s)</span></b-button>
                  </div>
                  <div v-else>
                    <b-button block @click="googleAdsConnect" v-if="!integrations.gmb"
                      variant="outline-primary">Connect</b-button>
                  </div>
                </div>
                <div v-else>
                  <b-avatar square class="mb-1" variant="white" size="45" src="@/assets/images/logo/ads-logo.png">
                  </b-avatar>
                  <div class="truncate">
                    <h3 class="mb-25 font-weight-bolder">Google Ads</h3>
                    <div class="mt-2">
                      <b-button block @click="googleAdsConnect('google-ads')" v-if="!integrations.googleAds"
                        variant="outline-primary">Connect</b-button>
                      <div v-if="!googleAdError">
                        <b-button :class="next === 'google-ads-customer-selection' ||
                          !googleAdsCustomerID
                          ? 'google-pulse-button'
                          : ''
                          " @click="selectGoogleAdsCustomers" v-if="integrations.googleAds" variant="primary">
                          <b-spinner v-if="isFetchingAdsCustomers" small />Select
                          Customer</b-button>
                      </div>
                      <div>
                        <b-button block @click="googleAdsConnect('google-ads')" v-if="googleAdError"
                          variant="outline-primary">Connect</b-button>
                        <p v-if="googleAdError" class="text-danger mt-1">
                          Please try again.
                        </p>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="8" class="channel-cards">
          <b-card variant="primary" class="text-center">
            <b-row>
              <b-col cols="12">
                <div v-if="facebookAccountName.length > 0" class="account-details">
                  <div>
                    <div class="d-flex align-items-center">
                      <div class="avatar-section">
                        <b-avatar square class="mb-1" variant="white" size="45" src="@/assets/images/logo/meta.png">
                        </b-avatar>
                      </div>
                      <div class="account-data text-left">
                        <b-link>
                          <h3 class="app-title md ml-2">Meta</h3>
                        </b-link>
                        <div v-if="facebookAccountName.length === 1">
                          <div>
                            <ol>
                              <li class="account-name text-style" v-for="(item, index) in facebookAccountName"
                                :key="index" style="width: 150px;">
                                {{ item }}
                              </li>
                            </ol>
                          </div>
                        </div>
                        <div v-else-if="showMetaMore || facebookAccountName.length <= maxLength">
                          <div style="display: flex; justify-content:center">
                            <div>
                              <ol>
                                <li class="account-name text-style" v-for="(item, index) in facebookAccountName"
                                  :key="index" style="width: 150px;">
                                  {{ item }}
                                </li>
                              </ol>
                            </div>
                            <div style="width:120px">
                              <a href="#" class="ml-3" @click="toggleMetaShowMore">See
                                Less<feather-icon icon="ChevronsUpIcon" /></a>
                            </div>
  
                          </div>
                        </div>
                        <div v-else>
                          <div style="display: flex; justify-content:center">
                            <div>
                              <ol>
                                <li class="account-name text-style" v-for="(item, index) in truncatedFacebookData"
                                  :key="index" style="width: 130px;">
                                  {{ item }}
                                </li>
                              </ol>
                            </div>
                            <div>
                            </div>
                            <a href="#" @click="toggleMetaShowMore" style="width:100px">See More<feather-icon
                                icon="ChevronsDownIcon" /></a>
                          </div>
                        </div>
                        <p class="manager-account"></p>
                      </div>
                    </div>
                    <div class="mt-2">
                      <div v-if="integrations.facebook">
                        <!-- <b-button block v-if="integrations.facebook" variant="primary"
                          :to="`/client/${slug}/dashboard/meta-ads`" v-b-tooltip.hover.top="'View Meta Ads Data'">
                          Continue</b-button> -->
                        <b-button :disabled="role === 'VIEWER'" @click="selectFacebookAdsAccount"
                        variant="primary">
                          <b-spinner v-if="isFetchingFacebookAccounts" small />
                          <span class="customize-text"> <span v-if="role === 'VIEWER'"><b-icon class="upDownIcon"
                                icon="dash-circle" /></span>Select Ads Account(s)</span></b-button>
                      </div>
                      <div v-else>
                        <b-button block @click="facebookConnect" v-if="!integrations.amazon"
                          variant="outline-primary">Connect</b-button>
                      </div>
  
                    </div>
                  </div>
                </div>
                <div v-else>
                  <b-avatar square class="mb-1" variant="white" size="45" src="@/assets/images/logo/meta.png">
                  </b-avatar>
                  <div class="truncate">
                    <b-link>
                      <h3 class="app-title md">Meta Ads</h3>
                    </b-link>
                    <div style="font-size: 10px;" class=" text-style" v-for="(item, index) in facebookAccountName"
                      :key="index">
                      <p style="font-size: 12px; text-align:center">{{ item }}</p>
                    </div>
                    <div class="mt-2">
                      <b-button block @click="facebookConnect" v-if="!integrations.facebook"
                        variant="outline-primary">Connect</b-button>
                      <b-button v-if="integrations.facebook" :disabled="role === 'VIEWER'"
                        @click="selectFacebookAdsAccount" variant="primary"
                          >
                        <b-spinner v-if="isFetchingFacebookAccounts" small />
                        <span v-if="integrations.facebook">Select Ads Account(s)</span></b-button>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </div>
    </b-row>
   </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { BCard, BCol, BRow, BAvatar, BButton, BLink ,BSpinner,  VBPopover,  VBTooltip,} from "bootstrap-vue";
export default {
  data() {
    return {
      showGoogleMore: false,
      showMetaMore: false,
      maxLength:2,
    }
  },
  components: {
    BCard, BCol, BRow, BAvatar, BButton, BLink,
    BSpinner, VBPopover
  },
  directives: {
    "b-popover": VBPopover,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    integrations: {
      type: Object,
      default: () => {
        return {
          google: null,
          facebook: null,
          googleAds: null,
        };
      },
    },
    isFetchingFacebookAccounts: {
      type: Boolean,
    },
    facebookAccountName: {
      type: Array,
    },
    channelName: {
      type: String
    },
    googleAdsCustomerID: {
      type: String,
    },
    isFetchingAdsCustomers: {
      type: Boolean,
    },
    googleAdError: {
      type: Boolean,
    },
    slug: {
      type: String,
      default: "",
    },
    next: {
      type: String,
      default: "",
    },
    role: {
      type: String,
      required: true,
      default: "VIEWER",
    },
    facebookAdsModal: {
      type: Boolean,
      default: true,
    },
    googleAds: {
      type: Object,
      default: () => {
        return {
          googleAdsCustomerID: null,
          manager: false,
          customerClientID: null,
          managerName: null,
          customerName: null,
          customerNames: null,
        };
      },
    },
  },
  methods: {
    googleAdsConnect() {
      this.$emit("googleAdsConnect", true);
    },
    selectGoogleAdsCustomers() {
      this.$emit("selectGoogleAdsCustomers", true);
    },
    facebookConnect() {
      this.$emit("facebookConnect", true);
    },
    selectFacebookAdsAccount() {
      this.$emit("selectFacebookAdsAccount", true);
    },
    fetchFacebookAdAccounts() {
      this.$emit("fetchFacebookAdAccounts", true);
    },
    toggleMetaShowMore() {
      this.showMetaMore = !this.showMetaMore;
    },
    toggleGoogleShowMore() {
      this.showGoogleMore = !this.showGoogleMore;
    },
  },
  computed: {
    truncatedFacebookData() {
      if (this.facebookAccountName.length > this.maxLength) {
        return this.facebookAccountName.slice(0, this.maxLength);
      }
      return this.facebookAccountName;
    },
    truncatedGoogleData() {
      if (this.googleAds.customerNames.length > this.maxLength) {
        return this.googleAds.customerNames.slice(0, this.maxLength);
      }
      return this.googleAds.customerNames;
    },
  },
  // mounted(){

  // }
}


</script>

<style>
.channel-cards{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; border-radius:10px
}
.channelContainer-onbaord{
  display: flex; gap:10px;justify-content:space-around;align-items: center;
}
/* Mobile Devices (<= 576px) */
@media (max-width: 576px) {
  .channelContainer-onbaord{
    display: grid;
    grid-template-columns: auto;

  }
  .channel-cards{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; border-radius:10px;
    width: 500px;
  }
}

/* Tablet Devices (577px - 992px) */
@media (min-width: 577px) and (max-width: 992px) {
  .channelContainer-onbaord{
    display: grid;
    grid-template-columns: auto;}
    .channel-cards{
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; border-radius:10px;
      width: 500px;
    }
}

/* Laptop Devices (993px - 1200px) */
@media (min-width: 993px) and (max-width: 1200px) {
  .channelContainer-onbaord{
    display: grid;
    grid-template-columns: auto;}
    .channel-cards{
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; border-radius:10px;
      width: 500px;
    }
}
</style>