<template>
    <div>
      <b-modal
        @hidden="toggleSalesforceModal"
        @ok="toggleSalesforceModal"
        v-model="internalModalVisible"
        ok-only
        no-close-on-backdrop
        ok-title="CLOSE"
        centered
        size="lg"
        title="Select Salesforce Account"
      >
      <b-card
      class="innerContainer"
        v-for="(account, accountIndex) in this.salesforceAccounts"
        :key="account.id"
      >
        <div style="display: flex; justify-content:space-between">
          <div class="pr-1 pt-0 edit-form">
            <h3 class="app-title sm">
               {{ account.Name }}
            </h3>
            <span>{{ account.Id }}</span>
        </div>
        <div>
          <b-button
            @click="
            selectSalesforceViewAccount(account.Id,account.Name)
            "
            variant="primary"
            >
            <b-spinner v-if="account.loading" small />
             Select Account</b-button
          >
        </div>
        </div>
      </b-card>
      </b-modal>
    </div>
  </template>
  
  <script>
  import { BModal, BCardText, BCard, BButton, BSpinner } from "bootstrap-vue";
  import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
  import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
  export default {
    props: {
      accountSalesforceModal: {
        type: Boolean,
        default: false,
      },
      salesforceAccounts: {
        type: Array,
        default: [],
      },
    },
    data() {
      return {
        internalModalVisible : this.accountSalesforceModal
      };
    },
    watch: {
      accountSalesforceModal(value) {
        this.internalModalVisible = value;
      },
    },
    components: {
      BModal,
      AppCollapse,
      AppCollapseItem,
      BCardText,
      BCard,
      BButton,
      BSpinner,
    },
    methods: {
      toggleSalesforceModal() {
        this.$emit("toggleSalesforceModal", false);
      },
      selectSalesforceViewAccount(accountID, accountNames) {
        this.$emit("selectSalesforceViewAccount", {
          accountID,
          accountNames,
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .mainContainer{
    width: 760px;
  }
  .innerContainer{
    width: 730px;
  }
  @media (max-width: 587px) {
    .mainContainer{
      width: 300px !important;
    }
    .innerContainer{
      width: 270px !important;
  }
  }
  @media (max-width: 890px) {
    .mainContainer{
      width: 465px;
    }
    .innerContainer{
      width: 438px;
  }
    
  }
  </style>