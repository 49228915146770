<template>
  <div>
    <div
      v-if="isClientLoadingProperly"
      class="d-flex justify-content-center align-items-center"
      style="min-height: 60vh;"
    >
     <Spinner />
    </div>
    <div v-else>
      <template>
        <b-row class="content-header">
          <b-col class="content-header-left mb-2" cols="12" md="10">
            <b-row class="breadcrumbs-top">
              <b-col cols="10">
                <h2 class="content-header-title text-white float-left pr-1 mb-0">
                  Overview 
                </h2>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="responsiveness">
          <b-col :cols="this.isExternalUser === 'true' && this.isNewUser === 'false' ? '4' : '6'">
            <b-card class="custom-action-card search-card">
              <b-row class="">
                <b-col :cols="DynamicCols">
                    <div style="display: flex !important;" >
                      <b-input-group-prepend>
                      <b-input-group-text class="search-icon" >
                        <feather-icon
                          class="cursor-pointer"
                          icon="SearchIcon"
                          color="black"
                          size="16"
                        />
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input v-model="searchTerm" class="search-form"/>
                    <b-button
                      class="searc-button"
                      variant="dark"
                      @click="searchClient"
                    >
                      Search
                    </b-button>
                    </div>
                   
                    <!-- <b-input-group-append>
                    <b-button class="searc-button" variant="dark"  @click="searchClient">
                    Search
                  </b-button>
                  </b-input-group-append> -->
                 
                </b-col>
                <b-col v-if="(this.isNewUser == 'new' && this.isExternalUser == 'true' || this.isNewUser == 'false' && this.isExternalUser == 'false') || this.clients.length === 0" cols="5">
                  <b-button
                    @click="addNewClientInit"
                    variant="primary"
                    class="w-100 input-button"
                    ><feather-icon icon="PlusIcon"></feather-icon
                    ><span style="font-size: 12px;">
                      Add New Client</span
                    ></b-button
                  >
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="6" class="paginated-card">
            <div style="justify-content: flex-end !important;" class="d-flex align-items-center">
              <b-pagination
                class="mb-0 mt-1 dashboard-paginations"
                @change="paginate"
                v-model="pagination.currentPage"
                :total-rows="totalClients"
                :per-page="pagination.limit"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-col>
        </b-row>
        <b-tabs class="dashboard-tabs">
          <b-tab active title="Active Clients">
            <b-row class="match-height responsiveMode">
              <b-col v-for="client in clients" :key="client._id" cols="3">
                <ClientCard
                  class="client-card"
                  :client="client"
                  @editClient="editClient"
                  @deleteClient="deleteClientInit"
                  @accessControl="initAccessControl"
                />
              </b-col>
              <b-col cols="3" v-if="
                this.isNewUser == 'false' && this.isExternalUser == 'false'">
                <div v-if="this.clients.length === 0" >
                  <h6 style="color:white"> No Client Found!</h6>
                </div>
                <AddNewClientCard
                  class="add-new-client-card"
                  @add-new="addNewClientInit"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <div
                  class="d-flex justify-content-start align-items-center text-white"
                >
                  <span
                    >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                    {{ dataMeta.of }} entries</span
                  >
                </div>
              </b-col>
              <b-col cols="6">
                <div style="justify-content: flex-end !important;" class="d-flex h-100 align-items-center">
                  <b-pagination
                    class="dashboard-paginations"
                    @change="paginate"
                    v-model="pagination.currentPage"
                    :total-rows="totalClients"
                    :per-page="pagination.limit"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </b-col>
            </b-row>
          </b-tab>
          <!-- <b-tab title="Inactive Clients"> </b-tab> -->
        </b-tabs>
       
        <EditClient
          :method="fetchData"
          :editClient="editClientScreen"
          :client="editingClient"
          @closeClientPopUp="closeClientPopUp"
          :action="clientPopUpAction"
          @edit-client="triggerEditClient"
          :isBusy="isBusy"
        />
  
        <DeleteConfirmation
          @closePopUp="closeDeleteConfirmationPopUp"
          :isBusy="isBusy"
          :show="deleteConfirmationPopUp"
          :deleteClientDetails="deleteClientDetails"
          @confirmDelete="triggerDeleteClient"
        />
  
        <AccessControlPopUp
          @closePopUp="closeAccessControlPopUp"
          :client="shareAccessClientDetails"
          :isProcessingAccess="isProcessingAccess"
          :isProcessingRevokeAccess="isProcessingRevokeAccess"
          :show="shareAccessPopUp"
          @send-invite="sendInvite"
          @remove-access="removeAccess"
        />
      </template>
    </div>
    <!-- <D2ScaleGPT /> -->
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import { getUserToken } from "@/auth/utils";
import AdsCustomerSelectorModalV2 from  '../views/Client/AdsCustomerSelectorV2.vue'
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BCardBody,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BButton,
  BTabs,
  BTab,
  BPagination,
  BInputGroupPrepend,
  BInputGroupText,
  BOverlay,
  BSpinner,
  BFormSelect,
  BFormGroup,
  BIcon
} from "bootstrap-vue";
import vSelect from "vue-select";
import D2ScaleGPT from './D2ScaleGPT'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import ClientCard from "@/components/client/ClientCard.vue";
import AddNewClientCard from "@/components/client/AddNewClientCard.vue";
import EditClient from "@/components/client/EditClient.vue";
import DeleteConfirmation from "@/components/client/DeleteConfirmation.vue";
import AccessControlPopUp from "@/components/client/AccessControlPopUp.vue";
import Spinner from "@/components/client/Spinner.vue";
import Cookies from 'js-cookie';
import Channels from '../views/UserUIScreens/Channels.vue'
export default {
  data() {
    return {
      todayDateCompare: new Date().toISOString().split('T')[0],
      isClientLoadingProperly: false,
      clients: [],
      totalClients: "",
      pagination: {
        limit: 7,
        currentPage: 1
      },
      editClientScreen: false,
      editingClient: {
        name: "",
        sector: null,
        selectedBusinessType: []
      },
      clientPopUpAction: "add-new",
      isBusy: false,
      deleteConfirmationPopUp: false,
      deleteClientDetails: {
        name: "",
        clientID: ""
      },
      shareAccessPopUp: false,
      shareAccessClientDetails: {
        name: "",
        clientID: "",
        accessList: []
      },
      isProcessingAccess: false,
      isProcessingRevokeAccess: false,
      searchTerm: "",
      isNewUser:null,
      isExternalUser:null
    };
  },
  computed: {
    DynamicCols(){
      if (this.isNewUser === 'true' && this.isExternalUser === 'true' || this.clients.length === 0) {
          return 6
      }
      else if (this.isNewUser === 'false' && this.isExternalUser === 'false') {
          return 7
      }
      else{
        return 12
      }
    },
    isFormValid() {
      return this.form.companyName && this.form.industry && this.form.language;
    },
    dataMeta() {
      const localItemsCount = this.clients.length;
      return {
        from:
          this.pagination.limit * (this.pagination.currentPage - 1) +
          (localItemsCount ? 1 : 0),
        to:
          this.pagination.limit * (this.pagination.currentPage - 1) +
          localItemsCount,
        of: this.totalClients
      };
    }
  },
  components: {
    Channels,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BCardBody,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormInput,
    BButton,
    BTabs,
    BTab,
    ClientCard,
    AddNewClientCard,
    EditClient,
    ToastificationContent,
    BPagination,
    DeleteConfirmation,
    AccessControlPopUp,
    BInputGroupPrepend,
    BInputGroupText,
    BOverlay,
    BSpinner,
    Spinner,
    D2ScaleGPT,
    vSelect,
    BFormGroup,
    BIcon,
    AdsCustomerSelectorModalV2
  },
  methods: {
    addActivityLog() {
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/api/logs/`, {
              "description": "Dashboard Overview Opened",
              "action": "view"
          }, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`
          }
        })
        .then(response => {
        })
        .catch(error => {
        });
    },
    fetchData() {
      this.fetchClients();
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant
        }
      });
    },
    fetchClients(search) {
      this.isClientLoadingProperly = true;
      const storedDate = Cookies.get('lastVisitedDashboardDate');
      if (storedDate === this.todayDateCompare) {
        console.log("");
      } else {
        Cookies.set('lastVisitedDashboardDate', this.todayDateCompare, { expires: 1 }); 
        this.addActivityLog();
      }
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/api/client/get`, search, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`
          }
        })
        .then(response => {
          this.clients = response.data.clients;
          if (this.clients.length === 0 && this.isNewUser === 'true' && this.isExternalUser === 'true') {
                this.$router.push("/new-user-onbaord");
          }
          this.totalClients = response.data.total;
          this.isBusy = false;
          this.isClientLoadingProperly = false;
        })
        .catch(error => {
          console.log(error.response.data);
          this.isClientLoadingProperly = false;
        });
    },
    editClient(client) {
      this.editClientScreen = true;
      this.editingClient = client;
      this.clientPopUpAction = "edit-client";
    },
    closeClientPopUp() {
      this.editClientScreen = false;
      this.isBusy = false;
      this.editingClient = {
        name: "",
        sector: null,
        selectedBusinessType: []
      };
      this.clientPopUpAction = "";
    },
    displayError(errorData) {
      if (errorData && errorData.error) {
        this.showToast("Error", errorData.error, "danger");
        return;
      }

      if (errorData && errorData.errors) {
        for (let i = 0; i < errorData.errors.length; i++) {
          const error = errorData.errors[i];
          this.showToast("Error", error.msg, "danger");
        }
        return;
      }

      this.showToast("Error", "Unknown error occured.", "danger");
    },
    triggerEditClient(client) {
      this.isBusy = true;
      let clientIndex = this.clients.findIndex(clientObj => {
        return (clientObj._id = client.clientID);
      });
      if (clientIndex > -1) {
        axios
          .post(`${process.env.VUE_APP_SERVER_URL}/api/client/update`, client, {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          })
          .then(response => {
            this.clients[clientIndex].name = client.name;
            this.clients[clientIndex].sector = client.sector;
            this.showToast(
              "Updated",
              "Client details has been updated successfully!",
              "success"
            );
            this.isBusy = false;
            this.editClientScreen = false;
          })
          .catch(error => {
            this.displayError(error.response.data);
            this.isBusy = false;
          });
      } else {
        alert("Invalid client selected!");
      }
    },
    addNewClientInit() {
      if (this.isNewUser === 'true' && isExternalUser === 'true' || this.clients.length === 0) {
        this.$router.push(`/new-user-onbaord`);
      }else{
        this.editClientScreen = true;
        this.clientPopUpAction = "add-new";
      }
    },
    paginate(currentPage) {
      this.pagination.currentPage = currentPage;
      this.fetchClients({
        limit: this.pagination.limit,
        currentPage: currentPage,
        s: this.searchTerm
      });
    },
    closeDeleteConfirmationPopUp() {
      this.deleteConfirmationPopUp = false;
      this.deleteClientDetails = {
        name: "",
        clientID: ""
      };
    },
    deleteClientInit(client) {
      this.deleteConfirmationPopUp = true;
      this.deleteClientDetails = {
        clientID: client.clientID,
        name: client.name
      };

    },
    triggerDeleteClient(client) {
      if (this.clients.length <= 1) {
        this.$router.push("/new-user-onbaord");
      }
      this.isBusy = true;
      let clientIndex = this.clients.findIndex(clientObj => {
        return (clientObj._id = client.clientID);
      });

      if (clientIndex > -1) {
        axios
          .post(`${process.env.VUE_APP_SERVER_URL}/api/client/delete`, client, {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          })
          .then(response => {
            this.fetchClients();
            this.isBusy = false;
            this.deleteConfirmationPopUp = false;
            store.commit("verticalMenu/UPDATE_CLIENT_DETAILS_ON_DELETE", {
              status: true,
              slug: this.clients[clientIndex].slug,
              name: this.clients[clientIndex].name,
              id: this.clients[clientIndex]._id
            });
            this.fetchClients({
              limit: this.pagination.limit,
              currentPage: this.pagination.currentPage
            });
          })
          .catch(error => {
            this.displayError(error.response.data);
            this.isBusy = false;
          });
      } else {
        alert("Invalid client selected!");
      }
    },
    async initAccessControl(client) {
      try {
        this.shareAccessClientDetails = client;
        let accessList = await this.getAccessList(client.clientID);
        this.shareAccessClientDetails.accessList = accessList;
        this.shareAccessPopUp = true;
      } catch (error) {
        this.showToast("Error", "Unknown error occured!", "danger");
      }
    },
    closeAccessControlPopUp() {
      this.shareAccessPopUp = false;
      this.shareAccessClientDetails = {
        name: "",
        clientID: ""
      };
    },
    getAccessList(clientID) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_SERVER_URL}/api/client/get-access-list`,
            { clientID },
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`
              }
            }
          )
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    sendInvite(accessListData) {
      this.isProcessingAccess = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/add-access`,
          {
            accessList: accessListData.accessList,
            clientID: accessListData.clientID
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(async response => {
          let newAccessList = await this.getAccessList(accessListData.clientID);
          this.shareAccessClientDetails.accessList = newAccessList;
          this.isProcessingAccess = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    nextStep() {
      if (this.currentStep < this.steps.length - 1) {
        this.currentStep += 1;
      }
    },
    prevStep() {
      if (this.currentStep > 0) {
        this.currentStep -= 1;
      }
    },
    getToken() {
      return Cookies.get(useJwt.jwtConfig.storageTokenKeyName);
    },
    removeAccess(data) {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/remove-access`,
          data,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(async response => {
          this.isProcessingRevokeAccess = false;
          window.location.reload();
        })
        .catch(error => {
          console.log(error);
        });
    },
    searchClient() {
      this.fetchClients({
        s: this.searchTerm
      });
    }
  },
  mounted() {
    this.isNewUser = Cookies.get(useJwt.jwtConfig.userNewUser);
    this.isExternalUser  = Cookies.get(useJwt.jwtConfig.userExternalUser);
    this.fetchClients(this.pagination);
  }
};
</script>

<style lang="scss">
.dashboard-overlay .rounded-sm {
  inset: -20px !important;
  height: 80vh !important;
}

.addclientButton {
  background-color: #6c4e96 !important;
  border-radius: 12px !important;
  border: none !important;
}

.search-card {
  border-radius: 15px;
}

.search-form {
  background-color: #e4dfeb !important;
  border: none;
  margin-right: -10px !important;
  margin-left: -10px !important;
}

.searc-button {
  background-color: black !important;
  color: white !important;
  font-weight: bold;
  border-radius: 12px;
}

.search-icon {
  background-color: #e4dfeb !important;
  border-radius: 12px 0 0 12px !important;
  border: none;
  cursor: none;
}

@media only screen and (max-width: 767px) {
  .responsiveMode {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 20px;
  }

  .client-card {
    width: 350px !important;
  }

  .search-card {
    width: 400px;
  }

  .input-group {
    width: 220px !important;
  }

  .input-button {
    width: 150px !important;
    height: 40px !important;
    font-size: 10px !important;
    padding: 2px !important;
  }

  .responsiveness {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 20px;
  }

  .paginated-card {
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-bottom: 10px;
  }

  .add-new-client-card {
    width: 350px !important;
  }
}

.onboard{
   display: flex;
  .container {
    display: flex;
    width: 100%;
    font-family: Arial, sans-serif;
  }
  
  /* Sidebar */
  .sidebar {
    width: 35%;
    height: 400px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #f7f7f7;
    padding: 20px;
    margin-top: 40px;
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .progress-circle {
    width: 80px;
    height: 80px;
    border: 5px solid #2cc474;
    border-radius: 50%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
  }
  
  .steps {
    list-style: none;
    padding: 0;
    margin-top: 20px;
  }
  
  .step {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 14px;
    color: #555;
  }
  
  .check-mark {
    margin-right: 10px;
    color: #aaa;
  }
  
  /* Form Section */
  .form-section {
    width: 75%;
    padding: 30px 50px;
    box-sizing: border-box;
  }
  
  h2 {
    margin: 0 0 10px;
    color: #333;
  }
  
  .instruction-text {
    color: #777;
    margin-bottom: 30px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-weight: bold;
  }
  
  label span {
    color: red;
  }
  
  .submit-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}
.steps .step {
  display: flex;
  align-items: center;
  margin: 10px 0;
  color: #333; /* Default color for incomplete steps */
}

.steps .step.completed {
  color: #2cc474; /* Green color for completed steps */
  font-weight: bold; /* Optional: make completed steps bold */
}

.steps .step .check-mark {
  margin-right: 5px;
  color: #2cc474; /* Ensure the check mark is also green */
}
</style>
