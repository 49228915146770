<template>
  <div class="obboard-create-account">
    <div class="auth-onboard-manager">
      <div class="auth-onboard-manager-main">
        <div class="manager">
          <header class="header">
            <h3 class="title">Central Dashboard for all your Digital Campaigns</h3>
            <!-- <h2 class="subtitle" style="color: white!important;">Platform for your campaigns</h2> -->
            <p class="description" style="color: white!important;">
              Link your Google Ads, Meta Ads account and get a single view of what campaigns are working best.
            </p>
          </header>
          <div class="image-section">
            <img src="@/assets/images/logo/onboardUI.png" class="onboard-image" />
          </div>
          <footer class="footer">
            <p class="footer-text" style="color: white!important;">
              Trusted by 200+ Leading brands and they love us!
            </p>
            <div class="brands">
              <img
                style="width: 120px;"
                src="@/assets/images/logo/gocolors.webp"
              />
              <img style="width: 80px;" src="@/assets/images/logo/cfs.webp" />
              <img style="width: 120px;" src="@/assets/images/logo/fr.png" />
              <img style="width: 120px;" src="@/assets/images/logo/kl.png" />
              <img style="width: 120px;" src="@/assets/images/logo/tvs.png" />
            </div>
          </footer>
        </div>
      </div>
      <div class="auth-login-section">
        <div class="logo">
          <img
            style="width: 150px;"
            src="@/assets/images/logo/black-logo-2024.png"
            alt="Logo"
          />
        </div>
        <h3 class="welcome-text">Hi, Welcome</h3>
        <p class="subtitle">Enter your credentials to create your account</p>
        <div v-if="!isSubmitSuccessFully">
          <div class="text-center mb-1"></div>
          <form @submit.prevent="handleSubmit">
            <div style="display: flex; gap:5px">
              <div class="form-group">
                <label for="name" class="form-label">First Name</label>
                <input
                  type="text"
                  id="name"
                  class="form-control form-control-lg"
                  v-model="form.firstname"
                  placeholder="First Name"
                  required
                />
              </div>
              <div class="form-group">
                <label for="name" class="form-label">Last Name</label>
                <input
                  type="text"
                  id="name"
                  class="form-control form-control-lg"
                  v-model="form.lastname"
                  placeholder="Last Name"
                  required
                />
              </div>
            </div>
  
            <div class="form-group">
              <label for="email" class="form-label">Email</label>
              <div>
                <input
                  type="email"
                  id="email"
                  class="form-control form-control-lg"
                  v-model="form.email"
                  placeholder="name@company.com"
                  required
                  @input="validateEmail"
                />
                <div v-if="emailError" class="text-danger-number">
                  {{ emailError }}
                </div>
              </div>
            </div>
  
            <div class="form-group">
              <label for="phone" class="form-label">Phone Number</label>
              <div
                class="input-group"
                style="display: flex; align-items: center; border: 1px solid black; border-color: #d8d6de; border-radius: 5px;"
              >
                <div
                  class="form-select text-center form-select-lg"
                  style="display: flex; align-items: center; justify-content: center; border-right: 1px solid #d8d6de; padding: 0 8px; border-radius: 5px 0 0 5px;color: #A6AEBF"
                >
                  <img
                    src="@/assets/images/logo/India_Flag.png"
                    alt="login-page-logo"
                    style="height: 24px; margin-right: 5px;"
                  />
                  +91
                </div>
                <input
                  type="tel"
                  id="phone"
                  class="form-control form-control-lg"
                  v-model="form.phone"
                  placeholder="0000000000"
                  required
                  pattern="[0-9]{10}"
                  @input="validatePhone"
                  style="border: none; flex: 1; padding: 10px; font-size: 16px; height: 42px;"
                />
              </div>
  
              <div v-if="phoneError" class="text-danger-number">
                {{ phoneError }}
              </div>
            </div>
  
            <button
              type="submit"
              :disabled="!isFormFilledProperly"
              style="background:#903095; color:white"
              class="btn btn-lg w-100"
            >
             Free Sign Up
            </button>
          </form>
          <div class="text-center mt-1">
            <small class="text-muted">
              Already have an account?
            </small>
            <b-link style="color: #903095;" @click="LoginUpRediect">
              Sign in
            </b-link>
          </div>
        </div>
        <div v-if="isSubmitSuccessFully" class="email-verification__container">
          <div class="email-verification__card">
            <!-- Icon -->
            <div class="email-verification__icon-wrapper">
              <img
                src="@/assets/images/logo/submitSuccessfully.png"
                alt="Email Icon"
                class="email-verification__icon"
              />
            </div>
            <!-- Title -->
            <h1 class="email-verification__title">Verify your email address</h1>
            <!-- Message -->
            <p class="email-verification__message">
              A verification email has been sent to your email
              <span class="email-verification__highlight">{{
                this.form.email
              }}</span
              >.
            </p>
            <!-- <p class="email-verification__instructions">
                Please check your email and click the link provided in the email to complete your account registration.
              </p> -->
            <!-- Additional Info -->
            <p class="email-verification__info">
              Please check your email and click the link provided in the email to
              complete your account registration.
            </p>
            <!-- Resend Button -->
            <button class="email-verification__button" @click="LoginUpRediect">
              Back to Sign in
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { email } from "vee-validate/dist/rules";

export default {
  data() {
    return {
      isSubmitSuccessFully: false,
      phoneError: "",
      emailError: "",
      form: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
      },
    };
  },
  computed: {
    isFormFilledProperly() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phoneRegex = /^[0-9]{10}$/;

      return (
        this.form.firstname.length > 0 &&
        this.form.lastname.length > 0 &&
        emailRegex.test(this.form.email) &&
        phoneRegex.test(this.form.phone)
      );
    },
  },
  methods: {
    LoginUpRediect() {
      this.$router.push("/login");
    },
    validatePhone() {
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(this.form.phone)) {
        this.phoneError = "Phone number must be exactly 10 digits.";
      } else {
        this.phoneError = "";
      }
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.form.email)) {
        this.emailError = "Please enter a valid email address.";
      } else {
        this.emailError = "";
      }
    },
    SignUp() {
      this.isSubmitSuccessFully = false;
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/api/admin/register`, {
          email: this.form.email,
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          mobile: this.form.phone,
        })
        .then((response) => {
          this.isSubmitSuccessFully = true;
          this.showToast("Success", response.data.message, "success");
        })
        .catch((error) => {
          let errorMessage = error.response.data.error;
          this.showToast("Error", errorMessage, "danger");
          this.isSubmitSuccessFully = false;
        });
    },
    handleSubmit() {
      this.SignUp();
    },
  },
  components: {
    BLink,
    ToastificationContent,
  },
  name: "LoginScreen",
};
</script>


<style lang="scss">
.onboard-image{
  width: 280px;
}
.obboard-create-account{
  /* Container Styles */
.auth-onboard-manager {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.auth-onboard-manager-main {
  width: 60%;
  background-color: #704c94;
  padding: 70px;

  background-position: center;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  left: 0px;
  right: 0px;

  .manager {
    text-align: center;
    padding: 20px;
  }

  /* Header Styles */
  .header {
    margin-bottom: 30px;
  }

  .title {
    font-size: 2.5rem;
    color: #ffffff;
  }

  .subtitle {
    font-size: 1.8rem;
    margin: 10px 0;
  }

  .description {
    font-size: 1rem;
    margin: 15px auto;
    max-width: 600px;
  }

  /* Image Section */
  .image-section {
    margin: 30px 0;
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
  }

  .circle-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
  }

  /* Footer Styles */
  .footer {
    margin-top: 20px;
  }

  .footer-text {
    font-size: 1rem;
    margin-bottom: 15px;
  }

  .brands {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .brand-logo {
    max-width: 100px;
  }
}

.welcome-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.welcome-content h1 {
  font-size: 48px;
  margin-bottom: 10px;
}

.welcome-content p {
  font-size: 24px;
}

.auth-login-section {
  width: 40%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.login-box {
  width: 100%;
}

.login-header {
  text-align: center;
  margin-bottom: 20px;
}

.login-header h2 {
  font-size: 24px;
}

.login-form .form-group {
  margin-bottom: 15px;
}

.login-form .form-group label {
  display: block;
  margin-bottom: 5px;
}

.login-form .form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.btn-login {
  width: 100%;
  padding: 10px;
  background-color: #935097;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-login:hover {
  background-color: #903095;
}

.additional-options {
  margin-top: 20px;
  text-align: center;
}

.additional-options a {
  display: block;
  margin: 10px 0;
  text-decoration: none;
  color: #007bff;
}

.additional-options a:hover {
  text-decoration: underline;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ccc;
}

.divider:not(:empty)::before {
  margin-right: 0.25em;
}

.divider:not(:empty)::after {
  margin-left: 0.25em;
}

.social-login-options {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.social-icon {
  font-size: 24px;
  cursor: pointer;
}

.social-icon:hover {
  color: #fff;
}

/* Media Query */
@media (max-width: 800px) {
  .auth-onboard-manager {
    width: 100%;
    height: 200vh;
    display: flex;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .auth-onboard-manager {
    flex-direction: column;
    height: auto;
  }

  .auth-onboard-manager-main,
  .auth-login-section {
    width: 100%;
  }

  .auth-onboard-manager-main {
    height: 200px;
  }

  .welcome-content {
    position: relative;
    transform: none;
    padding: 20px;
    top: 30px;
  }
}

.login-manager {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
}

.login-box {
  width: 100%;
  background: #ffffff;
  text-align: center;
}

.logo img {
  width: 80px;
  margin-bottom: 20px;
}

.welcome-text {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333333;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 0.95rem;
  color: #666666;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
  position: relative;
}

.sign-in-input {
  width: 100%;
  padding: 15px 15px;
  font-size: 0.95rem;
  border: 1px solid #cccccc;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s;
}

.sign-in-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.3);
}

.password-group {
  display: flex;
  align-items: center;
}

.password-toggle {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #666666;
}

.password-toggle i {
  font-size: 1rem;
}

.forgot-password {
  text-align: right;
  margin-bottom: 20px;
}

.forgot-password a {
  color: #007bff;
  font-size: 0.9rem;
  text-decoration: none;
}

.btn-primary {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #ffffff;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 15px;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.divider {
  font-size: 0.9rem;
  color: #666666;
  margin: 15px 0;
}

.btn-secondary {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #cccccc;
  background: #ffffff;
  color: #333333;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-secondary:hover {
  background-color: #f1f1f1;
}

.google-icon {
  width: 18px;
  margin-right: 10px;
}

.signup-text {
  font-size: 0.9rem;
  color: #666666;
}

.signup-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

}


/* Mobile Devices (<= 576px) */
@media (max-width: 576px) {
  .obboard-create-account .auth-onboard-manager-main {
    left: 0px;
    right: 0px;
    height: auto;
}
.onboard-image{
  width: 150px;
}
.form-control-merge{
  width: 200px;
}
.brands {
  display: grid !important
;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto auto auto !important;
  gap: 20px;
}
}

/* Tablet Devices (577px - 992px) */
@media (min-width: 577px) and (max-width: 992px) {
  .obboard-create-account .auth-onboard-manager-main {
    left: 0px;
    right: 0px;
    height: auto;
   }
   .onboard-image{
    width: 100px;
  }
  .brands {
    display: grid !important
;
    justify-content: center;
    align-items: center;
    grid-template-columns: auto auto auto !important;
    gap: 20px;
  }
}

/* Laptop Devices (993px - 1200px) */
@media (min-width: 993px) and (max-width: 1200px) {
  .obboard-create-account .auth-onboard-manager-main {
    left: 0px;
    right: 0px;
    height: auto;
}
.brands {
  display: grid !important
;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto auto auto !important;
  gap: 20px;
}
}

</style>