import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'
import client from './client'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: "/",
      redirect: { name: 'create-account' }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/UserUIScreens/UserFlow.vue'),
    },
    {
      path: '/stores',
      name: 'stores',
      component: () => import('@/views/Client/GmbV2.vue'),
    },
    {
      path: '/local-post',
      name: 'local-post',
      component: () => import('@/views/Client/LocalPost.vue'),
    },
    {
      path: '/triggers',
      name: 'triggers',
      component: () => import('@/views/Trigger.vue'),
    },
    // {
    //   path: '/new-ui',
    //   name: 'new-ui',
    //   component: () => import('@/views/clientFeature/MultiChannelConnectedDashboard.vue'),
    // },
    {
      path: '/gmb',
      name: 'gmb',
      component: () => import('@/views/Gmb.vue'),
    },
    {
      path: '/new-user-onbaord',
      name: 'new-user-onbaord',
      component: () => import('@/views/UserUIScreens/OnBoardingUI.vue'),
    },
    {
      path: '/privacy-policy',
      component: () => import('@/views/PrivacyPolicy.vue'),
      meta: {
        layout: 'full',
        public: true
      },
    },
    {
      path: '/success-invitation',
      component: () => import('@/components/client/SuccessInvitePage.vue'),
      meta: {
        layout: 'full',
        public: true
      },
    },
    {
      path: '/forgot-password',
      component: () => import('@/components/client/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        public: true
      },
    },
    // {
    //   path: '/create-account',
    //   component: () => import('@/components/client/SignUp.vue'),
    //   meta: {
    //     layout: 'full',
    //     public: true
    //   },
    // },
    {
      path: '/create-account',
      name: 'create-account',
      component: () => import('@/components/client/Create-Account.vue'),
      meta: {
        layout: 'full',
        public: true
      },
    },
    {
      path: '/reset-password/:accessToken',
      component: () => import('@/components/client/CreateNewPassword.vue'),
      meta: {
        layout: 'full',
        public: true
      },
    },
    {
      path: '/gmb-dashboard',
      name: 'gmb-dashboard',
      component: () => import('@/views/Client/GMBDashboard.vue'),
    },
    {
      path: '/creative-analysis',
      name: 'creative-analysis',
      component: () => import('@/views/clientFeature/CreativeAnalysisOverall.vue'),
    },
    {
      path: '/gmb-dealers-dashboard',
      name: 'gmb-dealers-dashboard',
      component: () => import('@/views/clientFeature/GmbForDealers.vue'),
    },
    {
      path: '/d2scale-ai',
      name: 'd2scale-ai',
      component: () => import('@/views/D2Scale-ai.vue'),
    },
    {
      path: '/activity-tracker',
      name: 'activity-tracker',
      component: () => import('@/views/ActivityTracker.vue'),
    },
    {
      path: '/configuration-settings',
      name: 'configuration-settings',
      component: () => import('@/views/UserUIScreens/OnBoardingUI.vue'),
    },
    // {
    //   path: '/d2scale-ai',
    //   name: 'd2scale-ai',
    //   component: () => import('@/views/D2Scale-ai.vue'),
    // },
    // {
    //   path: '/activity-tracker',
    //   name: 'activity-tracker',
    //   component: () => import('@/views/clientFeature/ActivityTracker.vue'),
    // },
    {
      path: '/all-stores',
      name: 'all-stores',
      component: () => import('@/views/Stores.vue'),
    },
    {
      path: '/access-control',
      name: 'access-control',
      component: () => import('@/views/AccessControl.vue'),
    },
    {
      path: '/auth/callback',
      name: 'google-callback',
      component: () => import('@/views/GoogleCallback.vue'),
      meta: {
        layout: 'full',
        public: true
      },
    },
    {
      path: '/integrate/google/callback',
      name: 'google-analytics-callback',
      component: () => import('@/views/GoogleAnalyticsCallback.vue'),
      meta: {
        layout: 'full',
        public: true
      },
    },
    {
      path: '/integrate/salesforce/callback',
      name: 'salesforce-callback',
      component: () => import('@/views/SalesforceCallBack.vue'),
      meta: {
        layout: 'full',
        public: true
      },
    },
    {
      path: '/integrate/google-ads/callback',
      name: 'google-ads-callback',
      component: () => import('@/views/GoogleAdsCallback.vue'),
      meta: {
        layout: 'full',
        public: true
      },
      props: { customValue: 'google-ads-callback' }
    },
    {
      path: '/integrate/facebook-ads/callback',
      name: 'meta-ads-callback',
      component: () => import('@/views/MetaAdsCallBack.vue'),
      meta: {
        layout: 'full',
        public: true
      },
      props: { customValue: 'meta-ads-callback' }
    },
    {
      path: '/integrate/google-my-business/callback',
      name: 'google-my-business-callback',
      component: () => import('@/views/GoogleAdsCallback.vue'),
      meta: {
        layout: 'full',
        public: true
      },
      props: { customValue: 'google-my-business-callback' }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Signin.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true
      },
    },
    // {
    //   path: '/logina',
    //   name: 'login',
    //   component: () => import('@/views/Login.vue'),
    //   meta: {
    //     layout: 'full',
    //     redirectIfLoggedIn: true
    //   },
    // },
    {
      path: '/integrate/dv-360/callback',
      name: 'google-dv360-callback',
      component: () => import('@/views/DV360Callback.vue'),
      meta: {
        layout: 'full',
        public: true
      },
      props: { customValue: 'google-dv360-callback' }
    },
    ...client,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  const isLoggedIn = await isUserLoggedIn();
  if (to.name === 'create-account' && isLoggedIn) {
    next("/dashboard")
  }
  else if (to.meta.public && !isLoggedIn) {
    next();
  }
  else if (to.name === 'register' || to.name === 'login' && !isLoggedIn) {
    next();
  } 
  else if (to.name === 'stores' && from.name === 'client-gmb-dashboard') {
    next()
    setTimeout(()=>{
      window.location.reload()
    }, 300)
  }
  else if (to.name !== 'login' && !isLoggedIn) {
    next("/login?redirect=" + to.path);
  } else if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next("/dashboard")
  }
  else {
    next()
  }
  let AddCustomClasses =  document.getElementById("custom-Stores");
  if(AddCustomClasses){
    AddCustomClasses.classList.remove("active");
  }
})


// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
