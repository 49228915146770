<template>
    <div
      class="
        text-center
        d-flex
        align-items-center
        justify-content-between
        full-screen
      "
    >
    {{ id }}
      <div class="text-center mx-auto">
        <b-spinner
          style="width: 3rem; height: 3rem"
          label="Large Spinner"
          type="grow"
          variant="primary"
        />
      </div>
    </div>
  </template>
  
  <script>
  import Cookies from 'js-cookie';
  import { BSpinner } from "bootstrap-vue";
  import axios from "axios";
  import { getUserToken } from "@/auth/utils";
  import useJwt from "@/auth/jwt/useJwt";
  
  export default {
    components: {
      BSpinner,
    },
    props: {
      customValue: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        loggingIn: true,
        isExternalUser: null,
        url:''
      };
    },
    methods: {
      validateAuthCode() {
        if (!this.$route.query.code || !this.$route.query.state) {
          return this.$router.push("/login");
        }
        axios
          .post(
            `${process.env.VUE_APP_SERVER_URL}/api/facebook/login/auth`,
            {
              code: this.$route.query.code,
              clientID: this.$route.query.state,
            },
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
              },
            }
          )
          .then((response) => {
            const clientSlug = response.data.slug; 
            if (this.isExternalUser === 'true') {
              this.$router.push(
              `/configuration-settings`
            );
            }else{
              this.$router.push(
              `/client/${clientSlug}?next=meta-ads-customer-selection`
            );
            }
           
          })
          .catch((error) => {
            console.log(error.response);
            this.$router.push("/client/list?error=meta-login-error");
          });
      },
    },
    mounted() {
      this.isExternalUser = Cookies.get(useJwt.jwtConfig.userExternalUser);
      this.validateAuthCode();
    },
    computed: {
      id() {
        return this.$route.query.name;
      }
    }
  };
  </script>
  
  <style scoped>
  .full-screen {
    height: 100vh;
  }
  </style>