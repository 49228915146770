<template>
  <div>
    <b-overlay>
      <template #overlay>
        <Spinner />
      </template>
  
      <template v-if="!isClientLoadingProperly">
        <OnBoardingUI 
          v-if="(clients.length <= 1 && isNewUser === 'true' && isExternalUser === 'true') || clients.length === 0" 
        />
        <Dashboard v-else />
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { BOverlay } from "bootstrap-vue";
import Cookies from "js-cookie";
import useJwt from "@/auth/jwt/useJwt";
import Dashboard from "../Dashboard.vue";
import Spinner from "@/components/client/Spinner.vue";
import OnBoardingUI from "./OnBoardingUI.vue";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
export default {
  data() {
    return {
      clients: [],
      isNewUser: null,
      isExternalUser: null,
      isClientLoadingProperly: false,
      pagination: {
        limit: 7,
        currentPage: 1,
      },
      totalClients: "",
    };
  },
  components: {
    OnBoardingUI,
    Dashboard,
    Spinner,
    BOverlay,
  },
  methods: {
    fetchClients(search) {
      this.isClientLoadingProperly = true;
      //   const storedDate = Cookies.get('lastVisitedDashboardDate');
      //   if (storedDate === this.todayDateCompare) {
      //     console.log("");
      //   } else {
      //     Cookies.set('lastVisitedDashboardDate', this.todayDateCompare, { expires: 1 });
      //     this.addActivityLog();
      //   }
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/api/client/get`, search, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.clients = response.data.clients;
          this.totalClients = response.data.total;
          this.isBusy = false;
          this.isClientLoadingProperly = false;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.isClientLoadingProperly = false;
        });
    },
  },
  mounted() {
    this.isNewUser = Cookies.get(useJwt.jwtConfig.userNewUser);
    this.isExternalUser = Cookies.get(useJwt.jwtConfig.userExternalUser);
    this.fetchClients(this.pagination);
  },
};
</script>

<style></style>
