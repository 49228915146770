<template>
        <div class="mainDashboardContainer">
            <b-row v-if="client.status" class="content-header">
              <b-col class="content-header-left mb-2" cols="10">
                <b-row class="breadcrumbs-top">
                  <b-col cols="12">
                    <h2 class="content-header-title float-left pr-1 mb-0 text-white">
                      <b-avatar class="mr-1" size="32" :src="client.logo" :text="avatarText(client.name)"
                        :variant="client.logo ? `white` : `primary`" />
                      {{ client.name }}
                    </h2>
                    <div class="breadcrumb-wrapper breadcrumb-container">
                      <b-breadcrumb>
                        <b-breadcrumb-item to="/">
                          <feather-icon icon="HomeIcon" size="16" class="align-text-top text-white" />
                        </b-breadcrumb-item>
                        <b-breadcrumb-item v-for="item in breadcrumbs" :key="item.text" :active="item.active" :to="item.to"
                          class="text-white">
                          {{ item.text }} 
                        </b-breadcrumb-item>
                      </b-breadcrumb>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="2">
              </b-col>
            </b-row>
            <div v-if="client.status &&
              client.integrations.dv360 &&
              client.dv360.partnerID.length > 0
              ">
              <b-overlay class="dashboard-overlay" :show="isClientLoad || isReportDataLoading || isReportDataDateWiseLoading" rounded="sm">
                <date-selector  @fetchDailyReport="getDateWiseReportData"
                 @fetchReportData="getReportData" 
                 :client="client._id" :slug="$route.params.slug"
                    :businessType="client.businessType" 
                     :iconCardType="iconType" :groupCardFlag="isReportDataDateWiseLoading"
                      :summaryData="overviewData" :chartSummaryData="overviewData"
                     />
                <b-card class="tabs-container screenAlignmentFacbook p-1"> 
                    <b-tabs fill>
                      <b-tab
                        title="Overview"
                        active
                        @click="changeTab('Overview')"
                      ></b-tab>
                      <b-tab
                        title="Daily Report"
                        @click="changeTab('DailyReport')"
                      ></b-tab>
                      <b-tab
                      title="Campaign Report"
                      @click="changeTab('CampaignReport')"
                    ></b-tab>
                    </b-tabs>
                  </b-card> 
                  <data-table
                  title="Daily Report"
                   exportsType="dv360Daily"
                  v-if="this.tabName === 'DailyReport'"
                  type="dv360-daily-report"
                  :chartData="datewiseData"
                  :businessType="client.businessType[0]"
                />
                  <data-table
                  title="Campaign Report"
                  v-if="this.tabName === 'CampaignReport'"
                  type="DV360CampaignReportcebook"
                  exportsType="DV360CampaignReportcebook"
                  :chartData="campaignsDV360Data"
                  :businessType="client.businessType[0]"
                />
                <Dv360OverView
                v-if="
                  this.tabName === 'Overview' &&
                    client.status &&
                    client.integrations.dv360 &&
                    client.dv360.partnerID.length > 0
                "
                :isDataLoading="isReportDataLoading"
                :summaryData="overviewData"
                :chartData="dv360ChartData"
                :iconCardType="iconType"
                :businessType="client.businessType"
                mediumType="aAds"
              />
              </b-overlay>
            </div>
          </div>
    </template>
    
    <script>
    import {
      BRow,
      BCol,
      BCardTitle,
      BFormCheckbox,
      BBreadcrumb,
      BBreadcrumbItem,
      BAlert,
      BLink,
      BAvatar,
      BCard,
      BButton,
      BOverlay,
      BTabs,
      BTab,
    } from "bootstrap-vue";
    import axios from "axios";
    import Dv360OverView from './Dv360OverView.vue'
    import DataTable from "../GoogleAds/DataTable.vue"; 
    import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
    import { getUserToken } from "@/auth/utils";
    import DateSelector from "./DateSelector.vue";
    import moment from "moment";
    import MultiHeaderProjectChannel from "@/views/clientFeature/MultiheaderProjectChannel.vue";
    export default {
        data() {
        return {
          overviewData: {},
          dv360ChartData:[],
          datewiseData: [],
          campaignsDV360Data:[],
          selectedStartDate:"",
          selectedEndDate:"",
          selectedZoneTypeForFilter:[],
          selectedProductType:"all",
          adRunningError:"",
          startDateProjectName:"",
          endDateProjectName:"",
          tabName: "",
          projectsTab: false,
          isReportDataDateWiseLoading:false,
          isProjectDataLoad:false,
          client: {
            status: false,
            integrations: {
              googleAds: null,
            },
            googleAdsCustomerID: null,
          },
          errorMessage: "",
          iconType: "",
          breadcrumbs: [
            {
              text: "Overview",
              to: "/dashboard",
            },
          ],
          isReportDataLoading: true,
          isClientLoad: true,
          currentDateRange: "",
          isProjectWiseIsLoad:false,
          isTotalIsLoad:false
        };
      },
      components: {
        BRow,
        BCol,
        BCardTitle,
        BFormCheckbox,
        BBreadcrumb,
        BBreadcrumbItem,
        BAlert,
        BLink,
        BAvatar,
        BCard,
        BButton,
        BOverlay,
        BTabs,
        BTab,
        DateSelector,
        MultiHeaderProjectChannel,
        multiChannelSpinner,
        DataTable,
        Dv360OverView
      },
      methods:{
    getReportData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      this.selectedStartDate = startDate
      this.selectedEndDate = endDate
      if (campaignType) {
        additionalQueryParams =
          additionalQueryParams + `&campaign-type=${campaignType}`;
      }
      if (campaigns) {
        additionalQueryParams =
          additionalQueryParams + `&campaigns=${campaigns}`;
      }
      if (adGroups) {
        additionalQueryParams =
          additionalQueryParams + `&ad-groups=${adGroups}`;
      }
      if (!startDate && !endDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
   
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }

      this.campaignsDV360Data = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/dv-360/get-campaign-reports?clientID=${this.client._id}${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.isReportDataLoading = false;
          this.campaignsDV360Data = response.data.reports;
        })
        .catch(error => {
          this.isReportDataLoading = false;
          let errorText =
            error.response.data && error.response.data.error
              ? error.response.data.error
              : "Unknown error occured";
          // this.isReportDataLoading = false;
          this.showToast("Error", errorText, "danger");
        });
    },
    getDateWiseReportData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isReportDataDateWiseLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (campaignType) {
        additionalQueryParams =
          additionalQueryParams + `&campaign-type=${campaignType}`;
      }
      if (campaigns) {
        additionalQueryParams =
          additionalQueryParams + `&campaigns=${campaigns}`;
      }
      if (adGroups) {
        additionalQueryParams =
          additionalQueryParams + `&ad-groups=${adGroups}`;
      }
      if (!startDate && !endDate) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
      }
      this.overviewData = {};
      this.datewiseData = [];
      this.dv360ChartData = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/dv-360/get-datewise-reports?clientID=${this.client._id}${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.overviewData = response.data.totalData;
          this.datewiseData = response.data.dateFormatted;
          this.dv360ChartData = response.data.reports;
          this.isReportDataDateWiseLoading = false;
        })
        .catch(error => {
          this.isReportDataDateWiseLoading = false;
          let errorText =
            error.response.data && error.response.data.error
              ? error.response.data.error
              : "Unknown error occured";
          this.showToast("Error", errorText, "danger");
        });
    },
        changeTab(name) {
          this.tabName = name;
        },
        avatarText(value) {
          if (!value) return "";
          const nameArray = value.split(" ");
          return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
        },
        formatDateDisplay(date) {
          return moment(date).format("DD-MM-YYYY");
        },
        formatDate(inputDate) {
        const [year, month, day] = inputDate.split('-');
        const updatedDate = `${day}-${month}-${year}`;
        return updatedDate;
       },
        getClient() {
          const slug = this.$route.params.slug;
          this.isClientLoad = true
          axios
            .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
              },
            })
            .then((response) => {
              this.client = { ...response.data.client, status: true };
              this.breadcrumbs.push({
                text: response.data.client.name,
                to:`/client/${response.data.client.slug}`,
                active: false,
              });
              this.breadcrumbs.push({
                text: "dv360 Dashboard",
                to: `/client/${response.data.client.slug}/dashboard/dv360`,
                active: true,
              });
              if (
            response.data.client &&
            response.data.client.integrations.dv360 &&
            response.data.client.dv360.partnerID
          ) {
            this.getReportData();
            this.getDateWiseReportData();
          }
              this.isClientLoad = false
            })
            .catch((error) => {
              console.log(error.response.data);
              this.client.status = false;
              this.errorMessage = error.response.data.error;
              this.isClientLoad = false
            });
        },
      },
      filters: {
        date(val) {
          return val ? moment(val).format("DD-MM-YYYY") : "";
        }
      },
      mounted() {
        this.tabName = "Overview";
        this.getClient();
      },
    }
    </script>
    <style lang="scss">
    // @import "@core/scss/vue/libs/vue-select.scss";
    
    .screenAlignmentFacbook {
      margin-left: -0.5rem;
      margin-right: -0.5rem;
    
      .card-body {
        padding: 0.5rem !important;
      }
    }
    
    .breadcrumb-container a {
      color: antiquewhite !important;
    }
    
    .dashboard-overlay .rounded-sm {
      inset: -20px !important
    }
    .spinnerCentered {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 20px;
      /* Set the height of the container */
    }
    </style>