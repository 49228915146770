<template>
  <div>
    <div style="display: flex; justify-content: space-between">
      <div>
        <b-card-title>
          <h3 class="app-title mdsm">Target Vs Achieved</h3>
        </b-card-title>
      </div>
      <div style="display: flex; justify-content: space-between; gap: 5px;">
        <div style="display: flex; justify-content:left; gap:10px;">
          <div class="selectBox" style="margin-top: -10px;">
            <label>Project:</label>
            <br />
            <b-form-select
              style="text-transform: capitalize; width:200px!important"
              :options="mergedProjectTableLevelWiseData"
              label="title"
              :clearable="false"
              v-model="selectedAllProject"
              @input="getTargetProjectNameType"
            >
            </b-form-select>
          </div>
          <div class="selectBox" style="margin-top: -10px;">
            <label>Channel:</label>
            <br />
            <b-form-select
              style="text-transform: capitalize;"
              :options="sourceType"
              label="title"
              :clearable="false"
              v-model="selectedSource"
              @input="getChannelType"
            >
              <template v-slot:option="option">
                <span :class="option"></span>
                <span v-if="option.title == 'all'">
                  All
                </span>
                <span v-else-if="option.title == 'googleAds'">
                  Google Ads
                </span>
                <span v-else-if="option.title == 'meta'">
                  Meta
                </span>
                <span v-else>
                  {{ option.title }}
                </span>
              </template>
            </b-form-select>
          </div>
          <div class="selectBox" style="margin-top: -10px;">
            <label>Duration:</label>
            <br />
            <b-form-select
              style="text-transform: capitalize; width:300px"
              :options="sourceTargetType"
              label="title"
              :clearable="false"
              v-model="selectedTypeSource"
              @input="getTargetType"
            >
              <template v-slot:option="option">
                <span :class="option"></span>
                <span v-if="option.title == 'all'">
                  All
                </span>
                <span v-else-if="option.title == 'googleAds'">
                  Google Ads
                </span>
                <span v-else-if="option.title == 'meta'">
                  Meta
                </span>
                <span v-else>
                  {{ option.title }}
                </span>
              </template>
            </b-form-select>
          </div>
          <div class="mt-1">
            <b-button variant="primary" @click="openTargetVsAchived('addTarget')"
              >Add</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.isTargetVsAchievedLoad" class="spinnerCentered">
      <multiChannelSpinner />
    </div>
    <div v-else>
      <vue-good-table 
      class="custom-data-table mt-1"
      :rows="formattedData"
      :columns="targetValTable"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
    <template  slot="table-row" slot-scope="props">
      <span v-if="props.column.label == 'Actions'">
       <div style="display: flex;gap:10px">
        <b-button
        size="sm"
        variant="primary"
        @click="editTargetVsAchieved('editTarget', props.row)"
        ><span style="font-weight: bold;">Edit</span></b-button
      >
      <b-button
      size="sm"
      variant="danger"
      @click="openDeleteTargetModal(props.row)"
      ><span style="font-weight: bold;">Delete</span></b-button
    >
       </div>
      </span>
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1"></div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="pageLengthALL"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    </div>
    <b-modal
      v-model="isTargetVsAchieved"
      id="showShopNameModal"
      hide-footer
      hide-header
      centered
      size="lg"
    >
      <b-card-text>
        <div style="display: flex; justify-content:space-between; padding:10px">
          <h5 class="app-title mdsm" v-if="this.modalMode === 'addTarget'">
            Add Target Values
          </h5>
          <h5 class="app-title mdsm" v-if="this.modalMode === 'editTarget'">
            Edit Target Values
          </h5>
          <div class="header text-right">
            <feather-icon
              style="cursor: pointer"
              @click="closeTargetVsAchived"
              class="Xicon"
              icon="XIcon"
            ></feather-icon>
          </div>
        </div>
      </b-card-text>
      <div
        style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;"
      > 
      <div style="display: flex; justify-content:left; gap:12px;padding:12px 8px !important; height:90px">
        <div style="margin-top: 25px;">
            <div style="display: flex; gap: 0px; margin-top: 5px;">
              <label style="display: flex; align-items: center;">
                <input   :disabled="this.modalMode === 'editTarget'" class="checkbox-weekly" style="cursor: pointer;" type="radio" v-model="reportModeSelected" value="custom"> <span style="margin-left: 1px; margin-top:2px">Custom Date</span>
              </label>
              <label style="display: flex; align-items: center; margin-left:5px">
                <input  :disabled="this.modalMode === 'editTarget'"  class="checkbox-weekly" style="cursor: pointer;" type="radio" v-model="reportModeSelected" value="monthly"> <span style="margin-left: 1px; margin-top:2px">Monthly</span>
              </label>
            </div>
        </div>
        <div class="selectBox" v-if="this.reportModeSelected === 'custom' ">
          <p class="date-range mb-0" style="font-size: 12px;">
            <span v-if="valdationDateRangeRequired" class="text-primary"> Select Date-Range</span>
            <span v-else> Select Date-Range</span>
          </p>
          <date-range-picker
            ref="picker"
            opens="right"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :showWeekNumbers="false"
            :showDropdowns="true"
            :autoApply="true"
            v-model="dateRange"
            @update="updateValues"
            :dateFormat="dateFormat"
            control-container-class="picker-controller"
            :ranges="dateRanges"
            :auto-apply="false"
            :readonly="false"
            
            class="googleDateSelector"
            style="margin-top: 5px;"
          >
            <template v-slot:input="picker">
              <feather-icon
                icon="CalendarIcon"
                size="12"
                class="mr-1"
              ></feather-icon>
              <span style="font-size: 13px;">{{ formatDateDisplay(picker.startDate) }} -
              {{ formatDateDisplay(picker.endDate) }}</span>
            </template>
          </date-range-picker>
        </div>
        <div  style="display: flex; justify-content:space-between; gap:10px;" v-if="this.reportModeSelected === 'monthly' ">
          <div class="form-group selectChannelBox">
            <label for="monthSelect" class="form-label"> Month</label>
            <div class="select-container">
              <b-form-select
                id="monthSelect"
                v-model="selectedMonth"
              >
                <option
                  v-for="month in months"
                  :value="month.value"
                  :key="month.value"
                  :disabled="month.value >= currentMonth"
                >
                  {{ month.label }}
                </option>
              </b-form-select>
            </div>
          </div>
          <div class="form-group selectChannelYearBox">
            <label for="monthSelect" class="form-label"> Year</label>
            <div class="select-container">
              <b-form-select disabled id="monthSelect" v-model="selectedYear">
                <option v-for="year in year" :value="year" :key="year">
                  {{ year }}
                </option>
              </b-form-select>
            </div>
          </div>
        </div>
        <div class="selectBox">
          <label>Project</label>
          <br />
          <b-form-select
            style="text-transform: capitalize; width:200px!important"
            :options="mergedProjectWiseData"
            label="title"
            :clearable="false"
            v-model="selectedProject"
            :disabled="this.modalMode === 'editTarget'"
          >
            <template v-slot:option="option">
              <span :class="option"></span>
              <span v-if="option.title == 'all'">
                All
              </span>
              <span v-else-if="option.title == 'googleAds'">
                Google Ads
              </span>
              <span v-else-if="option.title == 'meta'">
                Meta
              </span>
              <span v-else>
                {{ option.title }}
              </span>
            </template>
          </b-form-select>
        </div>
        <div class="selectChannelBox">
          <label>Channel</label>
          <br />
          <b-form-select
            style="text-transform: capitalize; width:200px"
            :options="sourceType"
            label="title"
            :clearable="false"
            v-model="selectedSource"
            :disabled="this.modalMode === 'editTarget'"
          >
            <template v-slot:option="option">
              <span :class="option"></span>
              <span v-if="option.title == 'all'">
                All
              </span>
              <span v-else-if="option.title == 'googleAds'">
                Google Ads
              </span>
              <span v-else-if="option.title == 'meta'">
                Meta
              </span>
              <span v-else>
                {{ option.title }}
              </span>
            </template>
          </b-form-select>
        </div>
      </div>  
      </div>
      <b-row class="p-1 mt-1">
        <b-col cols="4">
          <b-form-group id="input-group-1" label="Spends" label-for="input-1">
            <b-form-input
              id="input-1"
              type="number"
              v-model="targetSpends"
              placeholder="Enter Spends"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group id="input-group-1" label="Leads" label-for="input-1">
            <b-form-input
              id="input-1"
              type="number"
              v-model="targetLeads"
              placeholder="Enter Leads"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group id="input-group-1" label="CPL" label-for="input-1">
            <b-form-input
              id="input-1"
              type="number"
              placeholder="Enter CPL"
              v-model="targetCpl"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="p-1">
        <div style="display: flex; width: 100%;">
          <div style="flex-grow: 1;"></div>

          <b-button v-if="this.modalMode === 'addTarget'"
            variant="primary"
            style="margin-left: auto;"
            @click="calculateTargetVsAchieved"
            :disabled="isSubmitTargetButtonDisabled"
          >
            Add <b-spinner v-if="isCalculating" small></b-spinner>  
          </b-button>
          <b-button v-if="this.modalMode === 'editTarget'"
          variant="primary"
          style="margin-left: auto;"
          @click="updatedCalculateTargetVsAchieved"
        >
          Update Target Value <b-spinner v-if="isUpdatingCalculating" small></b-spinner>  
        </b-button>
        </div>
      </b-row>
    </b-modal>
    <b-modal
    no-close-on-backdrop
    id="modal-center"
    hide-footer
    hide-header
    centered
    size="small"
    v-model="deleteTargetVsAchieved"
  >
    <b-card-text>
      <div style="float: right">
        <b-button
          variant="flat-primary"
          @click="closeDeleteTargetModal"
          style="margin-top: -10px; margin-right: -18px"
          ><feather-icon icon="XIcon"></feather-icon
        ></b-button>
      </div>
      <br />
      <div class="text-center" style="margin-top: 5px">
        <h4>Are you sure want to delete this entry?</h4>
      </div>
      <div class="button-container">
        <b-button variant="outline-primary" @click="closeDeleteTargetModal">
          Cancel
        </b-button>
        <b-button variant="danger" @click="deleteTargetVsAchievedConfirmation">
         Delete
          <b-spinner v-if="isDeletingTargetVsAchieved" small class="ml-1" variant="white"
        /></b-button>
      </div>
      <div></div>
    </b-card-text>
  </b-modal>
  <b-modal no-close-on-backdrop
  id="modal-center"
  hide-footer
  hide-header
  centered
  size="xs"
  v-model="valdationDateRangeRequired">
  <div style="justify-content:center;align-items:center; text-align:center; padding:20px">
    <b-icon font-scale="2.5" icon="check-circle-fill" variant="primary"/>
    <h5 class="text-primary p-1" >You have selected a duration of {{ this.daysSelectedCount }} days.</h5>
    <b-button @click="closeDateWarning" variant="primary">Ok</b-button>
  </div>
  </b-modal>
  </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import { getUserToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import moment from "moment";
import axios from "axios";
import {
  BButton,
  BModal,
  BCardText,
  BFormSelect,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BSpinner,
  BPagination
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
export default {
  data() {
    return {
      daysSelectedCount:false,
      currentMonth: new Date().getMonth(),
      valdationDateRangeRequired:false,
      deleteTargetVsAchieved:false,
      isUpdatingCalculating:false,
      isDeletingTargetVsAchieved:false,
      targetVsAchivedObjectId:"",
      modalMode:"addTarget",
      pageLength:7,
      sourceTargetType:['custom','monthly'],
      selectedTypeSource:"custom",
      startDateForAll:"",
      endDateForAll:"",
      editClientTarget:"",
      months: [
        { label: "January", value: 0 },
        { label: "February", value: 1 },
        { label: "March", value: 2 },
        { label: "April", value: 3 },
        { label: "May", value: 4 },
        { label: "June", value: 5 },
        { label: "July", value: 6 },
        { label: "August", value: 7 },
        { label: "September", value: 8 },
        { label: "October", value: 9 },
        { label: "November", value: 10 },
        { label: "December", value: 11 },
      ],
      selectedYear: new Date().getFullYear(),
      isCalulatedSuccessFully:false,
      isCalculating:false,
      year: [new Date().getFullYear()],
      selectedMonth: new Date().getMonth() - 1,
      currentMonth: new Date().getMonth(),
      reportModeSelected:"custom",
      targetCpl: 0,
      targetSpends: 0,
      targetLeads: 0,
      isTargetVsAchieved: false,
      sourceType: ["all", "googleAds", "meta"],
      selectedSource: "all",
      selectedProject: "",
      dateRange: {
        endDate: moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        startDate: moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD"),
      },
    };
  },
  components: {
    BButton,
    VueGoodTable,
    BModal,
    BCardText,
    BFormSelect,
    BCardTitle,
    DateRangePicker,
    BFormGroup,
    BFormInput,
    BRow,
    ToastificationContent,
    BCol,
    BSpinner,
    multiChannelSpinner,
    BPagination
  },
  filters: {
    date(val) {
      return val ? moment(val).format("DD-MM-YYYY") : "";
    },
  },
  methods: {
    openDateWarning(){
      this.valdationDateRangeRequired = true
    },
    closeDateWarning(){
      this.valdationDateRangeRequired = false
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toISOString().split('T')[0]; // Formats to YYYY-MM-DD
    },
    closeDeleteTargetModal(){
      this.deleteTargetVsAchieved = false
    },
    openDeleteTargetModal(data){
      this.targetVsAchivedObjectId = data._id
      this.deleteTargetVsAchieved = true
    },
    calculateTargetVsAchieved(){
      this.isCalulatedSuccessFully = false
      let startDate, endDate;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        startDate = prevWeek.toISOString().split("T")[0];
        endDate = yesterday.toISOString().split("T")[0];
      } else {
        startDate = this.startDateForAll;
        endDate = this.endDateForAll;
      }
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/targetVsAchieved/addTarget/`,
          {
            clientID: this.clientId,
            startDate: startDate,
            endDate: endDate,
            channel: this.selectedSource,
            project:this.selectedProject,
            targetType:this.reportModeSelected === 'custom' ? 'weekly':this.reportModeSelected,
            month:this.selectedMonth,
            targetedValues:{
              "spends": this.targetSpends,
              "leads": this.targetLeads,
              "cpl": this.targetCpl
            }
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.$emit("targetCalCulated");
          this.isCalculating = false
          this.isTargetVsAchieved = false
          this.showToast(
            "Success!",
            "Calculated Target Vs Achieved Successfully!",
            "success"
          );
        })
        .catch(error => {
          this.isCalculating = false
          this.isCalulatedSuccessFully = false
          this.isTargetVsAchieved = false
          this.showToast("Error", error.responseMessage, "danger");
        });
    },
    updatedCalculateTargetVsAchieved(){
      this.isUpdatingCalculating = true
      let startDate, endDate;
      if (!this.endDateForAll && !this.startDateForAll) {
        startDate = this.dateRange.startDate;
        endDate = this.dateRange.endDate;
      } else {
        startDate = this.startDateForAll;
        endDate = this.endDateForAll;
      }
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/targetVsAchieved/addTarget/`,
          {
            clientID: this.clientId,
            startDate: startDate,
            endDate: endDate,
            channel: this.selectedSource,
            project:this.selectedProject,
            targetType:this.reportModeSelected === 'custom' ? 'weekly':this.reportModeSelected,
            month:this.selectedMonth,
            targetedValues:{
              "spends": this.targetSpends,
              "leads": this.targetLeads,
              "cpl": this.targetCpl
            }
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.$emit("targetCalCulated");
          this.isUpdatingCalculating = false
          this.isTargetVsAchieved = false
          this.showToast(
            "Success!",
            "Updated Calculated Target Vs Achieved Successfully!",
            "success"
          );
        })
        .catch(error => {
          this.isUpdatingCalculating = false
          this.isTargetVsAchieved = false
          this.showToast("Error", error.responseMessage, "danger");
        });
    },
    async deleteTargetVsAchievedConfirmation() {
      this.isDeletingTargetVsAchieved = true;
      try {
        const response = await axios.delete(`${process.env.VUE_APP_SERVER_URL}/api/targetVsAchieved?clientID=${this.clientId}&targetVsAchievedID=${this.targetVsAchivedObjectId}`, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${getUserToken()}`,
          }
        });
        this.showToast(
          "Success",
          "The selected Target Vs Achieved value has been successfully delete!",
          "success"
        );
        this.$emit("targetCalCulated");
        this.isDeletingTargetVsAchieved = false;
        this.deleteTargetVsAchieved =  false;
      } catch (error) {
        this.isDeletingTargetVsAchieved = false;
        this.showToast(
          "Error",
          "Unable to Delete Target Vs Achieved! Please try again or contact support.",
          "danger"
        );
        console.error(error.response.data);
      }
    },
    getChannelType(val){
      this.$emit("getChannelType", val);
    },
    getTargetType(val){
        this.$emit("getTargetTypeChange", val);
    },
    getTargetProjectNameType(val){
        this.$emit("getTargetProjectNameType", val);
      },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    formatDateDisplay(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    closeTargetVsAchived() {
      this.targetCpl = 0
      this.targetSpends = 0
      this.targetLeads = 0
      this.isTargetVsAchieved = false;
    },
    openTargetVsAchived(name) {
      this.targetCpl = 0
      this.targetSpends = 0
      this.targetLeads = 0
      this.modalMode = name
      this.isTargetVsAchieved = true;
    },
    editTargetVsAchieved(name, data) {
      this.modalMode = name
      this.editClientTarget = data._id
      this.dateRange.startDate = data.startDate
      this.dateRange.endDate = data.endDate
      this.selectedSource = data.channel
      this.selectedProject = data.project
      this.reportModeSelected = data.targetType
      this.targetCpl = this.convertToNumber(data.cplTargeted)
      this.targetSpends = this.convertToNumber(data.spendsTargeted)
      this.targetLeads = this.convertToNumber(data.leadsTargeted)
      this.isTargetVsAchieved = true;
    },
    convertToNumber(input) {
      if (typeof input === "string") {
          input = input.replace(/[^0-9.-]/g, "").replace(/,/g, "");
      }
      const num = parseFloat(input);
      return isNaN(num) ? null : num;
  },
    dateChanged(val) {
      this.$emit("fetchNewData", val.code);
    },
    updateValues(values) {
     // Keep startDate and endDate as moment objects for calculation
  let startDate = moment(values.startDate);
  let endDate = moment(values.endDate);

  // Format the dates for display purposes only
  this.startDateForAll = startDate.format("YYYY-MM-DD");
  this.endDateForAll = endDate.format("YYYY-MM-DD");

  const timeDifference = endDate.toDate().getTime() - startDate.toDate().getTime();
  const dayDifference = timeDifference / (1000 * 3600 * 24);
  this.valdationDateRangeRequired = dayDifference >= 0;
  this.daysSelectedCount = Math.max(dayDifference + 1, 0);
  
},
  dateFormat(classes, date) {
      let yesterday = new Date();

      let d1 = moment(date).format("DD-MM-YYYY");
      let d2 = moment(yesterday.setDate(yesterday.getDate() - 1)).format(
        "DD-MM-YYYY"
      );
      return classes;
  },
  },
  computed: {
    isSubmitTargetButtonDisabled(){
      return this.targetLeads === 0 || this.targetCpl === 0 || this.targetSpends === 0
    },
    pageLengthALL(){
      return this.formattedData.length 
    },
    formattedData() {
      return this.flattenedData.map(row => ({
        ...row,
        startDate: this.formatDate(row.startDate),
        endDate: this.formatDate(row.endDate),
      }));
    },
    targetValTable() {
      let tableColumns = [
        {
          label: "Project Name",
          field: "project",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Start Date",
          field: "startDate",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "End Date",
          field: "endDate",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Duration",
          field: "targetType",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Channel",
          field: "channel",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Spends Target",
          field: "spendsTargeted",
          tdClass: "text-center",
          thClass: "text-center targeted ",
        },
        {
          label: "Leads Target",
          field: "leadsTargeted",
          tdClass: "text-center",
          thClass: "text-center targeted",
        },
        {
          label: "CPL Target",
          field: "cplTargeted",
          tdClass: "text-center",
          thClass: "text-center targeted",
        },
        {
          label: "Spends Achieved",
          field: "spendsAchieved",
          tdClass: "text-center",
          thClass: "text-center acheived",
        },
        {
          label: "Leads Achieved",
          field: "leadsAchieved",
          tdClass: "text-center",
          thClass: "text-center acheived",
        },
        {
          label: "CPL Achieved",
          field: "cplAchieved",
          tdClass: "text-center",
          thClass: "text-center acheived",
        },
        {
          label: "Spends Difference",
          field: "spendsDifference",
          tdClass: "text-center",
          thClass: "text-center difference",
        },
        {
          label: "Leads Difference",
          field: "leadsDifference",
          tdClass: "text-center",
          thClass: "text-center difference",
        },
        {
          label: "CPL Difference",
          field: "cplDifference",
          tdClass: "text-center",
          thClass: "text-center difference",
        },
        {
          label: "Spends Difference %",
          field: "spendsDifferencePercent",
          tdClass: "text-center",
          thClass: "text-center difference_percent",
        },
        {
          label: "Leads Difference %",
          field: "leadsDifferencePercent",
          tdClass: "text-center ",
          thClass: "text-center difference_percent",
        },
        {
          label: "CPL Difference %",
          field: "cplDifferencePercent",
          tdClass: "text-center",
          thClass: "text-center difference_percent",
        },
        {
          label: "Actions",
          field: "-",
          tdClass: "text-center",
          thClass: "text-center",
        },
       
      ];
      return tableColumns;
    },
    flattenedData() {
      if (this.getTargetVsAchivedData) {
        return this.getTargetVsAchivedData.map(item => {
          return {
            _id: item._id,
            channel: item.channel,
            clientID: item.clientID,
            endDate: item.endDate,
            startDate: item.startDate,
            targetType: item.targetType,
            project: item.project,
            createdAt: item.createdAt,
            // Flatten metrics
            spendsAchieved: item?.metricsFormatted?.achievedValues?.spends || 0,
            leadsAchieved: item?.metricsFormatted?.achievedValues?.leads || 0,
            cplAchieved: item?.metricsFormatted?.achievedValues?.cpl || 0,
            spendsTargeted: item?.metricsFormatted?.targetedValues?.spends || 0,
            leadsTargeted: item?.metricsFormatted?.targetedValues?.leads || 0,
            cplTargeted: item?.metricsFormatted?.targetedValues?.cpl || 0,
            spendsDifference: item?.metricsFormatted?.differenceValues?.spends || 0,
            leadsDifference: item?.metricsFormatted?.differenceValues?.leads || 0,
            cplDifference: item?.metricsFormatted?.differenceValues?.cpl || 0,
            spendsDifferencePercent: item?.metricsFormatted?.differencePercentValues?.spends || 0,
            leadsDifferencePercent: item?.metricsFormatted?.differencePercentValues?.leads || 0,
            cplDifferencePercent: item?.metricsFormatted?.differencePercentValues?.cpl || 0,
          };
        });
      } else {
        // Return an empty array if rawData is not defined or empty
        return [];
      }
    },
    mergedProjectWiseData() {
      let mergedData = [];
      const combinedArray = Object.values(
        this.projectNames.projectNames
      ).flat();
      mergedData = [...combinedArray];
      this.selectedProject = mergedData[0];
      return mergedData;
    },
    isMonthSummayDisabled(month) {
      if (this.selectedYear == '2024') {
        const currentMonth = new Date().getMonth();
        const monthIndex = this.months.indexOf(month);
        return monthIndex >= currentMonth + 1;
      }

    },
    mergedProjectTableLevelWiseData() {
      let mergedData = [];
      const combinedArray = Object.values(
        this.projectNames.projectNames
      ).flat();
      mergedData = [...combinedArray];
      mergedData = ['all',...mergedData]
      this.selectedAllProject = mergedData[0];
      return mergedData;
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let last7thDay = new Date();
      last7thDay.setDate(today.getDate() - 7);
      last7thDay.setHours(0, 0, 0, 0);

      let last14thDay = new Date();
      last14thDay.setDate(today.getDate() - 14);
      last14thDay.setHours(0, 0, 0, 0);

      let last30thDay = new Date();
      last30thDay.setDate(today.getDate() - 30);
      last30thDay.setHours(0, 0, 0, 0);

      var thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      var thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let ranges = {
        Today: [today, today],
        Yesterday: [yesterday, today],
        "Last 7 Days": [last7thDay, yesterday],
        "Last 14 Days": [last14thDay, yesterday],
        "Last 30 Days": [last30thDay, yesterday],
        "Last Month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "This Month": [thisMonthStart, thisMonthEnd],
        "This Year": [new Date(today.getFullYear(), 0, 1), new Date(today)],
      };

      return ranges;
    },
  },
  props: {
    clientId: {
      type: String,
    },
    projectNames: {
      type: Object,
    },
    getTargetVsAchivedData:{
      type:Array
    },
    isTargetVsAchievedLoad:{
      type:Boolean
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  /* Set the height of the container */
}
.button-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.selectChannelBox .custom-select {
  width: 125px !important;
}
.selectChannelYearBox .custom-select {
  width: 76px !important;
}
.difference_percent{
  color: #000000 !important;
  background-color: #AFEEEE !important;
  align-content: center !important;
}
.difference{
  color: #000000 !important;
  background-color: #E6E6FA !important;
  align-content: center !important;
}
.acheived{
  color: #000000 !important;
  background-color: #F08080 !important;
  align-content: center !important;
}
.targeted{
  color: #000000 !important;
  background-color: #778899 !important;
  align-content: center !important; 
}
</style>
