<template>
  <div class="mainDashboardContainer">
    <b-row v-if="client.status" class="content-header">
      <b-col class="content-header-left mb-2" cols="10">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0 text-white">
              <b-avatar class="mr-1" size="32" :src="client.logo" :text="avatarText(client.name)"
                :variant="client.logo ? `white` : `primary`" />
              {{ client.name }}
            </h2>
            <div class="breadcrumb-wrapper breadcrumb-container">
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon icon="HomeIcon" size="16" class="align-text-top text-white" />
                </b-breadcrumb-item>
                <b-breadcrumb-item v-for="item in breadcrumbs" :key="item.text" :active="item.active" :to="item.to"
                  class="text-white">
                  {{ item.text }} 
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="2">
        <div style="float: right; width: 200px;">
          <b-button
            @click="sendSchedule(client._id)"
            :size="buttonSize"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" size="12" /> Schedule a Trigger
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-alert :show="!client.status & !isReportDataLoading" variant="danger">
      <h4 class="alert-heading">Error fetching Client data</h4>
      <div class="alert-body">
        {{ errorMessage }}. Check
        <b-link class="alert-link" :to="{ name: 'client-list' }">
          Clients List
        </b-link>
        for other clients.
      </div>
    </b-alert>
    <b-alert :show="client.status && !client.integrations.googleAds" variant="danger">
      <h4 class="alert-heading">Error fetching the Google Ads Reports</h4>
      <div class="alert-body">
        Your Google Ads account was not integrated with this account, or we lost
        the access to it. Click
        <b-link class="alert-link" :to="`/client/${client.slug}?next=google-ads-integration`">
          here
        </b-link>
        to connect or reconnect your account.
      </div>
    </b-alert>
    <b-alert :show="client.status &&
      client.integrations.googleAds &&
      !client.googleAdsCustomerID
      " variant="danger">
      <h4 class="alert-heading">Error fetching the Google Ads Reports</h4>
      <div class="alert-body">
        You don't have a customer account linked to your account. Click
        <b-link class="alert-link" :to="`/client/${client.slug}?next=google-ads-customer-selection`">
          here
        </b-link>
        to select a customer account.
      </div>
    </b-alert>

    <!-- DASHBOARD -->
    <div v-if="client.status &&
      client.integrations.googleAds &&
      client.googleAdsCustomerID
      ">
      <b-overlay class="dashboard-overlay" :show="isReportDataLoading || isClientLoad || isProjectDataLoad " rounded="sm">
        <date-selector :client="client._id" :slug="$route.params.slug" @fetchNewData="getReportData"  @fetchReportData="fetchAllProjects" :chartSummaryData="charts.totalData" :businessType="client.businessType"  :iconCardType="iconType"
            @fetchZoneGoogleAdsWiseData="fetchGoogleAdsWiseZonewiseData"
            @fetchProductProjectGoogleAdsWiseData="fetchGoogleAdsProjectProductWiseData" :adsError="adRunningError"
            @fetchProjectData="fetchProjectWiseData" />
        <b-card class="tabs-container screenAlignmentFacbook p-1"> 
            <b-tabs fill>
              <b-tab
                title="Overview"
                active
                @click="changeTab('Graphs')"
              ></b-tab>
              <b-tab v-if="projectsTab && client &&  client.accessConfiguration && client.accessConfiguration.projects  && client.accessConfiguration.projects.enabled " title="Project Insights" @click="changeTab('ProjectInsights')">
              </b-tab>
              <b-tab title="Group(Zone) Insights" v-if="projectsTab && client &&  client.accessConfiguration && client.accessConfiguration.projects  && client.accessConfiguration.projects.enabled " @click="changeTab('ZoneInsights')" :disabled="this.optionsLength === 0">
              </b-tab>
              <b-tab title="Product Insights" v-if="projectsTab && client &&  client.accessConfiguration && client.accessConfiguration.projects  && client.accessConfiguration.projects.enabled " @click="changeTab('ProductInsights')" :disabled="Object.keys(this.productWiseData).length === 0">
              </b-tab>
              <b-tab title="Daily Report" @click="changeTab('DailyReport')">
              </b-tab>
              <b-tab
                title="Campaign Report"
                @click="changeTab('CampaignReport')"
              >
              </b-tab>
            </b-tabs>
          </b-card>
        <over-view-card v-if="this.tabName === 'Graphs' && client.status &&
          client.integrations.googleAds &&
          client.googleAdsCustomerID
          " :isDataLoading="isReportDataLoading" :summaryData="charts.totalData" :chartData="chartData"
          :iconCardType="iconType" :businessType="client.businessType" />
        <!-- <MultiHeader  v-if="this.tabName === 'ZoneInsights'" style="margin-left:-8px; margin-right: -8px;" :tableData="zoneWiseData" type="zoneWise" selectionType="single" :businessTypeProject="client.businessType[0]" :projectNameSummary="'Group(Zone) Insights'" selectBoxType="Select Zone(s)"/> -->
        <!-- <MultiHeader v-if="this.tabName === 'ZoneInsights'" style="margin-left:-8px; margin-right: -8px;" :tableData="projectData" type="googleAds" :businessTypeProject="client.businessType[0]"  :projectNameSummary="'Group(Zone) Insights'" selectBoxType="Select Zone(s)" selectionType="single"/> -->
        <MultiHeader v-if="this.tabName === 'ProductInsights'" style="margin-left:-8px; margin-right: -8px;" :tableData="productWiseData" hideSelectBox="google" type="productWise" :businessTypeProject="client.businessType[0]"  :projectNameSummary="'Product Insights'" selectBoxType="Select Product(s)" selectionType="multiple"/>
        <!-- <data-table v-if="this.tabName === 'DailyReport'" title="Daily Report" type="date" :chartData="charts.dataFormatted" /> -->
        <!-- <data-table v-if="this.tabName === 'CampaignReport'" title="Campaign Report" type="campaign" :chartData="campaigns" /> -->
          <!-- :iconCardType="iconType" :businessType="client.businessType" :client="client"/> -->
        <!-- <MultiHeader  :clientName="client.name" v-if="this.tabName === 'ProjectInsights'" style="margin-left:-8px; margin-right: -8px;" :tableData="projectData" type="googleAds" :businessTypeProject="client.businessType[0]"  :projectNameSummary="'Project(s) Summary ' +(formatDate(this.startDateProjectName))+' to '+(formatDate(this.endDateProjectName))  " selectBoxType="Select Project(s)"  channelType="google"/> -->
        <data-table v-if="this.tabName === 'DailyReport'" title="Daily Report"  exportsType='googleDaily' type="date" :chartData="charts.dataFormatted" :businessType="client.businessType[0]"/>
        <data-table v-if="this.tabName === 'CampaignReport'" title="Campaign Report" type="campaign" :chartData="campaigns" exportsType='googleCampaign' :businessType="client.businessType[0]"/>
        <!-- Graph Display -->
        <b-row v-if="this.tabName === 'ZoneInsights'">
            <b-col cols="12">
           <div class="multiHeaderMulti" style="margin-left:2px; margin-right: 2px;"> 
          <MultiHeader  :clientName="client.name"  :zoneWiseType="zoneWiseTypeOptions"
           :tableData="zoneWiseData"   channelType="google"
           type="zoneGoogleAdsWise" selectionType="single" :businessTypeProject="client.businessType[0]" 
           :projectNameSummary="'Group(Zone) Insights'" selectBoxType="Select Zone(s)"
             :zoneWiseDataLoading="isZonewiseDatafetched" @zoneTypeChangedData="getZoneTypeData"
             @handleZoneListChange="handleZoneList" @filterDataZoneWiseType="handledZoneType"/>
        </div>
       </b-col>
         </b-row>
          <!-- <b-row v-if="this.tabName === 'ProductInsights'">
        <b-col cols="12">
        <div class="multiHeaderMulti"  style="margin-left:2px; margin-right: 2px;"> 
          <MultiHeader  :clientName="client.name"  channelType="google"  :productAllProjectWiseType="productWiseTypeOptions" :tableData="productWiseData" type="productGoogleAdsWise" selectionType="multiple" :businessTypeProject="client.businessType[0]" :projectNameSummary="'Product Insights'" selectBoxType="Select Product(s)" :productWiseDataLoading="isProductDatafetched"  @handleZoneListProjectChange="handleProductProjectList"/>
        </div>
        </b-col>
      </b-row> -->
      <div v-if="this.tabName === 'ProjectInsights'">
        <div v-if="
          client &&
          client.accessConfiguration &&
          client.accessConfiguration.projects.enabled
        ">
          <b-row >
            <b-col cols="12">
              <b-card>
                <div v-if="this.isProjectWiseIsLoad" class="spinnerCentered">
                  <multiChannelSpinner />
                </div>
                <div v-else>
                  <MultiHeaderProjectChannel :projectName="'Project(s) Summary ' +
                    this.formatDateDisplay(this.startDateProjectName || this.selectedStartDate) +
                    ' to ' +
                    this.formatDateDisplay(this.endDateProjectName || this.selectedEndDate)
                    " v-if="
                    client &&
                    client.accessConfiguration &&
                    client.accessConfiguration.projects.enabled
                  " type="overallChannelProjectData" :businessTypeProject="client.businessType[0]"
                    :tableData="projectWiseData" :clientName="client.name"/>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
        <div v-if="!isReportDataLoading && this.tabName === 'Graphs'">
          <graph-display @deleteGraph="deleteGraph" :index="index" :chartData="graph" :data="charts.data"
            v-for="(graph, index) in graphs" :key="index" :businessType="client.businessType[0]"/>
        </div>
        <!-- Graph Display -->

        <!-- Graph Selector -->
        <graph-selector @graphAdded="graphAdded" :metrics="charts.data" v-if="this.tabName === 'Graphs'" :businessType="client.businessType[0]"/>
        <!-- Graph Selector -->

        <filters v-if="client._id" :clientID="client._id" @filterReportData="filterReportData" />
      </b-overlay>
    </div>
    <transition name="modal">
      <div class="modal-mask" v-if="openScheduleModal">
        <TriggerModalAll
          @close="closeScheduleClientPopUp"
          :clientID="client._id"
          @closeScheduleClientPopUp="closeScheduleClientPopUp"
          :scheduleModal="this.openScheduleModal"
        />
      </div>
    </transition>
    <!-- DASHBOARD -->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardTitle,
  BFormCheckbox,
  BBreadcrumb,
  BBreadcrumbItem,
  BAlert,
  BLink,
  BAvatar,
  BCard,
  BButton,
  BOverlay,
  BTabs,
  BTab
} from "bootstrap-vue";
import TriggerModalAll from "@/components/client/TriggerModalAll.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import MultiHeaderProjectChannel from "@/views/clientFeature/MultiheaderProjectChannel.vue";
import OverViewCard from "./OverViewCard.vue";
import DataTable from "./DataTable.vue";
import GraphSelector from "./GraphSelector.vue";
import GraphDisplay from "./GraphDisplay.vue";
import DateSelector from "./DateSelector.vue";
import Filters from "./Filters.vue";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
import MultiHeader from "@/views/clientFeature/MultiHeader.vue";

export default {
  data() {
    return {
      selectedStartDate:"",
      selectedEndDate:"",
      productProjectNamesChange:[],
      productProjectIntialNamesChange:[],
      productWiseTypeOptions:[],
      creativeOptions:[],
      intiallOptionsForGroup:[],
      optionsLength:0,
      zoneWiseLength:0,
      selectedZoneTypeForFilter:[],
      selectedProductType:"all",
      adRunningError:"",
      zoneWiseTypeOptions:null,
      zoneWiseTypeOptionsFlag:false,
      openScheduleModal:false,
      isProductDatafetched:false,
      isZonewiseDatafetched:false,
      productWiseData:[],
      zoneWiseData:[],
      startDateProjectName:"",
      endDateProjectName:"",
      tabName: "",
      projectsTab: false,
      isProjectDataLoad:false,
      client: {
        status: false,
        integrations: {
          googleAds: null,
        },
        googleAdsCustomerID: null,
      },
      errorMessage: "",
      iconType: "",
      breadcrumbs: [
        {
          text: "Overview",
          to: "/dashboard",
        },
      ],
      isReportDataLoading: true,
      isClientLoad: true,
      campaigns: [],
      charts: {
        data: [],
        totalData: {},
      },
      graphs: [],
      currentDateRange: "",
      chartData: [],
      projectData:[],
      selectedZoneTypeForFilter:[],
      zoneListChange:[],
      projectWiseData:[],
      isProjectWiseIsLoad:false
    };
  },
  props: {
    buttonSize: {
      type: String
    }},
  components: {
    BRow,
    BCol,
    BCardTitle,
    BFormCheckbox,
    BBreadcrumb,
    BBreadcrumbItem,
    BAlert,
    BLink,
    BAvatar,
    BCard,
    BButton,
    OverViewCard,
    DataTable,
    BOverlay,
    GraphSelector,
    GraphDisplay,
    DateSelector,
    Filters,
    MultiHeader,
    BTabs,
    BTab,
    TriggerModalAll,
    MultiHeaderProjectChannel,
    multiChannelSpinner
  },
  methods: {
    handledZoneType(data){
      this.selectedZoneTypeForFilter = data
    },
    handleProductProjectList(data){
      this.productProjectNamesChange =  data
    },
    getZoneTypeData(data){
      this.selectedProductType = data
    },
    handleZoneList(data){
      this.zoneListChange =  data
    },
    fetchProjectWiseData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      // this.showSummaryDashboardLoading = true;
      this.isProjectWiseIsLoad = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (campaignType) {
        additionalQueryParams =
          additionalQueryParams + `&campaign-type=${campaignType}`;
      }
      if (campaigns) {
        additionalQueryParams =
          additionalQueryParams + `&campaigns=${campaigns}`;
      }
      if (adGroups) {
        additionalQueryParams =
          additionalQueryParams + `&ad-groups=${adGroups}`;
      }
      if (!this.selectedStartDate && !this.selectedEndDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
   
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (this.selectedStartDate && this.selectedEndDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${this.selectedStartDate}&endDate=${this.selectedEndDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = this.selectedStartDate
        this.endDateProjectName = this.selectedEndDate
      }
   
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/overall-project-insights?clientID=${this.client._id}&channel=googleAds&${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.projectWiseData = response.data;
          this.isProjectWiseIsLoad = false;
        })
        .catch(error => {
          this.isProjectWiseIsLoad = false;
          console.log(error);
          // this.showSummaryDashboardLoading = false;
        });
    },
    getFetchOptions(){
      this.intiallOptionsForGroup = []
      this.zoneWiseTypeOptionsFlag = true
      axios.get(`${process.env.VUE_APP_SERVER_URL}/api/projects/options?clientID=${this.client._id}`, {
        headers: {
          Authorization: `Bearer ${getUserToken()}`,
          "Content-type": "application/json",
        },
      })
        .then((response) => {
          this.zoneWiseTypeOptionsFlag = false
          this.zoneWiseTypeOptions = response && response.data
          const combinedArray = Object.values(this.zoneWiseTypeOptions.projectNames).flat();
          this.productProjectIntialNamesChange = [...combinedArray];
          this.productWiseTypeOptions = [...combinedArray]
          this.creativeOptions =  [...combinedArray];
          this.intiallOptionsForGroup = [...response && response.data && response.data.groups]
          this.optionsLength = Object.keys(response && response.data).length
        })
        .catch((error) => {
          this.zoneWiseTypeOptionsFlag = false
          console.log(error);
      });
    },
    sendSchedule() {
      this.clientID = this.client._id;
      this.openScheduleModal = true;
    },
    formatDateDisplay(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    fetchGoogleAdsWiseZonewiseData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) 
    {
      
      // this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.zoneWiseData = []
      this.isZonewiseDatafetched = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (campaignType) {
        additionalQueryParams =
          additionalQueryParams + `&campaign-type=${campaignType}`;
      }
      if (campaigns) {
        additionalQueryParams =
          additionalQueryParams + `&campaigns=${campaigns}`;
      }
      if (adGroups) {
        additionalQueryParams =
          additionalQueryParams + `&ad-groups=${adGroups}`;
      }
      if (!this.selectedStartDate && !this.selectedEndDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
   
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (this.selectedStartDate && this.selectedEndDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${this.selectedStartDate}&endDate=${this.selectedEndDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }
      let zoneListSeletion =  this.zoneListChange.join(',') == "" ? this.creativeOptions : this.zoneListChange.join(',') 
      let zoneData  = this.selectedZoneTypeForFilter.length === 0 ? this.intiallOptionsForGroup : this.selectedZoneTypeForFilter
      zoneListSeletion = encodeURIComponent(zoneListSeletion)
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/group/insights/?clientID=${this.client._id}${additionalQueryParams}&channel=googleAds&tag=Zone&product=${this.selectedProductType}&projects=${zoneListSeletion}&groupName=${zoneData}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.zoneWiseData = response && response.data
          this.isZonewiseDatafetched = false;
        })
        .catch(error => {
          console.log(error);
          this.isZonewiseDatafetched = false;
        });
    },
    fetchGoogleAdsProjectProductWiseData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) 
    {
      this.zoneWiseData = []
      this.isProductDatafetched = true;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (campaignType) {
        additionalQueryParams =
          additionalQueryParams + `&campaign-type=${campaignType}`;
      }
      if (campaigns) {
        additionalQueryParams =
          additionalQueryParams + `&campaigns=${campaigns}`;
      }
      if (adGroups) {
        additionalQueryParams =
          additionalQueryParams + `&ad-groups=${adGroups}`;
      }
      if (!this.selectedStartDate && !this.selectedEndDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
   
        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate
      }
      if (this.selectedStartDate && this.selectedEndDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${this.selectedStartDate}&endDate=${this.selectedEndDate}`;
          this.startDateProjectName = " "
        this.endDateProjectName = " "
          this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }
      let projectNames = this.productProjectNamesChange.length === 0 ? this.productProjectIntialNamesChange : this.productProjectNamesChange

projectNames = encodeURIComponent(projectNames)
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/products/insights/?clientID=${this.client._id}${additionalQueryParams}&channel=googleAds&projects=${projectNames}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
          }
        )
        .then(response => {
          this.productWiseData = response && response.data
          this.isProductDatafetched = false;
        })
        .catch(error => {
          console.log(error);
          this.isProductDatafetched = false;
        });
    },
    closeScheduleClientPopUp() {
      this.openScheduleModal = false;
    },
    changeTab(name) {
      this.tabName = name;
      if (this.tabName == 'ZoneInsights') {
        this.fetchGoogleAdsWiseZonewiseData()
      }
      if(this.tabName === "ProductInsights"){
        this.getFetchOptions();
        this.fetchGoogleAdsProjectProductWiseData()
      }
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
    getClient() {
      const slug = this.$route.params.slug;
      this.isClientLoad = true
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.client = { ...response.data.client, status: true };
          this.graphs = response.data.client.googleAdsGraphs
            ? response.data.client.googleAdsGraphs
            : [];
          this.breadcrumbs.push({
            text: response.data.client.name,
            to:`/channels-dashboard/${response.data.client.slug}`,
            active: false,
          });
          this.breadcrumbs.push({
            text: "Google Ads Dashboard",
            to: `/client/${response.data.client.slug}/dashboard/google-ads`,
            active: true,
          });

          if (
            response.data.client &&
            response.data.client.integrations.googleAds &&
            response.data.client.googleAdsCustomerID
          ) {
            this.getFetchOptions();
            this.getReportData();
            this.fetchProjectWiseData()
            this.fetchAllProjects();
            setTimeout(()=>{
              this.fetchGoogleAdsProjectProductWiseData();
              this.fetchGoogleAdsWiseZonewiseData();
            },500)
          }
          this.isClientLoad = false
        })
        .catch((error) => {
          console.log(error.response.data);
          this.client.status = false;
          this.errorMessage = error.response.data.error;
          this.isClientLoad = false
        });
    },
    getReportData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.selectedStartDate = startDate
      this.selectedEndDate = endDate
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!startDate && !endDate) {
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];
        this.startDateProjectName = formattedStartDate
        this.endDateProjectName = formattedEndDate 
        if (this.client._id === '661674af15a991dc751dda34') {
          additionalQueryParams =
          additionalQueryParams +
          `&start-date=${this.startDateProjectName}&end-date=${this.endDateProjectName}`;
        }
      }
      if (campaignType) {
        additionalQueryParams =
          additionalQueryParams + `&campaign-type=${campaignType}`;
      }
      if (campaigns) {
        additionalQueryParams =
          additionalQueryParams + `&campaigns=${campaigns}`;
      }
      if (adGroups) {
        additionalQueryParams =
          additionalQueryParams + `&ad-groups=${adGroups}`;
      }
      if (startDate && endDate) {
        
        additionalQueryParams =
          additionalQueryParams +
          `&start-date=${startDate}&end-date=${endDate}`;
        this.startDateProjectName = " "
        this.endDateProjectName = " "
        this.startDateProjectName = startDate
        this.endDateProjectName = endDate
      }
      this.campaigns = []
      this.chartData = []
      this.charts =  {}
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/google-ads/get-report?date-range=${dateRange}${additionalQueryParams}`,
          {
            clientID: this.client._id,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          
          this.iconType = response.data.currencyCode;
          this.isReportDataLoading = false;
          this.campaigns = response.data?.campaigns || [];
          this.charts = response?.data?.charts || [];
          this.chartData = response.data?.charts?.data;
        })
        .catch((error) => {
          this.campaigns = []
          this.chartData = []
          this.charts =  {}
          this.adRunningError = ""
          this.adRunningError = error.response.data.error
          let errorText =
            error.response.data && error.response.data.error
              ? error.response.data.error
              : "Unknown error occured";
          this.isReportDataLoading = false;
          this.showToast("Error", errorText, "danger");
        });
    },
    formatDate(inputDate) {
    const [year, month, day] = inputDate.split('-');
    const updatedDate = `${day}-${month}-${year}`;
    return updatedDate;
   },
    fetchAllProjects(dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null) {
        this.isProjectDataLoad = true
        this.projectData = []
        let additionalQueryParams = ``;
        let url = ''
        if (!dateRange) {
          let today = new Date();
          let yesterday = new Date(today); 
          let startDate = new Date(yesterday);
          if(this.client._id === '661674af15a991dc751dda34'){
          yesterday.setDate(today.getDate() - 0); 
          startDate.setDate(yesterday.getDate() - 29);
          }else{
          yesterday.setDate(today.getDate() - 1); 
          startDate.setDate(yesterday.getDate() - 6);
          }
          const formattedStartDate = startDate.toISOString().slice(0, 10);
          const formattedEndDate = yesterday.toISOString().slice(0, 10);
          additionalQueryParams += `&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
          url = `${process.env.VUE_APP_SERVER_URL}/api/google-ads/projects/date-wise-insights/?clientID=${this.client._id}${additionalQueryParams}`
      }
      this.currentDateRange = dateRange;
      let conditionalQueryParams = ``;
      if (startDate && endDate) {
        conditionalQueryParams =
          conditionalQueryParams +
          `&startDate=${startDate}&endDate=${endDate}`;
          url = `${process.env.VUE_APP_SERVER_URL}/api/google-ads/projects/date-wise-insights/?clientID=${this.client._id}${conditionalQueryParams}`
      }
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.projectData = response.data
          if (this.projectData.length) {
            this.projectsTab = true
          }
          this.isProjectDataLoad = false
          // this.projectList = response.data.projects
        })
        .catch((error) => {
          this.projectsTab = false
          this.isProjectDataLoad = false
          console.log(error.response.data);
        });
    },
    updateGraph(graphs) {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/google-ads/add-graph`,
          { clientID: this.client._id, graphs },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.showToast("Updated", "Graph updated successfully!", "success");
        })
        .catch((error) => {
          console.log(error.response);
          this.showToast("Error", "Error updating the graph", "danger");
        });
    },
    graphAdded(graphData) {
      this.graphs.push(graphData);
      this.updateGraph(this.graphs);
    },
    deleteGraph(index) {
      this.graphs.splice(index, 1);
      this.updateGraph(this.graphs);
    },
    filterReportData(filter) {
      this.getReportData(
        this.currentDateRange,
        filter.selectedCampaignType,
        filter.selectedCampaigns,
        filter.selectedAdGroups
      );
    },
  },
  filters: {
    date(val) {
      return val ? moment(val).format("DD-MM-YYYY") : "";
    }
  },
  watch: {
    selectedZoneTypeForFilter(newVal, oldVal) {
      this.selectedZoneTypeForFilter = newVal
      this.zoneListChange = []
      this.fetchGoogleAdsWiseZonewiseData()
    },
    selectedProductType(newVal, oldVal){
      this.selectedProductType = newVal
      this.fetchGoogleAdsWiseZonewiseData()
    },
    zoneListChange(newVal, oldVal) {
      this.zoneListChange = newVal
      this.fetchGoogleAdsWiseZonewiseData()
    },
    productProjectNamesChange(newVal, oldVal){
      this.productProjectNamesChange = newVal
      this.fetchGoogleAdsProjectProductWiseData()
    },
  },
  mounted() {
    this.tabName = "Graphs";
    this.getClient();
  },
};
</script>

<style lang="scss">
// @import "@core/scss/vue/libs/vue-select.scss";

.screenAlignmentFacbook {
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  .card-body {
    padding: 0.5rem !important;
  }
}

.breadcrumb-container a {
  color: antiquewhite !important;
}

.dashboard-overlay .rounded-sm {
  inset: -20px !important
}
</style>