<template>
  <b-card no-body class="cardStaticsGroup">
    <b-card-body>
      <!-- Debug data for quick checks -->
      <!-- Spinner during data fetching -->
      <div v-if="isDataFetching" class="spinnerCentered">
        <multiChannelSpinner />
      </div>
      <!-- Message for no data -->
      <b-row v-else-if="statisticsItemsData.length === 0" class="iconCentered">
        <b-col cols="12">
          <div>
            <h5>No Data Found.</h5>
            <br />
            <span style="color: brown;">*{{ adRunningError }}</span>
          </div>
        </b-col>
      </b-row>
      <!-- Render statistics cards -->
      <b-row v-else>
        <b-col
          v-for="item in statisticsItemsData"
          :key="item.subtitle"
          class="mb-2 mb-md-0"
          :class="item.customClass"
          cols="12" md="4" lg="2"
        >
          <div class="text-center mb-1">
            <b-avatar size="48" :variant="item.color">
              <span v-if="item.icon === 'currency-rupee'" style="font-size: 22px;">
                ₹
              </span>
              <span v-else>
                <feather-icon size="24" :icon="item.icon" />
              </span>
            </b-avatar>
          </div>
          <b-media class="text-center" no-body>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">{{ item.title }}</h4>
              <b-card-text class="font-small-3 mb-0">{{ item.subtitle }}</b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>


<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BAvatar,
  BMediaBody,
  BCardText
} from "bootstrap-vue";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import millify from "millify";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaBody,
    multiChannelSpinner,
    BCardText
  },
  props: {
    adRunningError: {
      type: String,
      default: "",
    },
    summaryData: {
      type: Object,
      default: () => ({
        allLeads: 0,
        qualityLeads: 0,
        siteVisits: 0,
        sales: 0,
        spends:0,
        costPerQualityLeads:0
      }),
    },
    isDataFetching: {
      type: Boolean,
      default: false,
    },
    businessType: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    statisticsItemsData() {
      const statisticsItems = [];
      const summaryData = this.summaryData;

      if (!summaryData || typeof summaryData !== "object") {
        console.error("Invalid or empty summaryData:", summaryData);
        return statisticsItems;
      }

      const order = ["allLeads", "qualityLeads", "siteVisits", "sales","spends","costPerQualityLeads"];

      for (const key of order) {
        const value = summaryData[key];
        if (value !== undefined) {
          statisticsItems.push(this.getFormattedText(value, key));
        }
      }

      return statisticsItems;
    },
  },
  methods: {
    getFormattedText(value, key) {
      const formattedValue = millify(value);
      switch (key) {
        case "allLeads":
          return {
            icon: "MousePointerIcon",
            color: "light-warning",
            title: formattedValue,
            subtitle: "All Leads",
            customClass: "mb-2 mb-xl-0",
          };
        case "qualityLeads":
          return {
            icon: "CheckCircleIcon",
            color: "light-success",
            title: formattedValue,
            subtitle: "Quality Leads",
            customClass: "mb-2 mb-xl-0",
          };
        case "siteVisits":
          return {
            icon: "EyeIcon",
            color: "light-info",
            title: formattedValue,
            subtitle: "Site Visits",
            customClass: "mb-2 mb-xl-0",
          };
        case "sales":
          return {
            icon: "CheckCircleIcon",
            color: "light-info",
            title: formattedValue,
            subtitle: "Sales",
            customClass: "mb-2 mb-xl-0",
          };
        case "spends":
          return {
            icon: "DollarSignIcon",
            color: "light-danger",
            title: formattedValue,
            subtitle: "Spends",
            customClass: "mb-2 mb-xl-0",
          };
          case "costPerQualityLeads":
          return {
            icon: "MousePointerIcon",
            color: "light-danger",
            title: formattedValue,
            subtitle: "CPQL",
            customClass: "mb-2 mb-xl-0",
          };
        default:
          return {
            icon: "AlertTriangleIcon",
            color: "light-secondary",
            title: formattedValue,
            subtitle: key,
            customClass: "mb-2 mb-xl-0",
          };
      }
    },
  },
};
</script>

<style scoped>
.iconCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
}
.b-col {
  display: flex;
}
@media (max-width: 768px) {
  .b-col {
    display: block;
    margin-bottom: 15px;
  }
  .cardStaticsGroup{
    width: 70%;
    left: auto;
    right: auto;
  }
}
</style>
