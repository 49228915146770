<template>
    <div class="creative-analysis-main-container">
        <div v-if="isClientsFetching" class="spinnerCentered">
            <Spinner />
        </div>
        <div v-else>
            <b-row>
                <b-col cols="12">
                    <b-card class="creative-filters">
                        <div  class="creative-flex">
                            <div>
                                <div class="creatives-range">
                                    <date-range-picker ref="picker" opens="right"
                                        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }" :showWeekNumbers="false"
                                        :showDropdowns="true" :autoApply="true" v-model="dateRange"
                                        @update="updateValues" :dateFormat="dateFormat"
                                        control-container-class="picker-controller" :ranges="dateRanges"
                                        :auto-apply="false" :readonly="false" :maxDate="new Date(new Date().setDate(new Date().getDate() - 1))"
                                            >
                                        <template v-slot:input="picker">
                                            <feather-icon icon="CalendarIcon" size="13" class="mr-1"></feather-icon>
                                            <span style="font-size: 12px;">
                                                Showing Activity for
                                                {{ formatDateDisplay(picker.startDate) }} -
                                                {{ formatDateDisplay(picker.endDate) }}</span>
                                        </template>
                                    </date-range-picker>
                                </div>
                            </div>
                            <div class="select-style">
                                <div>
                                    <div class="select-creative-container">
                                        <span class="icon-format">
                                        <feather-icon style="margin-top: -2px;" icon="BriefcaseIcon" size="15"
                                            color="#704c94" />
                                    </span>
                                    <select style="margin-top: 1px; color:black; font-weight:500" v-model="selectedClient"
                                        class="client-creative-analysis" @change="getUpdateCreatives">
                                        <option value="">Select Client</option>
                                        <option v-for="onetype in clients" :value="onetype" class="form-control"
                                            id="lg_type" name="lg_type" v-bind:key="onetype._id">
                                            {{ onetype.name }}
                                        </option>
                                    </select>
                                    </div>
                                </div>
                                <div>
                                    <b-dropdown class="drop-down-query-selector" text="Select Option"
                                        variant="outline-secondary">
                                        <template #button-content>
                                            <img style="width: 17px; height:17px; margin-bottom:2px"
                                                :src="selectedDisplayType.img" :alt="selectedDisplayType.name"
                                                class="img-fluid" />
                                            <span style="width: 135px !important;color:black; font-weight:500"> {{ selectedDisplayType.name }}
                                            </span>
                                        </template>
                                        <b-dropdown-item v-for="(item, index) in dropdownItems" :key="index"
                                            @click="selectItem(item)">
                                            <img style="width: 17px; height:17px ; margin-bottom:2px" :src="item.img"
                                                :alt="item.name" class="img-fluid" /> {{ item.name }}
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-card class="screenAlignmentCreative">
                        <b-row>
                            <b-col cols="12">
                                <b-tabs fill class="multichanneldashbpa">
                                    <b-tab title="Overview" active @click="changeTab('Overview')"></b-tab>
                                    <b-tab title="Ads Lists" @click="changeTab('AdsLists')"></b-tab>
                                </b-tabs>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col cols="12" v-if="this.creativeAnalysisTab === 'Overview'">
                    <b-card>
                        <div style="display: flex; justify-content:space-between">
                            <div>
                                <b-card-title>
                                    <h3 class="app-title mdsm">{{ this.selectedDisplayType.name }} Creative Analysis
                                    </h3>
                                </b-card-title>
                            </div>
                            <!-- <div>
                                <b-button @click="exportsCreativesData(creativesAnalysisData)">Export </b-button>
                            </div> -->
                        </div>
                        <!-- <b-row v-if="this.tabMultiChannelName === 'CreativeAnalysisTable'"> -->
                        <b-col cols="12">
                            <!-- <b-card> -->
                            <CreativeAnalysisTable
                                style="margin-top: -25px !important; margin-left:-5px !important; margin-right:-5px !important"
                                :isAnalysisDataFetched="isCreativesAnalysisData" TableType="creativeAnalysisData"
                                :PaidData="creativesAnalysisData" :ChannelType="this.selectedDisplayType.name"
                                :selectedBusinessType="this.selectedClient  && this.selectedClient.businessType && this.selectedClient.businessType[0]" />
                            <b-row v-if="this.creativesAnalysisData && this.creativesAnalysisData.length > 0"
                                style="margin-bottom: -10px;">
                                <b-col cols="9"></b-col>
                                <b-col cols="3">
                                    <div class="d-flex justify-content-between align-items-center" style="float:right">
                                        <b-button variant="link" :disabled="creativeAnalysisPageIndex <= 1"
                                            @click="prevCreativePage">
                                            <feather-icon icon="ArrowLeftCircleIcon" size="30" />
                                        </b-button>
                                        <span>Page {{ creativeAnalysisPageIndex  + 1}} of {{ creativeAnalysisPageSize + 1
                                            }}</span>
                                        <b-button variant="link"
                                            :disabled="creativeAnalysisPageIndex >= creativeAnalysisPageSize"
                                            @click="nextCreativePage">
                                            <feather-icon icon="ArrowRightCircleIcon" size="30" />
                                        </b-button>
                                    </div>
                                </b-col>
                            </b-row>
                            <!-- </b-card> -->
                        </b-col>
                        <!-- </b-row> -->
                    </b-card>
                </b-col>
                <b-col cols="12" v-if="this.creativeAnalysisTab === 'AdsLists'">
                    <b-card>
                        <div style="display: flex; justify-content:space-between">
                            <div>
                                <b-card-title>
                                    <h3 class="app-title mdsm">{{ this.selectedDisplayType.name }} Ads List</h3>
                                </b-card-title>
                            </div>
                        </div>
                        <div v-if="isCreativeTopDataIsFetching" class="spinnerCentered">
                            <multiChannelSpinner />
                        </div>
                        <div v-else-if="this.creativeTopData && this.creativeTopData.length === 0">
                            <div
                                style="text-align: center; justify-content: center; align-items:  center; margin-left: 0%; right:auto ; margin-top:10% ; height: 20vh;">
                                <p class="text-primary">No Data Found For Selected Client.</p>
                            </div>
                        </div>
                        <div v-else>
                            <vue-good-table class="custom-data-table-creative mt-1" :rows="adsListsData"
                                :columns="targetValTable" :pagination-options="{
                                    enabled: true,
                                    perPage: pageAdsLength
                                }">
                                <!-- pagination -->
                                <template slot="pagination-bottom" slot-scope="props">
                                    <div class="d-flex justify-content-between flex-wrap">
                                        <div class="d-flex align-items-center mb-0 mt-1"></div>
                                        <div>
                                            <b-pagination :value="1" :total-rows="pageLengthALL"
                                                :per-page="pageAdsLength" first-number last-number align="right"
                                                prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                                                v-model="currentPage"
                                                @input="(value) => props.pageChanged({ currentPage: value })">
                                                <template #prev-text>
                                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                                </template>
                                                <template #next-text>
                                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                                </template>
                                            </b-pagination>
                                        </div>
                                    </div>
                                </template>
                            </vue-good-table>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BTab,
    BTabs,
    BCardTitle,
    BButton,
    BModal,
    BFormInput,
    BIcon,
    BRow,
    BCol,
    BFormSelect,
    BPagination,
    BCardText,
    BSpinner,
    BDropdown,
    BDropdownItem
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import CreativeAnalysisTable from '../Client/CreativeAnalysisTable.vue'
import Spinner from "@/components/client/Spinner.vue";
import { getUserToken } from "@/auth/utils";
import axios from "axios";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import img1 from '../../assets/images/logo/ads-logo.png';
import img2 from '../../assets/images/logo/meta.png';
import {sortFuntionNumericVal, sortFuntionStringintoNumericVal } from '@/components/client/SortingFunction';
export default {
    data() {
        return {
            pageAdsLength: 8,
            currentPage: 1,
            isCreativeTopDataIsFetching: false,
            isCreativeTopDataIsFetchingMeta: false,
            creativeTopData: [],
            creativeAnalysisPageIndex: 0,
            creativeAnalysisPageSize: 0,
            isCreativesAnalysisData: false,
            creativesAnalysisData: [],
            selectedClient: {},
            clients: [],
            isClientsFetching: false,
            creativeDisplayType: "Google-Ads",
            creativeAnalysisTab: "Overview",
            creativeChannelTypes: ["Google-Ads", "Meta"],
            startDateForAll: "",
            endDateForAll: "",
            selected: {
                label: "Last 7 days",
                code: "LAST_7_DAYS",
            },
            dateRange: {
                endDate: moment()
                    .subtract(1, "days")
                    .format("YYYY-MM-DD"),
                startDate: moment()
                    .subtract(8, "days")
                    .format("YYYY-MM-DD"),
            },
            selectedDisplayType: { img: img1, name: 'Google-Ads' },
            dropdownItems: [
                { img: img1, name: 'Google-Ads' },
                { img: img2, name: 'Meta' }
            ]
        }
    },
    components: {
        BCard,
        BTab,
        BTabs,
        BCardTitle,
        BButton,
        BModal,
        BFormInput,
        BIcon,
        BRow,
        BCol,
        multiChannelSpinner,
        BFormSelect,
        ToastificationContent,
        BCardText,
        BSpinner,
        Spinner,
        DateRangePicker,
        CreativeAnalysisTable,
        BDropdown,
        BDropdownItem,
        VueGoodTable,
        BPagination
    },
    methods: {
        sortFn(a,b){
          return sortFuntionNumericVal(a,b);
        },
        sortStringtoNumFn(a,b){
         return sortFuntionStringintoNumericVal(a,b);
        },
        selectItem(item) {
            this.creativeAnalysisPageIndex = 0
            this.selectedDisplayType = item;
            if (this.creativeAnalysisTab === 'AdsLists') {
                this.getCreativesTopPerfomingDataData()
            }
            if (this.creativeAnalysisTab === 'Overview') {
                if (this.selectedDisplayType.name === 'Meta') {
                    this.getCreativesAnalysisTableDataMeta();
                }
                if (this.selectedDisplayType.name === 'Google-Ads') {
                    this.getCreativesAnalysisTableDataGoogle();
                }
            }
        },
        creativeDisplayTypeChange() {
            this.creativeAnalysisPageIndex = 0
            if (this.creativeAnalysisTab === 'AdsLists') {
                this.getCreativesTopPerfomingDataData()
            }
            if (this.creativeAnalysisTab === 'Overview') {
                // this.getCreativesAnalysisTableData()
                if (this.selectedDisplayType.name === 'Meta') {
                    this.getCreativesAnalysisTableDataMeta();
                }
                if (this.selectedDisplayType.name === 'Google-Ads') {
                    this.getCreativesAnalysisTableDataGoogle();
                }
            }

        },
        getUpdateCreatives() {
            if (this.creativeAnalysisTab === 'AdsLists') {
                this.getCreativesTopPerfomingDataData()
            }
            if (this.creativeAnalysisTab === 'Overview') {
                // this.getCreativesAnalysisTableData()
                if (this.selectedDisplayType.name === 'Meta') {
                    this.getCreativesAnalysisTableDataMeta();
                }
                if (this.selectedDisplayType.name === 'Google-Ads') {
                    this.getCreativesAnalysisTableDataGoogle();
                }
            }
        },
        nextCreativePage() {
            if (this.creativeAnalysisPageIndex < this.creativeAnalysisPageSize) {
                this.creativeAnalysisPageIndex++;
               if (this.selectedDisplayType.name === 'Meta') {
                    this.getCreativesAnalysisTableDataMeta();
                }
                if (this.selectedDisplayType.name === 'Google-Ads') {
                    this.getCreativesAnalysisTableDataGoogle();
                }
            }
        },
        prevCreativePage() {
            if (this.creativeAnalysisPageIndex > 1) {
                this.creativeAnalysisPageIndex--;
               if (this.selectedDisplayType.name === 'Meta') {
                    this.getCreativesAnalysisTableDataMeta();
                }
                if (this.selectedDisplayType.name === 'Google-Ads') {
                    this.getCreativesAnalysisTableDataGoogle();
                }
            }
        },
        getCreativesTopPerfomingDataData(
            dateRange,
            campaignType = null,
            campaigns = null,
            adGroups = null,
            startDate = null,
            endDate = null
        ) {
            this.isCreativeTopDataIsFetching = true;
            this.creativeTopData = [];
            if (!dateRange) {
                dateRange = "LAST_7_DAYS";
            }
            this.currentDateRange = dateRange;
            this.currentDateRange = dateRange;
            let additionalQueryParams = ``;
            if (!this.endDateForAll && !this.startDateForAll) {
                const today = new Date();
                const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
                const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
                const formattedStartDate = prevWeek.toISOString().split("T")[0];
                const formattedEndDate = yesterday.toISOString().split("T")[0];

                additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
            }
            if (this.startDateForAll && this.endDateForAll) {
                additionalQueryParams =
                    additionalQueryParams +
                    `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
            }
            let channelType = this.selectedDisplayType.name === 'Google-Ads' ? 'google' : 'meta'
            axios
                .get(
                    `${process.env.VUE_APP_SERVER_URL}/api/creatives/all?clientID=${this.selectedClient._id}&channel=${channelType}${additionalQueryParams}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getUserToken()}`,
                        },
                    }
                )
                .then((response) => {
                    if (this.selectedDisplayType.name === 'Meta') {
                        this.creativeTopData = response && response.data && response.data.metaAds.map((i, index) => {
                            return i.formatedReport;
                        });
                    }
                    if (this.selectedDisplayType.name === 'Google-Ads') {
                        this.creativeTopData = response && response.data && response.data.googleAds.map((i, index) => {
                            return i.formatedReport;
                        });
                    }
                    this.isCreativeTopDataIsFetching = false;
                })
                .catch((error) => {
                    this.creativeTopData = [];
                    this.isCreativeTopDataIsFetching = false;
                });
        },
        //Meta
        getCreativesAnalysisTableDataMeta(
            dateRange,
            campaignType = null,
            campaigns = null,
            adGroups = null,
            startDate = null,
            endDate = null
        ) {
            this.isCreativesAnalysisData = true;
            this.creativesAnalysisData = [];
            if (!dateRange) {
                dateRange = "LAST_7_DAYS";
            }
            this.currentDateRange = dateRange;
            this.currentDateRange = dateRange;
            let additionalQueryParams = ``;
            if (!this.endDateForAll && !this.startDateForAll) {
                const today = new Date();
                const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
                const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
                const formattedStartDate = prevWeek.toISOString().split("T")[0];
                const formattedEndDate = yesterday.toISOString().split("T")[0];

                additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
            }
            if (this.startDateForAll && this.endDateForAll) {
                additionalQueryParams =
                    additionalQueryParams +
                    `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
            }
            let url = "/api/creatives/meta-insights"
            axios
                .get(
                    `${process.env.VUE_APP_SERVER_URL}${url}?clientID=${this.selectedClient._id}${additionalQueryParams}&pageSize=10&pageIndex=${this.creativeAnalysisPageIndex}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getUserToken()}`,
                        },
                    }
                )
                .then((response) => {
                    this.creativesAnalysisData = response && response.data && response.data.campaign;
                    this.creativeAnalysisPageSize = Number(response && response.data && response.data.totalPages);
                    this.creativeAnalysisPageIndex = Number(response && response.data && response.data.pageIndex);
                    this.isCreativesAnalysisData = false;
                })
                .catch((error) => {
                    this.creativesAnalysisData = [];
                    console.log(error);
                    this.isCreativesAnalysisData = false;
                });
        },
        getCreativesAnalysisTableDataGoogle(
            dateRange,
            campaignType = null,
            campaigns = null,
            adGroups = null,
            startDate = null,
            endDate = null
        ) {
            this.isCreativesAnalysisData = true;
            this.creativesAnalysisData = [];
            if (!dateRange) {
                dateRange = "LAST_7_DAYS";
            }
            this.currentDateRange = dateRange;
            this.currentDateRange = dateRange;
            let additionalQueryParams = ``;
            if (!this.endDateForAll && !this.startDateForAll) {
                const today = new Date();
                const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
                const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
                const formattedStartDate = prevWeek.toISOString().split("T")[0];
                const formattedEndDate = yesterday.toISOString().split("T")[0];

                additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
            }
            if (this.startDateForAll && this.endDateForAll) {
                additionalQueryParams =
                    additionalQueryParams +
                    `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
            }
            let url = "/api/creatives/google-insights"
             
            axios
                .get(
                    `${process.env.VUE_APP_SERVER_URL}${url}?clientID=${this.selectedClient._id}${additionalQueryParams}&pageSize=10&pageIndex=${this.creativeAnalysisPageIndex}`,
                    {
                        headers: {
                            Authorization: `Bearer ${getUserToken()}`,
                        },
                    }
                )
                .then((response) => {
                    this.creativesAnalysisData = response && response.data && response.data.campaign;
                    this.creativeAnalysisPageSize = Number(response && response.data && response.data.totalPages);
                    this.creativeAnalysisPageIndex = Number(response && response.data && response.data.pageIndex);
                    this.isCreativesAnalysisData = false;
                })
                .catch((error) => {
                    this.creativesAnalysisData = [];
                    console.log(error);
                    this.isCreativesAnalysisData = false;
                });
        },
        formatDateDisplay(date) {
            return moment(date).format("DD-MM-YYYY");
        },

        updateValues(values) {
            let startDate = moment(values.startDate).format("YYYY-MM-DD");
            let endDate = moment(values.endDate).format("YYYY-MM-DD");
            this.startDateForAll = startDate;
            this.endDateForAll = endDate;
            // this.getCreativesAnalysisTableData();
            if (this.selectedDisplayType.name === 'Meta') {
                    this.getCreativesAnalysisTableDataMeta();
                }
            if (this.selectedDisplayType.name === 'Google-Ads') {
                    this.getCreativesAnalysisTableDataGoogle();
            }
            if (this.creativeAnalysisTab === 'AdsLists') {
                this.getCreativesTopPerfomingDataData();
            }
        },
        dateChanged(val) {
            this.$emit("fetchNewData", val.code);
        },
        dateFormat(classes, date) {
            let yesterday = new Date();

            let d1 = moment(date).format("DD-MM-YYYY");
            let d2 = moment(yesterday.setDate(yesterday.getDate() - 1)).format(
                "DD-MM-YYYY"
            );
            return classes;
        },
        changeTab(type) {
            this.creativeAnalysisTab = type;
            if (this.creativeAnalysisTab === 'Overview') {
                // this.getCreativesAnalysisTableData()
                if (this.selectedDisplayType.name === 'Meta') {
                    this.getCreativesAnalysisTableDataMeta();
                }
                if (this.selectedDisplayType.name === 'Google-Ads') {
                    this.getCreativesAnalysisTableDataGoogle();
                }
            }
            if (this.creativeAnalysisTab === 'AdsLists') {
                this.getCreativesTopPerfomingDataData();
            }
        },
        fetchClients() {
            this.isClientsFetching = true;
            axios
                .get(
                    `${process.env.VUE_APP_SERVER_URL}/api/client/access-control`,
                    {
                        headers: {
                            Authorization: `Bearer ${getUserToken()}`
                        }
                    }
                )
                .then(response => {
                    this.clients = response && response.data && response.data.clients;
                    this.selectedClient = response && response.data && response.data.clients[0];
                    // this.getCreativesAnalysisTableData();
                    if (this.selectedDisplayType.name === 'Meta') {
                    this.getCreativesAnalysisTableDataMeta();
                }
                if (this.selectedDisplayType.name === 'Google-Ads') {
                    this.getCreativesAnalysisTableDataGoogle();
                }
                    this.isClientsFetching = false;
                })
                .catch(error => {
                    this.isClientsFetching = false;
                    console.log(error.response.data);
                });
        },
    },
    computed: {
        pageLengthALL() {
            return this.creativeTopData && this.creativeTopData.length
        },
        targetValTable() {
            let tableColumns = []
            if (this.selectedDisplayType.name === 'Google-Ads' && this.selectedClient.businessType[0] === 'leadgen') {
                tableColumns = [
                    // {
                    //     label: "Campaign Name",
                    //     field: "campaignName",
                    //     tdClass: "text-center",
                    //     thClass: "text-center acheived",
                    // },
                    {
                        label: "Ad Group Name",
                        field: "adGroupName",
                        tdClass: "text-center",
                        thClass: "text-center ",
                        sortable: false,
                        
                    },
                    {
                        label: "Ad Name",
                        field: "adGroupAdName",
                        tdClass: "text-center",
                        thClass: "text-center ",
                        sortable: false,
                    },
                    {
                        label: "Spends",
                        field: "costMicros",
                        tdClass: "text-center",
                        thClass: "text-center ",
                        sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "Impressions",
                        field: "impressions",
                        tdClass: "text-center",
                        thClass: "text-center ",
                         sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "Clicks",
                        field: "clicks",
                        tdClass: "text-center",
                        thClass: "text-center ",
                         sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "CPM",
                        field: "cpm",
                        tdClass: "text-center",
                        thClass: "text-center ",
                         sortFn: this.sortFn
                    },
                    {
                        label: "CPC",
                        field: "cpcl",
                        tdClass: "text-center",
                        thClass: "text-center",
                         sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "CTR",
                        field: "ctr",
                        tdClass: "text-center ",
                        thClass: "text-center",
                         sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "Leads",
                        field: "conversions",
                        tdClass: "text-center ",
                        thClass: "text-center",
                         sortFn: this.sortStringtoNumFn
                    },

                ];
            }
            else if (this.selectedDisplayType.name === 'Google-Ads' && this.selectedClient.businessType[0] === 'ecommerce') {
                tableColumns = [
                    // {
                    //     label: "Campaign Name",
                    //     field: "campaignName",
                    //     tdClass: "text-center",
                    //     thClass: "text-center acheived",
                    // },
                    {
                        label: "Ad Group Name",
                        field: "adGroupName",
                        tdClass: "text-center",
                        thClass: "text-center ",
                        sortable: false,
                    },
                    {
                        label: "Ad Name",
                        field: "adName",
                        tdClass: "text-center",
                        thClass: "text-center ",
                        sortable: false,
                    },
                    {
                        label: "Spends",
                        field: "costMicros",
                        tdClass: "text-center",
                        thClass: "text-center ",
                       sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "Impressions",
                        field: "impressions",
                        tdClass: "text-center",
                        thClass: "text-center ",
                       sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "Clicks",
                        field: "clicks",
                        tdClass: "text-center",
                        thClass: "text-center ",
                       sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "CPM",
                        field: "cpm",
                        tdClass: "text-center",
                        thClass: "text-center ",
                       sortFn: this.sortFn
                    },
                    {
                        label: "CPC",
                        field: "cpcl",
                        tdClass: "text-center",
                        thClass: "text-center",
                       sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "CTR",
                        field: "ctr",
                        tdClass: "text-center ",
                        thClass: "text-center",
                       sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "ROAS",
                        field: "ROAS",
                        tdClass: "text-center ",
                        thClass: "text-center",
                       sortFn: this.sortStringtoNumFn
                    },

                ];
            }
            else if (this.selectedDisplayType.name === 'Meta' && this.selectedClient.businessType[0] === 'leadgen') {
                tableColumns = [
                    // {
                    //     label: "Campaign Name",
                    //     field: "campaignName",
                    //     tdClass: "text-center",
                    //     thClass: "text-center acheived",
                    // },
                    {
                        label: "Ad Set Name",
                        field: "adsetName",
                        tdClass: "text-center",
                        thClass: "text-center ",
                        sortable: false,
                    },
                    {
                        label: "Ad Name",
                        field: "adName",
                        tdClass: "text-center",
                        thClass: "text-center ",
                        sortable: false,
                    },
                    {
                        label: "Spends",
                        field: "spend",
                        tdClass: "text-center",
                        thClass: "text-center ",
                        sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "Impressions",
                        field: "impressions",
                        tdClass: "text-center",
                        thClass: "text-center ",
                        sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "Clicks",
                        field: "clicks",
                        tdClass: "text-center",
                        thClass: "text-center ",
                        sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "CPM",
                        field: "cpm",
                        tdClass: "text-center",
                        thClass: "text-center ",
                        sortFn: this.sortFn
                    },
                    {
                        label: "CPC",
                        field: "cpcl",
                        tdClass: "text-center",
                        thClass: "text-center",
                        sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "CTR",
                        field: "ctr",
                        tdClass: "text-center ",
                        thClass: "text-center",
                        sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "Leads",
                        field: "conversions",
                        tdClass: "text-center ",
                        thClass: "text-center",
                        sortFn: this.sortStringtoNumFn
                    },

                ];
            }
            else if (this.selectedDisplayType.name === 'Meta' && this.selectedClient.businessType[0] === 'ecommerce') {
                tableColumns = [
                    {
                        label: "Adset Name",
                        field: "adsetName",
                        tdClass: "text-center",
                        thClass: "text-center",
                    },
                    {
                        label: "Ad Name",
                        field: "adName",
                        tdClass: "text-center",
                        thClass: "text-center ",
                        sortable: false,
                    },
                    {
                        label: "Spends",
                        field: "spend",
                        tdClass: "text-center",
                        thClass: "text-center ",
                        sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "Impressions",
                        field: "impressions",
                        tdClass: "text-center",
                        thClass: "text-center ",
                        sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "Clicks",
                        field: "clicks",
                        tdClass: "text-center",
                        thClass: "text-center ",
                        sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "CPM",
                        field: "cpm",
                        tdClass: "text-center",
                        thClass: "text-center ",
                        sortFn: this.sortFn
                    },
                    {
                        label: "CPC",
                        field: "cpcl",
                        tdClass: "text-center",
                        thClass: "text-center",
                        sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "CTR",
                        field: "ctr",
                        tdClass: "text-center ",
                        thClass: "text-center",
                        sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "ROAS",
                        field: "ROAS",
                        tdClass: "text-center ",
                        thClass: "text-center",
                        sortFn: this.sortStringtoNumFn
                    },

                ];
            } else {
                tableColumns = [
                    // {
                    //     label: "Campaign Name",
                    //     field: "campaignName",
                    //     tdClass: "text-center",
                    //     thClass: "text-center acheived",
                    // },
                    {
                        label: "Spends",
                        field: "spend",
                        tdClass: "text-center",
                        thClass: "text-center ",
                      sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "Impressions",
                        field: "impressions",
                        tdClass: "text-center",
                        thClass: "text-center ",
                      sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "Clicks",
                        field: "clicks",
                        tdClass: "text-center",
                        thClass: "text-center ",
                      sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "CPM",
                        field: "cpm",
                        tdClass: "text-center",
                        thClass: "text-center ",
                      sortFn: this.sortFn
                    },
                    {
                        label: "CPC",
                        field: "cpcl",
                        tdClass: "text-center",
                        thClass: "text-center",
                      sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "CTR",
                        field: "ctr",
                        tdClass: "text-center ",
                        thClass: "text-center",
                      sortFn: this.sortStringtoNumFn
                    },
                    {
                        label: "ROAS",
                        field: "ROAS",
                        tdClass: "text-center ",
                        thClass: "text-center",
                      sortFn: this.sortStringtoNumFn
                    },

                ];
            }
            return tableColumns;
        },
        adsListsData() {
            const start = (this.currentPage - 1) * this.pageAdsLength;
            const end = start + this.pageAdsLength;
            return this.creativeTopData.slice(start, end);
        },
        dateRanges() {
            let today = new Date();
            today.setHours(0, 0, 0, 0);

            let yesterday = new Date();
            yesterday.setDate(today.getDate() - 1);
            yesterday.setHours(0, 0, 0, 0);

            let last7thDay = new Date();
            last7thDay.setDate(today.getDate() - 7);
            last7thDay.setHours(0, 0, 0, 0);

            let last14thDay = new Date();
            last14thDay.setDate(today.getDate() - 14);
            last14thDay.setHours(0, 0, 0, 0);

            let last30thDay = new Date();
            last30thDay.setDate(today.getDate() - 30);
            last30thDay.setHours(0, 0, 0, 0);

            var thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
            var thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            let ranges = {
                Today: [today, today],
                Yesterday: [yesterday, today],
                "Last 7 Days": [last7thDay, yesterday],
                "Last 14 Days": [last14thDay, yesterday],
                "Last 30 Days": [last30thDay, yesterday],
                "Last Month": [
                    new Date(today.getFullYear(), today.getMonth() - 1, 1),
                    new Date(today.getFullYear(), today.getMonth(), 0),
                ],
                "This Month": [thisMonthStart, thisMonthEnd],
                "This Year": [new Date(today.getFullYear(), 0, 1), new Date(today)],
            };

            return ranges;
        }
    },
    mounted() {
        this.creativeAnalysisTab = "Overview";
        this.fetchClients();
    },
}
</script>

<style lang="scss">


.creative-analysis-main-container{
    @import "@core/scss/vue/libs/vue-good-table.scss";
    .screenAlignmentCreative .card-body {
        padding: 0.5rem !important;
    }
    
    .spinnerCentered {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 20px;
    }
    
    .client-creative-analysis {
        border-right: 10px solid transparent;
        outline: 1px solid #88848c;
        width: 250px !important;
        height: 36px;
        background-color: white;
        border: 0px;
        color: #88848c;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        padding-left: 8px;
        color: rgb(163, 153, 153);
    }
    
    .creative-filters {
        .card-body {
            padding: 0.8rem !important;
        }
    }
    .drop-down-query-selector{
        width: 165px; height:38px;color:black; font-weight:500
    }
    .creatives-range {
        padding: 10px !important;
        background-color: #F5F5F7 !important;
        border-radius: 10px !important;
        cursor: pointer !important;
        width: 330px !important;
    
        .picker-controller {
            padding: 0px !important;
        }
    }
    .creative-flex{
        display:flex; justify-content:space-between
    }
    .select-style{
        display:flex; justify-content:space-between; gap:8px;
    }
    .icon-format{
        padding: 9.5px;
                                        border: 1px solid #88848c;
                                        margin-right: -2px;
                                        border-top-left-radius: 4px;
                                        border-bottom-left-radius: 4px;
    }
   /* Styles for small mobile screens (max-width: 480px) */
   @media (max-width: 480px) {
    .select-style {
        display: grid;
        grid-template-columns: auto;
        gap: 20px;
        width: 90%;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
      }
    
      .icon-format {
        padding: 10px;
        border: 1px solid #88848c;
        margin-right: 0;
      }
    
      .client-creative-analysis {
        width: 100%;
        font-size: 14px;
      }
    
      .b-dropdown {
        width: 100%;
      }
      .drop-down-query-selector{
        width: 100%; height:38px;color:black; font-weight:500
    }
    .creative-flex {
      display: grid;
      grid-template-columns: auto;
      gap: 20px;
      width: 90%;
      justify-content: center;
      align-items: center;
    }
  
    .creatives-range {
      width: 100% !important;
      padding: 10px;
      background-color: #f5f5f7;
      border-radius: 10px;
      cursor: pointer;
    }
  
    .select-style {
      width: 100%;
      display: grid;
      gap: 20px;
    }
  
    .icon-format {
      padding: 9.5px;
      border: 1px solid #88848c;
      margin-right: -2px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
  
  /* Styles for tablets (min-width: 480px and max-width: 768px) */
  @media (min-width: 480px) and (max-width: 768px) {
    .creative-flex {
      display: grid;
      grid-template-columns: auto;
      gap: 20px;
      align-items: center;
      justify-content: center;
    }
  
    .creatives-range {
      padding: 10px !important;
      background-color: #F5F5F7 !important;
      border-radius: 10px !important;
      cursor: pointer !important;
      width: 100% !important;
    }
  
    .creatives-range .picker-controller {
      padding: 0px !important;
    }
  }
  
}

</style>