<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="10">
          <b-tabs fill class="multichanneldashbpa">
            <b-tab title="Groups" active @click="changeTab('Groups')"></b-tab>
            <b-tab title="Projects" @click="changeTab('Project')"></b-tab>
            <b-tab title="Product Options" @click="changeTab('ProductOptions')"></b-tab>
          </b-tabs>
        </b-col>
        <b-col cols="2">
          <div style="display: grid; grid-template-columns:auto">
            <div>
              <b-button style="float: right; margin-right:5px" variant="outline-primary" size="sm" @click="openDataSyncModal"> <feather-icon icon="RefreshCwIcon" size="12" style="margin-right: 0px;" /> Sync Data </b-button>
            </div>
            <div style="margin-left: 10px;">
              <span style="font-size: 9px;"><strong>Last Updated: </strong>{{this.formatDateAndTimeForSync(this.lastTimeDataFetchedTime)}}</span>
            </div>
          </div>
        </b-col>
      </b-row>
      <div v-if="this.projectCreationTabType === 'Groups'">
        <div class="tabs-flex">
          <b-card-title class="project-creation-header mt-1" >Groups</b-card-title>
          <b-button variant="primary" style="margin-top: 23px;" @click="openGproupCreation">
            <feather-icon icon="PlusCircleIcon" color="white" size="15" style="margin-right: 0px;" />
            Add
          </b-button>
        </div>
        <div v-if="isGroupProductFetched" class="spinnerCentered">
          <multiChannelSpinner />
        </div>
        <div v-else>
          <vue-good-table class="custom-data-table" :rows="accounts" :columns="allAccountsCol" :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }">
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.label == 'Action'" @click="showProjects(props.row.projects)">
                <b-button size="sm" variant="primary"> View Projects</b-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1"></div>
                <div>
                  <b-pagination v-model="currentAccountPage" :total-rows="props.total" :per-page="pageLength"
                    first-number last-number align="right" prev-class="prev-item" next-class="next-item"
                    class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })
                      ">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
      </div>
      <div v-if="this.projectCreationTabType === 'Project'">
        <div class="tabs-flex">
          <b-card-title class="mt-1 project-creation-header">Projects</b-card-title>
          <b-button variant="primary" style="margin-top: 23px;" @click="openProjectCreation">
            <feather-icon icon="PlusCircleIcon" color="white" size="15" style="margin-right: 0px;" />
            Add
          </b-button>
        </div>
        <div v-if="isFetchProjects" class="spinnerCentered">
          <multiChannelSpinner />
        </div>
        <div v-else>
          <div>
            <vue-good-table class="custom-data-table" :rows="allProjects" :columns="allProjectsCol" :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }">
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.label == 'Meta-Ads'">
                  {{ props.formattedRow[props.column.field] }}
                  <b-button @click="showProducts(props.row.channelInfo.meta)" style="margin-left: 5px; float:right"
                    size="sm" variant="primary">
                    View Products</b-button>
                </span>
                <span v-else-if="props.column.label == 'Google-Ads'">
                  {{ props.formattedRow[props.column.field] }}
                  <b-button @click="showProducts(props.row.channelInfo.googleAds)" size="sm"
                    style="margin-left: 5px; float:right" variant="primary">
                    View Products</b-button>
                </span>
                <span v-else-if="props.column.label == 'Action'">
                    <div style="display: flex;gap:2px; justify-content:center">
                      <b-button size="sm" variant="primary" @click="openEditProjectCreations(props.row, props.row._id)"> Edit</b-button>
                      <b-button size="sm" variant="danger" @click="openProjectDeleteModal(props.row._id)"> Delete</b-button>
                    </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1"></div>
                  <div>
                    <b-pagination v-model="currentProjectPage" :total-rows="props.total" :per-page="pageLength"
                      first-number last-number align="right" prev-class="prev-item" next-class="next-item"
                      class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })
                        ">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
      <div v-if="this.projectCreationTabType === 'ProductOptions'">
        <div class="tabs-flex">
          <b-card-title class="mt-1 project-creation-header">Product Options</b-card-title>
          <div class="tabs-flex">
            <div>
              <label>Channel:</label>
              <br />
              <b-form-select style="text-transform: capitalize;" :options="sourceType" label="title"
                v-model="channelType" @input="handleChannelType">
                <template v-slot:option="option">
                  <span :class="option"></span>
                  <span v-if="option.title == 'all'">
                    All
                  </span>
                  <span v-else-if="option.title == 'googleAds'">
                    Google Ads
                  </span>
                  <span v-else-if="option.title == 'meta'">
                    Meta
                  </span>
                  <span v-else>
                    {{ option.title }}
                  </span>
                </template>
              </b-form-select>
            </div>
            <!-- <b-button @click="openProductOptionsCreation" style="width: 100px;height:38px; margin-top:23px"
              variant="primary">Add</b-button> -->
            <b-button variant="primary" style="margin-top: 23px;" @click="openProductOptionsCreation">
              <feather-icon icon="PlusCircleIcon" color="white" size="15" style="margin-right: 0px;" />
              Add
            </b-button>
          </div>
        </div>
        <div>
          <div v-if="isProductOptionsFetched" class="spinnerCentered">
            <multiChannelSpinner />
          </div>
          <div v-else>
            <vue-good-table class="custom-data-table" :rows="productOptions" :columns="productOptionsCol"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }">
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.label == 'Action'">
                  <b-button size="sm" variant="danger" @click="openOptionsDeleteModal(props.row._id)">
                    Delete</b-button>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1"></div>
                  <div>
                    <b-pagination v-model="currentPage" :total-rows="props.total" :per-page="pageLength" first-number
                      last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })
                        ">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </b-card>
    <b-modal no-close-on-backdrop v-model="showGroupCreations" hide-footer hide-header centered size="lg">
      <div class="tabs-flex">
        <b-card-title class="project-creation-header">Groups Creation</b-card-title>
        <div class="header text-right">
          <b-button @click="closeGproupCreation" variant="flat-primary"><feather-icon
              icon="XIcon"></feather-icon></b-button>
        </div>
      </div>
      <div class="p-1 tabs-flex">
        <div>
          <label>Group name:</label>
          <b-form-input v-model="groupName" placeholder="Group Name.." class="groupProject" />
        </div>
        <div>
          <label>Tag:</label>
          <b-form-input v-model="groupTag" placeholder="Group Name.." class="groupProject" />
        </div>
        <div>
          <label>Projects:</label>
          <v-select class="project-select-size custom-vue-select-project-creation" placeholder="Select Project Options" v-model="selectedGroupProjects"
            multiple label="name" :options="allAdsValues" />
        </div>
        <div>
          <b-button :disabled="isGroupButtonDisabled" variant="primary" style="margin-top: 23px;"
            @click="addGroupFormEntry">
            <feather-icon icon="PlusCircleIcon" color="white" size="15" style="margin-right: 0px;" />
            Add
          </b-button>
        </div>
      </div>
      <div class="p-1" v-if="groupsCreationsValue.length > 0">
        <label>Selected Group List</label>
        <vue-good-table class="custom-data-table" :rows="groupsCreationsValue" :columns="groupsCreationsCol">
        </vue-good-table>
      </div>
      <div class="tabs-flex" v-if="this.groupsCreationsValue.length > 0">
        <div></div>
        <div>
          <b-button variant="primary" @click="createGroups">
            Submit
            <b-spinner v-if="isGroupCreated" small class="mr-1" />
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal no-close-on-backdrop v-model="showProjectCreations" hide-footer hide-header centered size="lg">
      <div class="tabs-flex">
        <b-card-title class="project-creation-header">Project Creation</b-card-title>
        <div class="header text-right">
          <b-button @click="closeProjectCreation" variant="flat-primary"><feather-icon
              icon="XIcon"></feather-icon></b-button>
        </div>
      </div>
      <div class="p-1">
        <div>
          <div class="tabs-flex">
            <div class="tabs-flex">
              <div>
                <div>
                  <label>Project Name:</label>
                  <br />
                  <b-form-input v-model="projectValue" id="boomark-search-input" placeholder="Value"
                    class="project-select-size" />
                </div>
              </div>
              <div>
                <div>
                  <label>Google-Ads Accounts:</label>
                  <br />
                  <b-form-select class="project-select-size" :options="combinedGoogleAdsAccount"
                    v-model="selectedGoogleAccountSelected">
                  </b-form-select>
                </div>
              </div>
              <div>
                <div>
                  <label>Meta-Ads Accounts:</label>
                  <br />
                  <b-form-select class="project-select-size" :options="combinedFacebookAdsAccount"
                    v-model="selectedFacebookAccountSelected">
                  </b-form-select>
                </div>
              </div>
            </div>
          </div>
          <div class="tabs-flex">
            <div class="tabs-flex">
              <div>
                <div>
                  <label>Values:</label>
                  <br />
                  <v-select
                  class="project-accounts-select-size custom-vue-select-project-creation"
                  placeholder="Enter Values"
                  v-model="selectedValues"
                  multiple
                  no-drop taggable :select-on-key-codes="[188, 13]"
                  label="name"
                  :options="allSelectValues"
                  :searchable="true"
                  ref="vSelect"
                />
                </div>
              </div>
              <div>
                <div>
                  <label>Google-Ads Product Options:</label> 
                  <br />
                  <v-select class="project-accounts-select-size custom-vue-select-project-creation" placeholder="Select Product Options"
                    v-model="selectedGoogleAds" multiple label="name" :options="googleAdsValues" />
                </div>
              </div>
              <div>
                <div>
                  <label>Meta-Ads Product Options:</label>
                  <br />
                  <v-select class="project-accounts-select-size custom-vue-select-project-creation" placeholder="Select Product Options"
                    v-model="selectedMetaAds" multiple label="name" :options="metaValues" />
                </div>
              </div>
              <div>
                <div>
                  <b-button :disabled="isProjectCreationsDisabled" variant="primary" style="margin-top: 23px;" @click="addProjectCreationEntry">
                    <feather-icon icon="PlusCircleIcon" color="white" size="15" style="margin-right: 0px;" />
                    Add
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="project-creation-container mt-1" v-if="projectCreateOptionsValue.length">
          <div class="project-options-container">
            <h5 class="section-header">Projects</h5>

            <div v-for="(client, clientIndex) in projectCreateOptionsValue" :key="clientIndex" class="client-card">
              <div class="project-card">
                <div style="display: flex; justify-content: space-between">
                  <h6 class="project-title">Project Name: {{ client.name }}</h6>
                  <b-button size="sm" variant="outline-danger" @click="removeProjectOption(clientIndex)"
                    class="remove-option-button">
                    Remove
                  </b-button>
                </div>

                <div class="channel-container">
                  <h6 class="channel-header">Channel Information</h6>

                  <!-- Google Ads Info -->
                  <div v-if="client.channelInfo.googleAds" class="channel-info google-ads">
                    <p class="channel-name">Google Ads</p>
                    <p class="channel-detail">
                      Account Name:
                      <span class="channel-value">{{
                        client.channelInfo.googleAds.accountID
                      }}</span>
                    </p>
                    <p class="product-ids-label">Product Options:</p>
                    <ul class="product-ids-list">
                      <li v-for="(id, idIndex) in client.channelInfo.googleAds
                        .productOptionNames" :key="idIndex" class="product-id">
                        {{ id }}
                      </li>
                    </ul>
                  </div>
                  <!-- Meta Info -->
                  <div v-if="client.channelInfo.meta" class="channel-info meta-channel">
                    <p class="channel-name">Meta</p>
                    <p class="channel-detail">
                      Account Name:
                      <span class="channel-value">{{
                        client.channelInfo.meta.accountID
                      }}</span>
                    </p>
                    <p class="product-ids-label">Product Options:</p>
                    <ul class="product-ids-list">
                      <li v-for="(id, idIndex) in client.channelInfo.meta
                        .productOptionNames" :key="idIndex" class="product-id">
                        {{ id }}
                      </li>
                    </ul>
                  </div>
                  <div v-if="client.values" class="channel-info google-ads">
                    <p class="channel-name">Values:</p>
                    <ul class="product-ids-list">
                      <li v-for="(id, idIndex) in client.values" :key="idIndex" class="product-id">
                        {{ id }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tabs-flex mt-1" v-if="this.projectCreateOptions.length > 0">
          <div></div>
          <div>
            <b-button :disabled="isProjectCreated" variant="primary" @click="openProjectConfirmation">
              Submit
              <b-spinner v-if="isProjectCreated" small class="mr-1" />
            </b-button>
          </div>
        </div>
      </div>
      <div v-if="productCreateOptions.length" class="product-options-container">
        <h5 class="options-header">Product Options</h5>
        <div v-for="(option, index) in productCreateOptions" :key="index" class="product-option-card">
          <div class="product-option-header">
            <span class="option-index">#{{ index + 1 }}</span>
            <b-button size="sm" variant="outline-danger" @click="removeOption(index)" class="remove-option-button">
              Remove
            </b-button>
          </div>
          <div class="product-option-details">
            <p><strong>Channel:</strong> {{ option.channel }}</p>
            <p><strong>Value:</strong> {{ option.rule.value }}</p>
          </div>
        </div>
      </div>
      <div class="tabs-flex" v-if="this.productCreateOptions.length > 0">
        <div></div>
        <div>
          <b-button variant="primary" @click="createProductOptionsSubmit">
            Submit
            <b-spinner v-if="isProjectCreationsDisabled" small class="mr-1" />
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- Update Project -->
    <b-modal no-close-on-backdrop v-model="isOpenEditData" hide-footer hide-header centered size="lg">
      <div class="tabs-flex">
        <b-card-title class="project-creation-header">Update Project</b-card-title>
        <div class="header text-right">
          <b-button @click="closeEditProjectCreations" variant="flat-primary"><feather-icon
              icon="XIcon"></feather-icon></b-button>
        </div>
      </div>
      <div class="p-1">
        <div>
          <div class="tabs-flex">
            <div class="tabs-flex">
              <div>
                <div>
                  <label>Project Name:</label>
                  <br />
                  <b-form-input disabled v-model="projectValue" id="boomark-search-input" placeholder="Value"
                    class="project-select-size" />
                </div>
              </div>
              <div>
                <div>
                  <label>Google-Ads Accounts:</label>
                  <br />
                  <b-form-select class="project-select-size" :options="combinedGoogleAdsAccount"
                    v-model="selectedGoogleAccountSelected">
                  </b-form-select>
                </div>
              </div>
              <div>
                <div>
                  <label>Meta-Ads Accounts:</label>
                  <br />
                  <b-form-select class="project-select-size" :options="combinedFacebookAdsAccount"
                    v-model="selectedFacebookAccountSelected">
                  </b-form-select>
                </div>
              </div>
            </div>
          </div>
          <div class="tabs-flex">
            <div class="tabs-flex">
              <div>
                <div>
                  <label>Values:</label>
                  <br />
                  <v-select
                  class="project-accounts-select-size custom-vue-select-project-creation"
                  placeholder="Enter Values"
                  v-model="selectedValues"
                  multiple
                  no-drop taggable :select-on-key-codes="[188, 13]"
                  label="name"
                  :options="allSelectValues"
                  :searchable="true"
                  ref="vSelect"
                />
                </div>
              </div>
              <div>
                <div>
                  <label>Google-Ads Product Options:</label>
                  <br />
                  <v-select class="project-accounts-select-size custom-vue-select-project-creation" placeholder="Select Product Options"
                    v-model="selectedGoogleAds" multiple label="name" :options="googleAdsValues" />
                </div>
              </div>
              <div>
                <div>
                  <label>Meta-Ads Product Options:</label>
                  <br />
                  <v-select class="project-accounts-select-size custom-vue-select-project-creation" placeholder="Select Product Options"
                    v-model="selectedMetaAds" multiple label="name" :options="metaValues" />
                </div>
              </div>
              <div>
                <div>
                  <b-button :disabled="isProjectCreationsDisabled" variant="primary" style="margin-top: 23px;" @click="openEditConfirmationModal">
                    <!-- <feather-icon icon="PlusCircleIcon" color="white" size="15" style="margin-right: 0px;" /> -->
                    Update
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tabs-flex mt-1" v-if="this.projectCreateOptions.length > 0">
          <div></div>
          <div>
            <b-button :disabled="isProjectCreated" variant="primary" @click="openProjectConfirmation">
              Submit
              <b-spinner v-if="isProjectCreated" small class="mr-1" />
            </b-button>
          </div>
        </div>
      </div>
      <div v-if="productCreateOptions.length" class="product-options-container">
        <h5 class="options-header">Product Options</h5>
        <div v-for="(option, index) in productCreateOptions" :key="index" class="product-option-card">
          <div class="product-option-header">
            <span class="option-index">#{{ index + 1 }}</span>
            <b-button size="sm" variant="outline-danger" @click="removeOption(index)" class="remove-option-button">
              Remove
            </b-button>
          </div>
          <div class="product-option-details">
            <p><strong>Channel:</strong> {{ option.channel }}</p>
            <p><strong>Value:</strong> {{ option.rule.value }}</p>
          </div>
        </div>
      </div>
      <div class="tabs-flex" v-if="this.productCreateOptions.length > 0">
        <div></div>
        <div>
          <b-button variant="primary" @click="createProductOptionsSubmit">
            Submit
            <b-spinner v-if="isProjectCreationsDisabled" small class="mr-1" />
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal no-close-on-backdrop v-model="showOptionsCreations" hide-footer hide-header centered size="small">
      <div class="tabs-flex">
        <b-card-title class="project-creation-header">Product Option Creation</b-card-title>
        <div class="header text-right">
          <b-button @click="closeProductOptionsCreation" variant="flat-primary"><feather-icon
              icon="XIcon"></feather-icon></b-button>
        </div>
      </div>
      <div class="tabs-flex">
        <div>
          <div class="tabs-flex mb-1">
            <div>
              <label>Value:</label>
              <br />
              <b-form-input style="width: 180px;" v-model="formData.rule.value" id="boomark-search-input" placeholder="Value" />
            </div>
            <div>
              <label>Channel:</label>
              <br />
              <b-form-select style="text-transform: capitalize; width: 178px;" :options="sourceOptionsType" label="title"
                v-model="formData.channel">
                <template v-slot:option="option">
                  <span :class="option"></span>
                  <span v-if="option.title == 'all'">
                    All
                  </span>
                  <span v-else-if="option.title == 'googleAds'">
                    Google Ads
                  </span>
                  <span v-else-if="option.title == 'meta'">
                    Meta
                  </span>
                  <span v-else>
                    {{ option.title }}
                  </span>
                </template>
              </b-form-select>
            </div>
            <div>
              <b-button :disabled="isOptionButtonDisabled" variant="primary" style="margin-top: 23px;"
                @click="addFormEntry">
                <feather-icon icon="PlusCircleIcon" color="white" size="15" style="margin-right: 0px;" />
                Add
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="productCreateOptions.length > 0">
        <vue-good-table class="custom-data-table" :rows="productCreateOptions" :columns="productCreateOptionsCol" >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.label == 'Action'" @click="removeProductCreateOptionsOption(props.row)">
              <b-button size="sm" variant="danger">Remove</b-button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
      <!-- <div v-if="productCreateOptions.length" class="product-options-container">
        <h5 class="options-header">Product Options</h5>
        <div v-for="(option, index) in productCreateOptions" :key="index" class="product-option-card">
          <div class="product-option-header">
            <span class="option-index">#{{ index + 1 }}</span>
            <b-button size="sm" variant="outline-danger" @click="removeOption(index)" class="remove-option-button">
              Remove
            </b-button>
          </div>
          <div class="product-option-details">
            <p><strong>Channel:</strong> {{ option.channel }}</p>
            <p><strong>Value:</strong> {{ option.rule.value }}</p>
          </div>
        </div>
      </div> -->
      <div class="tabs-flex mt-1" v-if="this.productCreateOptions.length > 0">
        <div></div>
        <div>
          <b-button variant="primary" @click="createProductOptionsSubmit">
            Submit
            <b-spinner v-if="isProductOptionCreated" small class="mr-1" />
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal no-close-on-backdrop v-model="showProjectOptions" hide-footer hide-header centered size="small">
      <div class="tabs-flex">
        <div><b-card-title class="project-creation-header">Project Details</b-card-title></div>
        <div class="header text-right">
          <b-button @click="closeProjects" variant="flat-primary"><feather-icon icon="XIcon"></feather-icon></b-button>
        </div>
      </div>
      <div v-if="this.projectLists.length > 0">
        <vue-good-table class="custom-data-table" :rows="projectLists" :columns="projectsListCol" :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }">
          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1"></div>
              <div>
                <b-pagination v-model="currentProjectListPage" :total-rows="props.total" :per-page="pageLength"
                  first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-modal>
    <b-modal no-close-on-backdrop v-model="showProductOptions" hide-footer hide-header centered size="small">
      <div class="tabs-flex">
        <div><b-card-title class="project-creation-header">Product List</b-card-title></div>
        <div class="header text-right">
          <b-button @click="closeProducts" variant="flat-primary"><feather-icon icon="XIcon"></feather-icon></b-button>
        </div>
      </div>
      <div v-if="this.productLists.length > 0">
        <vue-good-table class="custom-data-table" :rows="productLists" :columns="productListCol" :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }">
          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1"></div>
              <div>
                <b-pagination v-model="currentListPage" :total-rows="props.total" :per-page="pageLength" first-number
                  last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-modal>
    <b-modal no-close-on-backdrop id="modal-center" hide-footer hide-header centered size="small"
      v-model="projectDeleteModal">
      <b-card-text>
        <div style="float: right">
          <b-button variant="flat-primary" @click="closeProjectDeleteModal"
            style="margin-top: -10px; margin-right: -18px"><feather-icon icon="XIcon"></feather-icon></b-button>
        </div>
        <br />
        <div class="text-center" style="margin-top: 5px">
          <h4>Are sure want to delete this Project?</h4>
        </div>
        <div class="button-container">
          <b-button variant="outline-danger" class="custom-primary-with-border" @click="closeProjectDeleteModal">
            Cancel
          </b-button>
          <b-button variant="danger" @click="deleteProjects">
            Delete <b-spinner v-if="isDeleteProjects" small class="ml-1" />
          </b-button>
        </div>
      </b-card-text>
    </b-modal>
    <b-modal no-close-on-backdrop id="modal-center" hide-footer hide-header centered size="small"
      v-model="optionsDeleteModal">
      <b-card-text>
        <div style="float: right">
          <b-button variant="flat-primary" @click="closeOptionsDeleteModal"
            style="margin-top: -10px; margin-right: -18px"><feather-icon icon="XIcon"></feather-icon></b-button>
        </div>
        <br />
        <div class="text-center" style="margin-top: 5px">
          <h4>Are sure want to delete this option?</h4>
        </div>
        <div class="button-container">
          <b-button variant="outline-danger" class="custom-primary-with-border" @click="closeOptionsDeleteModal">
            Cancel
          </b-button>
          <b-button variant="danger" @click="deleteOptions">
            Delete <b-spinner v-if="isDeleteOptions" small class="ml-1" />
          </b-button>
        </div>
      </b-card-text>
    </b-modal>
    <b-modal no-close-on-backdrop id="modal-center" hide-footer hide-header centered size="small"
    v-model="finalConfirmation">
    <b-card-text>
      <div style="float: right">
        <b-button variant="flat-primary" @click="closeProjectConfirmation"
          style="margin-top: -10px; margin-right: -18px"><feather-icon icon="XIcon"></feather-icon></b-button>
      </div>
      <br />
      <div class="project-creation-container mt-1" v-if="projectCreateOptionsValue.length">
        <div class="project-options-container">
          <h5 class="section-header">Projects</h5>

          <div v-for="(client, clientIndex) in projectCreateOptionsValue" :key="clientIndex" class="client-card">
            <div class="project-card">
              <div style="display: flex; justify-content: space-between">
                <h6 class="project-title">Project Name: {{ client.name }}</h6>
                <!-- <b-button size="sm" variant="outline-danger" @click="removeProjectOption(clientIndex)"
                  class="remove-option-button">
                  Remove
                </b-button> -->
              </div>

              <div class="channel-container">
                <h6 class="channel-header">Channel Information</h6>

                <!-- Google Ads Info -->
                <div v-if="client.channelInfo.googleAds" class="channel-info google-ads">
                  <p class="channel-name">Google Ads</p>
                  <p class="channel-detail">
                    Account Name:
                    <span class="channel-value">{{
                      client.channelInfo.googleAds.accountID
                    }}</span>
                  </p>
                  <p class="product-ids-label">Product Options:</p>
                  <ul class="product-ids-list">
                    <li v-for="(id, idIndex) in client.channelInfo.googleAds
                      .productOptionNames" :key="idIndex" class="product-id">
                      {{ id }}
                    </li>
                  </ul>
                </div>

                <!-- Meta Info -->
                <div v-if="client.channelInfo.meta" class="channel-info meta-channel">
                  <p class="channel-name">Meta</p>
                  <p class="channel-detail">
                    Account Name:
                    <span class="channel-value">{{
                      client.channelInfo.meta.accountID
                    }}</span>
                  </p>
                  <p class="product-ids-label">Product Options:</p>
                  <ul class="product-ids-list">
                    <li v-for="(id, idIndex) in client.channelInfo.meta
                      .productOptionNames" :key="idIndex" class="product-id">
                      {{ id }}
                    </li>
                  </ul>
                </div>
                <div v-if="client.values" class="channel-info google-ads">
                  <p class="channel-name">Values:</p>
                  <ul class="product-ids-list">
                    <li v-for="(id, idIndex) in client.values" :key="idIndex" class="product-id">
                      {{ id }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center" style="font-family: Arial, sans-serif; padding: 20px; border: 1px solid #ccc; border-radius: 8px; background-color: #f9f9f9;">
          <h4 style="margin-bottom: 15px; color: #333; text-align:center">Are you sure you want to create this Project?</h4>
          <ul style="list-style-type: disc; margin: 0 auto; padding-left: 20px; text-align: left; max-width: 400px;">
            <li style="margin-bottom: 10px;">Once created, the project cannot be edited.</li>
            <li style="margin-bottom: 10px;">Ensure all details are correct before proceeding.</li>
          </ul>
        </div>  
      </div>       
      <div class="button-container">
        <b-button variant="outline-danger" class="custom-primary-with-border" @click="closeProjectConfirmation">
          Cancel
        </b-button>
        <b-button variant="danger" @click="createProjectSubmit">
          Yes <b-spinner v-if="isProjectCreated" small class="ml-1" />
        </b-button>
      </div>
    </b-card-text>
  </b-modal>
  <b-modal  id="modal-center" hide-header hide-footer  centered size="xs"
  v-model="dataSyncing">
  <b-card-text>
    <div style="display: flex; justify-content:space-between; padding:2px 0px">
      <div>
        <h6>Sync Your Data</h6>
      </div>
      <div style="float: right">
        <b-button variant="flat-primary" @click="closeDataSyncModal"
          style="margin-top: -10px; margin-right: -18px"><feather-icon icon="XIcon"></feather-icon></b-button>
      </div>  
    </div>    
  </b-card-text>
   <div>
    <b-row style="padding: 0 10px">
      <b-col cols="12">
        <label>Select Duration</label>
        <br />
        <b-form-select
        style="text-transform: capitalize;"
        :options="dataSyncDurationType"
        v-model="selectedSyncType"
        @input="selectSyncType"
      >
      </b-form-select>
      </b-col>
    </b-row>
   </div>
  <div class="button-container mt-2">
    <b-button variant="outline-primary" class="custom-primary-with-border" @click="closeDataSyncModal" size="sm">
      Cancel
    </b-button>
    <b-button variant="primary" @click="callDataSync" size="sm">
      Proceed <b-spinner v-if="isDataSyncing" small class="ml-1" />
    </b-button>
  </div>
  </b-modal>
  <b-modal no-close-on-backdrop id="modal-center" hide-footer hide-header centered size="small"
  v-model="finalEditConfirmation">
  <div class="text-center" style="font-family: Arial, sans-serif; padding: 20px; border: 1px solid #ccc; border-radius: 8px; background-color: #f9f9f9;">
    <h4 style="margin-bottom: 15px; color: #333; text-align:center">Are you sure you want to update this Project?</h4>
    <ul style="list-style-type: disc; margin: 0 auto; padding-left: 20px; text-align: left; max-width: 400px;">
      <!-- <li style="margin-bottom: 10px;">Once updated, the project cannot be edited.</li> -->
      <li style="margin-bottom: 10px;">Ensure all details are correct before proceeding.</li>
    </ul>
  </div>  
  <div class="button-container">
    <b-button variant="outline-danger" class="custom-primary-with-border" @click="closeEditConfirmationModal">
      Cancel
    </b-button>
    <b-button variant="danger" @click="updateProjectSubmit">
      Yes <b-spinner v-if="isProjectCreated" small class="ml-1" />
    </b-button>
  </div>
  </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { getUserToken } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import {
  BCard,
  BTab,
  BTabs,
  BCardTitle,
  BButton,
  BModal,
  BFormInput,
  BIcon,
  BRow,
  BCol,
  BFormSelect,
  BPagination,
  BCardText,
  BSpinner,
} from "bootstrap-vue";
import { props } from "vue2-dropzone";
export default {
  data() {
    return {
      syncTypeValue:"",
      selectedSyncType:1,
      isDataSyncing:false,
      dataSyncing:false,
      dataSyncDurationType: [
        { text: 'Last 90 Days', value: 90 },
        { text: 'Last 60 Days', value: 60 },
        { text: 'Last 30 Days', value: 30 },
        { text: 'Last 7 Days', value: 7 },
        { text: 'Yesterday', value: 1 }
      ],
      projectEditId:"",
      editProjectEditDetails:null,
      finalEditConfirmation:false,
      isOpenEditData:false,
      selectedValues: [], // Selected items
      allSelectValues: [], // Available options
      finalConfirmation:false,
      groupsCreationsValue: [],
      groupName: "",
      groupTag: "",
      isGroupCreated: false,
      isProjectCreated: false,
      projectValue: "",
      projectCreateOptions: [],
      projectCreateOptionsValue: [],
      selectedGoogleAds: [],
      selectedGroupProjects: [],
      selectedMetaAds: [],
      selectedFacebookAccountSelected: {},
      selectedGoogleAccountSelected: {},
      isDeleteOptions: false,
      deleteProjectID: "",
      deleteOptionsID: "",
      optionsDeleteModal: false,
      isDeleteProjects: false,
      productLists: [],
      projectLists: [],
      showProductOptions: false,
      showProjectOptions: false,
      isFetchProjects: false,
      allProjects: [],
      isProductOptionCreated: false,
      productCreateOptions: [],
      formData: {
        channel: "",
        rule: {
          value: "",
        },
      },
      showOptionsCreations: false,
      showProjectCreations: false,
      currentPage: 1,
      currentProjectPage: 1,
      currentListPage: 1,
      currentAccountPage: 1,
      currentProjectListPage: 1,
      sourceType: ["all", "googleAds", "meta"],
      sourceOptionsType: ["all", "googleAds", "meta"],
      channelOptionsType: "all",
      channelType: "all",
      groupName: "",
      tag: "",
      projectCreationTabType: "",
      showGroupCreations: false,
      isGroupProductFetched: false,
      isColFetched: false,
      accounts: [],
      newAccess: {},
      Col: [],
      pageLength: 5,
      productOptions: [],
      projectDeleteModal: false,
    };
  },
  components: {
    BCard,
    BTab,
    BTabs,
    BCardTitle,
    BButton,
    BModal,
    BFormInput,
    BIcon,
    BRow,
    BCol,
    vSelect,
    multiChannelSpinner,
    BFormSelect,
    VueGoodTable,
    BPagination,
    ToastificationContent,
    BCardText,
    BSpinner,
  },
  props: {
    lastTimeDataFetchedTime:{
      type: String,
    },
    clientID: {
      type: String,
    },
    facebookAdsAccount: {
      type: Object,
      default: () => ({}), 
    },
    googleAdsAccounts: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    selectSyncType(){
      const selectedObject = this.dataSyncDurationType.find(
        option => option.value === this.selectedSyncType
      );
      this.syncTypeValue = selectedObject.text.split(" ").join("_")
    },
    callDataSync(){
      this.isDataSyncing = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/sync-data`,
          {
            clientID: this.clientID,
            dateRange: this.syncTypeValue,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.showToast(
            "Success!",
            "Data Syncing Successfully!",
            "success"
          );
          this.isDataSyncing = false
          this.dataSyncing = false
        })
        .catch((error) => {
          this.isDataSyncing = false
          this.isProjectCreated = false;
          this.showToast("Error", "Unknown error occured!", "danger");
          console.log(error.response.data);
          this.closeProjectCreation();
          this.dataSyncing = false
        });
    },
    openDataSyncModal(){
      this.dataSyncing = true
    },
    closeDataSyncModal(){
      this.dataSyncing = false
    },
    formatDateAndTimeForSync(isoString) {
  const date = new Date(isoString);

  // Extract individual components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Format as 'YYYY-MM-DD HH:mm:ss'
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
},
    openEditConfirmationModal(){
      let project = {
      channelInfo: {
        googleAds: {
          accountID: this.selectedGoogleAccountSelected || "defaultGoogleAccountID",
          productOptionIDs: this.selectedGoogleAds?.map(ad => ad.value) || [],
        },
        meta: {
          accountID: this.selectedFacebookAccountSelected || "defaultFacebookAccountID",
          productOptionIDs: this.selectedMetaAds?.map(ad => ad.value) || [],
        },
      },
      values: this.selectedValues || [],
  };
  this.editProjectEditDetails = project
        this.finalEditConfirmation = true
    },
    closeEditConfirmationModal(){
      this.finalEditConfirmation = false
    },
    openEditProjectCreations(data, id){
    this.projectEditId = id
    const matchedGoogleValues = data.channelInfo.googleAds.productOptionIDs.map(option => option.rule.value);
    const matchedMetaValues = data.channelInfo.meta.productOptionIDs.map(option => option.rule.value);

    const filteredGoogleData = this.googleAdsValues.filter(item =>
      matchedGoogleValues.includes(item.name)
    );

    // Filter the Meta data based on the matched values
    const filteredMetaData = this.metaValues.filter(item =>
      matchedMetaValues.includes(item.name)
    );

    console.log(filteredGoogleData, filteredMetaData);
      // const metaProductOptionIDs = data.channelInfo.meta.productOptionIDs.map(
      //     (option) => option._id
      //   );
      // const googleAdsProductOptionIDs = data.channelInfo.googleAds.productOptionIDs.map(
      //     (option) => option._id
      //   );

      this.selectedGoogleAccountSelected = data.channelInfo.googleAds.accountID
      this.selectedFacebookAccountSelected = data.channelInfo.meta.accountID
      this.selectedMetaAds = filteredMetaData
      this.selectedGoogleAds = filteredGoogleData
      this.selectedValues = data.values
      this.projectValue = data.name
      this.isOpenEditData = true
    },
    closeEditProjectCreations(){
      this.isOpenEditData = false
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    deleteProjects() {
      this.isDeleteProjects = true;
      axios
        .delete(
          `${process.env.VUE_APP_SERVER_URL}/api/projects?projectID=${this.deleteProjectID}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.fetchProjectsAll();
          this.isDeleteProjects = false;
          this.closeProjectDeleteModal();
          this.fetchProjectsAll();
          this.showToast("Success!", "Delete Project Successfully", "success");
        })
        .catch((error) => {
          this.isDeleteProjects = false;
          this.closeProjectDeleteModal();
          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    deleteOptions() {
      this.isDeleteOptions = true;
      axios
        .delete(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/product-options?productOptionID=${this.deleteOptionsID}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.fetchProductOptions();
          this.isDeleteOptions = false;
          this.closeOptionsDeleteModal();
          this.showToast("Success!", "Delete Option Successfully", "success");
        })
        .catch((error) => {
          this.closeOptionsDeleteModal();
          this.isDeleteOptions = false;
          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    openProjectConfirmation(){
      this.finalConfirmation = true
    },
    closeProjectConfirmation(){
      this.finalConfirmation = false
    },
    
    createProjectSubmit() {
      this.isProjectCreated = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/create-projects`,
          {
            projects: this.projectCreateOptions,
            clientID: this.clientID,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.showToast(
            "Success!",
            "Project Created Successfully!",
            "success"
          );
          this.isProjectCreated = false;
          this.projectCreateOptions = [];
          this.fetchProjectsAll();
          this.closeProjectCreation();
          this.finalConfirmation = false
        })
        .catch((error) => {
          this.isProjectCreated = false;
          this.showToast("Error", "Unknown error occured!", "danger");
          console.log(error.response.data);
          this.closeProjectCreation();
          this.finalConfirmation = false
        });
    },
    updateProjectSubmit() {
      this.isProjectCreated = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/edit-project`,
          {
            projects: this.editProjectEditDetails,
            projectID:this.projectEditId,
            clientID: this.clientID,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.showToast(
            "Success!",
            "Project Updated Successfully!",
            "success"
          );
          this.isProjectCreated = false;
          this.projectCreateOptions = [];
          this.fetchProjectsAll();
          this.closeProjectCreation();
          this.closeEditConfirmationModal();
          this.closeEditProjectCreations();
          this.selectedValues = []
          this.finalConfirmation = false
        })
        .catch((error) => {
          this.isProjectCreated = false;
          this.showToast("Error", "Unknown error occured!", "danger");
          console.log(error.response.data);
          this.closeProjectCreation();
          this.finalConfirmation = false
        });
    },
    createProductOptionsSubmit() {
      this.isProductOptionCreated = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/create-product-options`,
          {
            productOptions: this.productCreateOptions,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.showToast(
            "Success!",
            "Product Options Created Successfully!",
            "success"
          );
          this.isProductOptionCreated = false;
          this.fetchProductOptions();
          this.closeProductOptionsCreation();
          this.productCreateOptions = []
        })
        .catch((error) => {
          this.isProductOptionCreated = false;
          this.showToast("Error", "Unknown error occured!", "danger");
          console.log(error.response.data);
          this.closeProductOptionsCreation();
          this.productCreateOptions = []
        });
    },
    createGroups() {
      this.isGroupCreated = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/create-project-group`,
          {
            clientID: this.clientID,
            groups: this.groupsCreationsValue,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.groupsCreationsValue = []
          this.showToast("Success!", "Group Created Successfully!", "success");
          this.isGroupCreated = false;
          this.fetchGropupProjects();
          this.closeGproupCreation();
        })
        .catch((error) => {
          this.groupsCreationsValue = []
          this.isGroupCreated = false;
          this.closeGproupCreation();
          this.showToast("Error", "Unknown error occured!", "danger");
        });
    },
    removeOption(index) {
      this.productCreateOptions.splice(index, 1);
    },
    removeProductCreateOptionsOption(row){
      const index = this.productCreateOptions.findIndex(option => option.rule.value === row.rule.value);
        if (index !== -1) {
          this.productCreateOptions.splice(index, 1);
        }
    },
    removeProjectOption(index) {
      this.projectCreateOptionsValue.splice(index, 1);
    },
    addGroupFormEntry() {
      const newGroup = {
        name: this.groupName,
        tag: this.groupTag,
        projectIDs: this.selectedGroupProjects.map((ad) => ad.value), // Using the unique 'value' property
      };

      // Add the new group to the groups array
      this.groupsCreationsValue.push(newGroup);

      // Remove selected projects from the available list
      this.allProjects = this.allProjects.filter(
        (rule) =>
          !this.selectedGroupProjects.some(
            (selected) => selected.value === rule._id
          )
      );

      // Clear form fields after submission
      this.groupName = "";
      this.groupTag = "";
      this.selectedGroupProjects = [];
    },
    addFormEntry() {
      // Check if both fields are filled
      if (this.formData.rule.value && this.formData.channel) {
        // Check for uniqueness of value using a Set or array filter
        const existingValues = new Set(
          this.productCreateOptions.map((option) => option.rule.value)
        );

        if (existingValues.has(this.formData.rule.value)) {
          this.showToast("Error", "Please add unique value!", "danger");
        } else {
          // Add the new entry to the array
          this.productCreateOptions.push({ ...this.formData });

          // Reset formData for the next entry
          this.formData = {
            channel: "",
            rule: {
              value: "",
            },
          };
        }
      }
    },
    addProjectCreationEntry() {
      if (
        this.projectValue &&
        this.selectedGoogleAccountSelected &&
        this.selectedFacebookAccountSelected &&
        this.selectedGoogleAds.length > 0 &&
        this.selectedMetaAds.length > 0
      ) {

        // Extract only `value` froMets Ads and Meta Ads selected product options
        const googleAdsProductOptionIDs = this.selectedGoogleAds.map(
          (option) => option.value
        );

        const metaAdsProductOptionIDs = this.selectedMetaAds.map(
          (option) => option.value
        );
        const googleAdsProductOptionNames = this.selectedGoogleAds.map(
          (option) => option.name
        );

        const metaAdsProductOptionNames = this.selectedMetaAds.map(
          (option) => option.name
        );
        

        const index = this.googleAdsAccounts.customerClientIDs.indexOf(this.selectedGoogleAccountSelected.toString());

      // Check if the ID exists and then retrieve the corresponding name
      let selectedCustomerName = '';
      if (index !== -1) {
        selectedCustomerName = this.googleAdsAccounts.customerNames[index];
      }

      let selectedMetaCustomerName = '';
      if (index !== -1) {
        selectedMetaCustomerName = this.facebookAdsAccount.adAccountNames[index];
      }

        const newValueEntry = {
          name: this.projectValue, // Assuming "Value" represents the project name
          channelInfo: {
            googleAds: {
              accountID: selectedCustomerName,
              productOptionNames: googleAdsProductOptionNames, // Use extracted values
            },
            meta: {
              accountID: selectedMetaCustomerName,
              productOptionNames: metaAdsProductOptionNames, // Use extracted values
            },
          },
          values:this.selectedValues
        };
        this.projectCreateOptionsValue.push(newValueEntry)

        const existingGoogleValues = new Set(
          this.productCreateOptions.flatMap(
            (option) =>
              option.projects[0]?.channelInfo.googleAds.productOptionIDs || []
          )
        );

        const existingMetaValues = new Set(
          this.productCreateOptions.flatMap(
            (option) =>
              option.projects[0]?.channelInfo.meta.productOptionIDs || []
          )
        );

        const isGoogleDuplicate = googleAdsProductOptionIDs.some((id) =>
          existingGoogleValues.has(id)
        );

        if (isGoogleDuplicate) {
          this.showToast(
            "Error",
            "Please add unique Google Ads Product Option!",
            "danger"
          );
          return;
        }

        const isMetaDuplicate = metaAdsProductOptionIDs.some((id) =>
          existingMetaValues.has(id)
        );

        if (isMetaDuplicate) {
          this.showToast(
            "Error",
            "Please add unique Meta Ads Product Option!",
            "danger"
          );
          return;
        }

        // Add the new entry
        const newEntry = {
          name: this.projectValue, // Assuming "Value" represents the project name
          channelInfo: {
            googleAds: {
              accountID: this.selectedGoogleAccountSelected,
              productOptionIDs: googleAdsProductOptionIDs, // Use extracted values
            },
            meta: {
              accountID: this.selectedFacebookAccountSelected,
              productOptionIDs: metaAdsProductOptionIDs, // Use extracted values
            },
          },
          values:this.selectedValues
        };

        this.projectCreateOptions.push(newEntry);
        this.projectValue = "";
        this.selectedGoogleAccountSelected = null;
        this.selectedFacebookAccountSelected = null;
        this.selectedGoogleAds = [];
        this.selectedMetaAds = [];
        this.selectedValues = []
        this.showToast("Success", "Entry added successfully!", "success");
      } else {
        this.showToast("Error", "Please fill all fields!", "danger");
      }
    },

    handleChannelType() {
      this.fetchProductOptions();
    },
    fetchProjectsAll() {
      this.isFetchProjects = true;
      axios
        .get(
          // `${process.env.VUE_APP_SERVER_URL}/api/projects/?clientID=661674af15a991dc751dda34`,
          `${process.env.VUE_APP_SERVER_URL}/api/projects/?clientID=${this.clientID}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.allProjects = response && response.data && response.data.data;
          this.isFetchProjects = false;
        })
        .catch((error) => {
          this.isFetchProjects = false;
          console.log(error);
        });
    },
    fetchProductOptions() {
      this.isProductOptionsFetched = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/product-options?channel=${this.channelType}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.productOptions = response && response.data && response.data.data;
          this.isProductOptionsFetched = false;
        })
        .catch((error) => {
          this.isProductOptionsFetched = false;
          console.log(error);
        });
    },
    fetchGropupProjects() {
      this.isGroupProductFetched = true;
      axios
        .get(
          // `${process.env.VUE_APP_SERVER_URL}/api/projects/groups?clientID=661674af15a991dc751dda34`,
          `${process.env.VUE_APP_SERVER_URL}/api/projects/groups?clientID=${this.clientID}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.accounts =
            response &&
            response.data &&
            response.data.data &&
            response.data.data.groups;
          this.isGroupProductFetched = false;
        })
        .catch((error) => {
          this.isGroupProductFetched = false;
          console.log(error);
        });
    },
    openGproupCreation() {
      this.fetchGropupProjects();
      this.showGroupCreations = true;
    },
    closeGproupCreation() {
      this.groupsCreationsValue = []
      this.showGroupCreations = false;
    },
    openProductOptionsCreation() {
      this.showOptionsCreations = true;
    },
    closeProductOptionsCreation() {
      this.productCreateOptions = []
      this.showOptionsCreations = false;
    },
    openProjectCreation() {
      this.showProjectCreations = true;
    },
    closeProjectCreation() {
      this.projectValue = ""
      this.selectedFacebookAccountSelected = []
      this.selectedGoogleAds = []
      this.selectedGoogleAccountSelected = []
      this.projectCreateOptions = []
      this.selectedMetaAds = []
      this.projectCreateOptionsValue = []
      this.showProjectCreations = false;
    },
    showProducts(product) {
      this.productLists = product.productOptionIDs;
      this.showProductOptions = true;
    },
    showProjects(projects) {
      this.projectLists = projects;
      this.showProjectOptions = true;
    },
    closeProjects() {
      this.productLists = [];
      this.showProjectOptions = false;
    },
    closeProducts() {
      this.productLists = [];
      this.showProductOptions = false;
    },
    changeTab(type) {
      this.projectCreationTabType = type;
    },
    closeProjectDeleteModal() {
      this.projectDeleteModal = false;
    },
    openProjectDeleteModal(projectId) {
      this.deleteProjectID = projectId;
      this.projectDeleteModal = true;
    },
    closeOptionsDeleteModal() {
      this.optionsDeleteModal = false;
    },
    openOptionsDeleteModal(projectId) {
      this.deleteOptionsID = projectId;
      this.optionsDeleteModal = true;
    },
  },
  computed: {
    isGroupButtonDisabled() {
      return (
        !this.groupName ||
        !this.groupTag ||
        this.selectedGroupProjects.length === 0
      );
    },
    allAdsValues() {
      return this.allProjects.map((rule) => ({
        name: rule.name,
        value: rule._id,
      }));
    },
    googleAdsValues() {
      return this.productOptions
        .filter((rule) => rule.channel === "googleAds")
        .map((rule) => ({
          name: rule.rule.value,
          value: rule._id,
        }));
    },
    metaValues() {
      return this.productOptions
        .filter((rule) => rule.channel === "meta")
        .map((rule) => ({
          name: rule.rule.value,
          value: rule._id,
        }));
    },
    combinedFacebookAdsAccount() {
      const { adAccountNames, adAccountIDs } = this.facebookAdsAccount;
      return adAccountNames.map((name, index) => {
        return {
          value: adAccountIDs[index] || null,
          text: name,
        };
      });
    },
    combinedGoogleAdsAccount() {
      const { customerNames, customerClientIDs } = this.googleAdsAccounts;
      return customerNames.map((name, index) => {
        return {
          value: customerClientIDs[index] || null,
          text: name,
        };
      });
    },
    isOptionButtonDisabled() {
      return !this.formData.rule.value || !this.formData.channel;
    },
    isProjectCreationsDisabled(){
      return this.selectedMetaAds.length === 0 || this.selectedGoogleAds.length === 0 || this.selectedFacebookAccountSelected.length === 0 || this.selectedGoogleAccountSelected.length === 0 || this.projectValue.length === 0
    },
    productOptionsCol() {
      let tableColumns = [
        // {
        //   label: "Rule Type",
        //   field: "ruleType",
        // },
        {
          label: "Value",
          field: "rule.value",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // {
        //   label: "Field",
        //   field: "rule.field",
        // },
        {
          label: "Channel",
          field: "channel",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Action",
          field: "-",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ];
      return tableColumns;
    },
    projectsListCol() {
      let tableColumns = [
        {
          label: "Name",
          field: "name",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Google Ads",
          field: "channelInfo.googleAds.accountID",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Meta Ads",
          field: "channelInfo.meta.accountID",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ];
      return tableColumns;
    },
    productListCol() {
      let tableColumns = [
        // {
        //   label: "Rule Type",
        //   field: "ruleType",
        // },
        {
          label: "Value",
          field: "rule.value",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // {
        //   label: "Field",
        //   field: "rule.field",
        // },
        {
          label: "Channel",
          field: "channel",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // {
        //   label: "Rule",
        //   field: "ruleType",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
      ];
      return tableColumns;
    },
    allProjectsCol() {
      let tableColumns = [
        {
          label: "Name",
          field: "name",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Meta-Ads",
          field: "channelInfo.meta.accountID",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Google-Ads",
          field: "channelInfo.meta.accountID",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Action",
          field: "-",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ];
      return tableColumns;
    },
    allAccountsCol() {
      let tableColumns = [
        {
          label: "Name",
          field: "name",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Tag",
          field: "tag",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Action",
          field: "-",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ];
      return tableColumns;
    },
    groupsCreationsCol() {
      let tableColumns = [
        {
          label: "Group",
          field: "name",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Tag",
          field: "tag",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Project ID",
          field: "projectIDs",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ];
      return tableColumns;
    },
    productCreateOptionsCol() {
      let tableColumns = [
        {
          label: "Channel",
          field: "channel",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Value",
          field: "rule.value",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Action",
          field: "-",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ];
      return tableColumns;
    },
  },
  mounted() {
    this.projectCreationTabType = "Groups";
    this.formData.channel = "all";
    this.fetchProductOptions();
    this.fetchProjectsAll();
    this.fetchGropupProjects();
  },
};
</script>

<style lang="scss">
.project-creation-header{
  font-size: 14px; font-weight: bold; color: #200E6B;
}
.project-select-size {
  width: 240px;
  text-transform: capitalize;
}

.groupProject {
  width: 190px;
  text-transform: capitalize;
}

.project-accounts-select-size {
  width: 208px;
  text-transform: capitalize;
}

.tabs-flex {
  display: flex;
  padding: 3px 0px;
  align-items: center; 
  gap: 5px;
  justify-content: space-between;
}

.groupsContainer {
  align-items: center;
  justify-content: center;
}

.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.product-options-container {
  margin-top: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.options-header {
  margin-bottom: 15px;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  font-size: 1.2rem;
}

.product-option-card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.product-option-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 5px;
}

.option-index {
  font-size: 0.9rem;
  font-weight: bold;
  color: #666;
}

.remove-option-button {
  font-size: 0.8rem;
}

.product-option-details {
  padding: 5px 0;
}

.product-option-details p {
  margin: 0;
  font-size: 0.9rem;
  color: #444;
}

.product-option-details strong {
  color: #333;
}

.project-creation-container {
  .project-options-container {
    padding: 0px;
    border-radius: 8px;
  }

  .section-header {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
    border-bottom: 2px solid #007bff;
    padding-bottom: 5px;
  }

  .client-card {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .client-header {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 15px;
    color: #0056b3;
  }

  .project-card {
    margin-bottom: 15px;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 6px;
    border-left: 4px solid #007bff;
  }

  .project-title {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 10px;
    color: #333;
  }

  .channel-container {
    margin-top: 15px;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  .channel-header {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #444;
  }

  .channel-info {
    margin-bottom: 15px;
  }

  .channel-info.google-ads {
    border-left: 4px solid #28a745;
    padding-left: 10px;
  }

  .channel-info.meta-channel {
    border-left: 4px solid #6c757d;
    padding-left: 10px;
  }

  .channel-name {
    font-size: 0.9rem;
    font-weight: 500;
    color: #007bff;
  }

  .channel-detail {
    margin: 5px 0;
    font-size: 0.9rem;
    color: #555;
  }

  .channel-value {
    font-weight: bold;
  }

  .product-ids-label {
    margin-top: 10px;
    font-size: 0.85rem;
    font-weight: 600;
    color: #555;
  }

  .product-ids-list {
    list-style-type: disc;
    padding-left: 20px;
    margin: 5px 0;
  }

  .product-id {
    font-size: 0.85rem;
    color: #333;
  }

  /* Utility Classes for Consistency */
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    margin-bottom: 5px;
  }

  p {
    margin: 0;
    padding: 0;
  }
}

/* Responsiveness */
@media (max-width: 1024px) {
  .tabs-flex {
    flex-wrap: wrap;
    /* Ensure items wrap */
    gap: 10px;
    /* Adjust spacing */
    justify-content: flex-start;
    /* Align items to the left */
  }

  .tabs-flex>div {
    flex: 1 1 45%;
    /* Two columns on medium screens */
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .tabs-flex {
    flex-direction: column;
    /* Stack items vertically */
    gap: 15px;
  }

  .tabs-flex>div {
    flex: 1 1 100%;
    /* Full width for child elements */
    max-width: 100%;
    /* Ensure proper scaling */
  }

  .project-select-size,
  .project-accounts-select-size {
    width: 100%;
    /* Inputs and selects take full width */
  }

  label {
    font-size: 0.9rem;
    /* Adjust label size */
  }

  .section-header {
    font-size: 1.25rem;
  }

  .project-title {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .tabs-flex {
    gap: 10px;
    /* Reduce gaps for smaller screens */
  }

  label {
    font-size: 0.8rem;
    /* Adjust label size */
  }

  .section-header {
    font-size: 1rem;
  }

  .project-title {
    font-size: 0.9rem;
  }

  b-button {
    font-size: 0.85rem;
    /* Adjust button text size */
  }
}
.custom-vue-select-project-creation .vs__selected-options {
  display: flex !important
;
  overflow-x: auto !important;
  white-space: nowrap;
  flex-wrap: nowrap !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
