<template>
  <div>
    <b-row class="match-height headerContainer mt-4">
      <b-col cols="5" class="dateContainer">
        <b-card class="headerContainerItem">
          <p class="app-title date-range mb-0 mt-2 ">Showing Data for {{ this.dateRange.startDate | date }} to
            {{ this.dateRange.endDate | date }}</p>
          <date-range-picker ref="picker" opens="right" :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :showWeekNumbers="false" :showDropdowns="true" :autoApply="true" v-model="dateRange" @update="updateValues"
            :dateFormat="dateFormat" control-container-class="picker-controller" :ranges="dateRanges"
            :auto-apply="false" :readonly="false" :maxDate="new Date(new Date().setDate(new Date().getDate() - 1))"
            class="leadSquaredUIbreak">
            <template v-slot:input="picker">
              <feather-icon icon="CalendarIcon" size="16" class="mr-1"></feather-icon>
              {{ formatDateDisplay(picker.startDate) }} -
              {{ formatDateDisplay(picker.endDate) }}
            </template>
          </date-range-picker>
        </b-card>
      </b-col>
      <b-col cols="7" class="text-center comparison dateContainer">
        <CardStaticsGroupSalesforce :summaryData="chartSummaryData" :iconType="iconCardType"
          :businessType="businessType" :adRunningError="adsError" :isDataFetching="this.groupCardFlag" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, BLink, BCardBody } from "bootstrap-vue";
import CardStaticsGroupSalesforce from "./CardStaticsGroupSalesforce.vue";
import vSelect from "vue-select";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
// import TriggerModalAll from '@/components/client/TriggerModalAll.vue'

export default {
  components: {
    // TriggerModalAll,
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
    vSelect,
    BCardBody,
    DateRangePicker,
    CardStaticsGroupSalesforce
    // CardStatisticsGroup
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
    businessType: {
      type: Array
    },
    iconCardType: { type: String },
    client: {
      type: String,
      required: true,
    },
    chartSummaryData: {
      type: null
    },
    buttonSize: {
      type: String,
    },
    iconSize: {
      type: String,
    },
    adsError: {
      type: String
    },
    groupCardFlag: {
      type: Boolean
    }
  },
  computed: {
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let last7thDay = new Date();
      last7thDay.setDate(today.getDate() - 7);
      last7thDay.setHours(0, 0, 0, 0);

      let last14thDay = new Date();
      last14thDay.setDate(today.getDate() - 14);
      last14thDay.setHours(0, 0, 0, 0);

      let last30thDay = new Date();
      last30thDay.setDate(today.getDate() - 30);
      last30thDay.setHours(0, 0, 0, 0);

      var thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      var thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let ranges = {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        "Last 7 Days": [last7thDay, yesterday],
        "Last 14 Days": [last14thDay, yesterday],
        "Last 30 Days": [last30thDay, yesterday],
        "Last Month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "This Month": [thisMonthStart, thisMonthEnd],
        "This Year": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today),
        ],
      };

      return ranges;
    },
  },
  data() {
    return {
      showModal: false,
      openScheduleModal: false,
      isTriggerModal: false,
      selected: {
        label: "Last 7 days",
        code: "LAST_7_DAYS",
      },
      opens: true,
      dateRange: {
        endDate: moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        startDate: moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD"),
      },
    };
  },
  filters: {
    date(val) {
      return val ? moment(val).format("DD-MM-YYYY") : "";
    }
  },
  methods: {
    sendSchedule() {
      this.clientID = this.client._id;
      this.openScheduleModal = true;
    },
    closeScheduleClientPopUp() {
      this.openScheduleModal = false;
    },
    dateChanged(val) {
      this.$emit("fetchLeads", val.code);
      this.$emit("fetchCampaigns", val.code);
      this.$emit("fetchContacts", val.code);
      this.$emit("fetchReports", val.code);
      this.$emit("fetchDashboard", val.code);
      this.$emit("fetchQuaterlyData", val.code);
      this.$emit("fetchActiveCampaignDataChange", val.code);
      this.$emit("fetchLeadFunnelsAll", val.code);
      this.$emit("fetchLeadFunnelsMeta", val.code);
      this.$emit("fetchLeadFunnelsGoogle", val.code);
      this.$emit("fetchLeadsBySourceLeads", val.code);
      this.$emit("fetchOpportunitiesTrendsData", val.code);
    },
    updateValues(values) {
      let startDate = moment(values.startDate).format("YYYY-MM-DD");
      let endDate = moment(values.endDate).format("YYYY-MM-DD");
      this.$emit("fetchLeads", null, null, null, null, startDate, endDate);
      this.$emit("fetchCampaigns", null, null, null, null, startDate, endDate);
      this.$emit("fetchContacts", null, null, null, null, startDate, endDate);
      this.$emit("fetchReports", null, null, null, null, startDate, endDate);
      this.$emit("fetchDashboard", null, null, null, null, startDate, endDate);
      this.$emit("fetchQuaterlyData", null, null, null, null, startDate, endDate);
      this.$emit("fetchActiveCampaignDataChange", null, null, null, null, startDate, endDate);
      this.$emit("fetchLeadFunnelsAll", null, null, null, null, startDate, endDate);
      this.$emit("fetchLeadFunnelsMeta", null, null, null, null, startDate, endDate);
      this.$emit("fetchLeadFunnelsGoogle", null, null, null, null, startDate, endDate);
      this.$emit("fetchLeadsBySourceLeads", null, null, null, null, startDate, endDate);
      this.$emit("fetchOpportunitiesTrendsData", null, null, null, null, startDate, endDate);
    },
    dateFormat(classes, date) {
      let yesterday = new Date();

      let d1 = moment(date).format("DD-MM-YYYY");
      let d2 = moment(yesterday.setDate(yesterday.getDate() - 1)).format(
        "DD-MM-YYYY"
      );

      // TODO commmented for date 2cross
      // if (!classes.disabled) {
      //   classes.disabled = d1 === d2;
      // }
      return classes;
    },
    formatDateDisplay(date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
  mounted() {
    if (this.client == '661674af15a991dc751dda34') {
      this.dateRange = {
        endDate: moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        startDate: moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD"),
      }
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

// Date selector UI break fixes.
.leadSquaredUIbreak {

  .daterangepicker .calendar-table td,
  .daterangepicker .calendar-table th {
    white-space: nowrap;
    vertical-align: middle;
    min-width: 44px !important;
    width: 41px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
  }

  .daterangepicker .monthselect {
    margin-right: 0.2rem;
    margin-left: -1.2rem;
  }

  .daterangepicker.show-ranges.show-weeknumbers[data-v-1ebd09d2],
  .daterangepicker.show-ranges[data-v-1ebd09d2] {
    min-width: 840px !important;
  }

  .daterangepicker.ltr .drp-calendar.left {
    clear: left !important;
    margin-right: 15% !important;
  }
}

.dateContainer {
  padding: 6px !important;
}

.headerContainer {
  margin-top: 10px !important;
}

.comparison-dashboard-link {
  font-size: 16px;
  font-weight: 500;
  color: #200e6b;
}

.vue-daterange-picker {
  width: 100%;

  svg {
    color: #4d21ff;
  }
}

.reportrange-text {
  display: flex;
  align-items: center;
}

.comparison {
  margin-left: auto;
}

.picker-controller {
  background-color: #f5f6fa;
  padding: 8px;
  margin-top: 6px;
}

.date-range {
  font-size: 11px !important;
}

.disable-scroll {
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .headerContainer {
    display: grid;
    grid-template-columns: auto;
  }

  .headerContainerItem {
    display: flex;
    width: 350px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .comparison {
    margin-left: 0;
    width: 1150px;
  }
}

@media only screen and (max-width: 350px) {
  .headerContainer {
    display: grid;
    grid-template-columns: auto;
  }

  .headerContainerItem {
    display: flex;
    width: 250px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .comparison {
    margin-left: 0;
    width: 850px;
  }
}
</style>