<template>
  <div class="onboard-ui-manager">
    <div class="auth-onboard-manager">
      <div class="auth-onboard-manager-main">
        <div class="manager">
          <header class="header">
            <h3 class="title">Central Dashboard for all your Digital Campaigns</h3>
            <!-- <h2 class="subtitle" style="color: white!important;">Platform for your campaigns</h2> -->
            <p class="description" style="color: white!important;">
              Link your Google Ads, Meta Ads account and get a single view of what campaigns are working best.
            </p>
          </header>
          <div class="image-section">
            <img src="@/assets/images/logo/onboardUI.png" class="onboard-image"/>
          </div>
          <footer class="footer">
            <p class="footer-text" style="color: white!important;">Trusted by 200+ Leading brands and they love us!</p>
            <div class="brands">
              <img
              style="width: 120px;"
              src="@/assets/images/logo/gocolors.webp"
            />
            <img style="width: 80px;" src="@/assets/images/logo/cfs.webp" />
              <img style="width: 120px;" src="@/assets/images/logo/fr.png" />
              <img style="width: 120px;" src="@/assets/images/logo/kl.png" />
              <img style="width: 120px;" src="@/assets/images/logo/tvs.png" />
            </div>
          </footer>
        </div>
      </div>
      <div class="auth-login-section">
        <div class="logo">
          <img style="width: 150px;" src="@/assets/images/logo/black-logo-2024.png" alt="Logo" />
        </div>
        <h3 class="welcome-text">Hi, Welcome Back</h3>
        <p class="subtitle">Enter your credentials to continue</p>
        <div class="mb-2 mt-2">
          <div style="background-color: #F8FAFC;">
            <b-tabs pills block>
              <b-tab title="Login as Client" @click="loginTypeCheck('client')"></b-tab>
              <b-tab title="Login as Team" @click="loginTypeCheck('team')"></b-tab>
            </b-tabs>
          </div>
        </div>
        <div v-if="this.userType === 'client'" class="login-manager">
          <div class="login-box">
            <validation-observer ref="loginValidation" #default="{ invalid }" >
              <form @submit.prevent>
                <!-- Email Input -->
                <div class="form-group">
                  <input type="email" v-model="email" placeholder="Work Email Address" class="sign-in-input" required />
                </div>
                <!-- Password Input -->
                <div class="form-group password-group">
                  <b-input-group
                  class="input-group-merge"
                 
                >
                  <b-form-input
                    id="team-password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    style="padding: 23px 15px;"
                   
                    name="login-password"
                    placeholder="Password"
                  />
    
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                </div>
                <!-- Forgot Password -->
                <div  @click="ForgotPassordRediect" class="forgot-password">
                  <a href="#">Forgot Password?</a>
                </div>
                <!-- Sign In Button -->
                <button type="submit" class="btn-primary"   @click="validateForm">Sign In</button>
                <!-- Sign Up Link -->
                <p class="signup-text" @click="SignUpRediect">
                  Don't have an account?
                  <a href="#" class="signup-link">Free Sign Up</a>
                </p>
              </form>
            </validation-observer>
       
          </div>
        </div>
        <div  v-if="this.userType === 'team'" class="login-manager">
          <div class="login-box">
            <validation-observer ref="loginValidation" #default="{ invalid }" >
              <form @submit.prevent>
                <!-- Email Input -->
                <div class="form-group">
                  <input type="email" v-model="email" placeholder="Work Email Address" class="sign-in-input" required />
                </div>
                <!-- Password Input -->
                <!-- <div class="form-group password-group">
                  <input :type="showPassword ? 'text' : 'password'" v-model="password" placeholder="Password"
                    class="sign-in-input" required />
                  <button type="button" class="password-toggle" @click="togglePassword">
                    <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                  </button>
                </div> -->
                <div class="form-group password-group">
                  <b-input-group
                  class="input-group-merge"
                 
                >
                  <b-form-input
                    id="team-password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    style="padding: 23px 15px;"
                   
                    name="login-password"
                    placeholder="Password"
                  />
    
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                </div>
                <!-- Forgot Password -->
                <div @click="ForgotPassordRediect" class="forgot-password">
                  <a href="#">Forgot Password?</a>
                </div>
                <!-- Sign In Button -->
                <button type="submit" class="btn-primary"   @click="validateForm">Sign In</button>
                <!-- Divider -->
                <p class="divider">Or</p>
                <!-- Google Sign-In -->
                <b-button   @click="googleLogin" size="lg" class="mb-2" block href="javascript:void(0)" variant="outline-secondary">
                  <img src="@/assets/images/logo/google.png" alt="google-logo-login"
                    style="width: 25px; margin-right: 5px;" />
                  <span class="text-xs" style="color: #7D7D7D;">Login with Google</span>
                </b-button>
              </form>
            </validation-observer>
        
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BButton,BTab,BCard ,BTabs,BInputGroup,BFormInput, BInputGroupAppend} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import axios from "axios";

export default {
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userType:"client",
      showPassword:false,
      userEmail: "",
      password: "",
      status: "",
      required,
      email,
      loggingIn: false,
    };
  },
  methods: {
    togglePassword(){
      this.showPassword != this.showPassword
    },
    ForgotPassordRediect(){
      this.$router.push("/forgot-password");
    },
    SignUpRediect(){
      this.$router.push("/create-account");
    },
    loginTypeCheck(type) {
      this.userType = type;
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    validateForm() {
      this.loggingIn = true;
      if (this.userType === '') {
        this.userType = "client"
     }
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          useJwt
            .login({
              email: this.email,
              password: this.password,
              loginType: this.userType
            })
            .then((response) => {
              this.loggingIn = false;
           
              useJwt.setToken(response.data.token);
              useJwt.setUserName(response.data.admin.firstname);
              useJwt.setUserEmail(response.data.admin.email);
              //-------------
              useJwt.setAdminClientEnabled(response.data.admin.configuration.clients);
              useJwt.setAdminDScaleAiEnabled(response.data.admin.configuration.d2scale_ai)
              useJwt.setAdminGMBEnabled(response.data.admin.configuration.gmb.enabled);
              useJwt.setAdminGMBDealersEnabled(response.data.admin.configuration.gmb_for_dealers);
              useJwt.setAdminAccessControlEnabled(response.data.admin.configuration.access_control);
              useJwt.setAdminTriggersControlEnabled(response.data.admin.configuration.triggers);

              useJwt.setAdminGMBGeoEnabled(response.data.admin.configuration.gmb.items.geo)
              useJwt.setAdminGMBSummaryEnabled(response.data.admin.configuration.gmb.items.summary)
              useJwt.setAdminGMBOrganicEnabled(response.data.admin.configuration.gmb.items.organic)
              useJwt.setAdminGMBPostEnabled(response.data.admin.configuration.gmb.items.posts)
              useJwt.setAdminGMBPaidEnabled(response.data.admin.configuration.gmb.items.paid)
              useJwt.setAdminGMBReviewEnabled(response.data.admin.configuration.gmb.items.reviews)
              useJwt.setAdminGMBStoreEnabled(response.data.admin.configuration.gmb.items.stores)
              useJwt.setAdminImageUrl( response.data && response.data.admin && response.data.admin.adminProfileImageUrl && response.data.admin.adminProfileImageUrl.s3URL)
              useJwt.setAdminID( response.data && response.data.admin._id)
              useJwt.setAdminLastName( response.data && response.data.admin && response.data.admin.lastname)
              useJwt.setAdminFirstName( response.data && response.data.admin && response.data.admin.firstname)
              // useJwt
              // window.location.reload(true);
              if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect);
              } else {
                this.$router.push("/dashboard");
              }
            })
            .catch((error) => {
              this.loggingIn = false;
              if (error.response.data && error.response.data.error) {
                this.showToast("Error", error.response.data.error, "danger");
                return;
              }

              if (error.response.data && error.response.data.errors) {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                  const error = error.response.data.errors[i];
                  this.showToast(
                    "Error",
                    `${error.msg - error.param}`,
                    "danger"
                  );
                }
                return;
              }

              this.showToast(
                "Unauthorized",
                "Incorrect Email / Password. Please try again.",
                "danger"
              );
            });
        } else {
          this.loggingIn = false;
        }
      });
    },
    googleLogin() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/admin/login/google/init`)
        .then((response) => {
          const url = response.data;
          window.location.replace(url);
        })
        .catch((error) => {
          this.showToast(
            "Error",
            "Couldn't process the request at the moment. Please try again later!",
            "danger"
          );
        });
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  components: {
    BButton,
    BTab,
    BCard,
    BTabs,
    BInputGroup,BFormInput, BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,

  },
  mounted(){
    this.isOnline = window.navigator.onLine;
// Add event listeners to detect online/offline changes
    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  },
  beforeDestroy() {
    // Remove event listeners when the component is destroyed
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
  },
  name: "LoginScreen",
};
</script>

<style lang="scss">
.onboard-image{
  width: 280px;
}
.onboard-ui-manager{
  /* Container Styles */
.auth-onboard-manager {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.auth-onboard-manager-main {
  width: 60%;
  background-color: #704c94;
  padding: 70px;

  background-position: center;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  left: 0px;
  right: 0px;

  .manager {
    text-align: center;
    padding: 20px;
  }

  /* Header Styles */
  .header {
    margin-bottom: 30px;
  }

  .title {
    font-size: 2.5rem;
    color: #ffffff;
  }

  .subtitle {
    font-size: 1.8rem;
    margin: 10px 0;
  }

  .description {
    font-size: 1rem;
    margin: 15px auto;
    max-width: 600px;
  }

  /* Image Section */
  .image-section {
    margin: 30px 0;
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
  }

  .circle-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
  }

  /* Footer Styles */
  .footer {
    margin-top: 20px;
  }

  .footer-text {
    font-size: 1rem;
    margin-bottom: 15px;
  }

  .brands {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .brand-logo {
    max-width: 100px;
  }
}

.welcome-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.welcome-content h1 {
  font-size: 48px;
  margin-bottom: 10px;
}

.welcome-content p {
  font-size: 24px;
}

.auth-login-section {
  width: 40%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.login-box {
  width: 100%;
}

.login-header {
  text-align: center;
  margin-bottom: 20px;
}

.login-header h2 {
  font-size: 24px;
}

.login-form .form-group {
  margin-bottom: 15px;
}

.login-form .form-group label {
  display: block;
  margin-bottom: 5px;
}

.login-form .form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.btn-login {
  width: 100%;
  padding: 10px;
  background-color: #935097;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-login:hover {
  background-color: #903095;
}

.additional-options {
  margin-top: 20px;
  text-align: center;
}

.additional-options a {
  display: block;
  margin: 10px 0;
  text-decoration: none;
  color: #007bff;
}

.additional-options a:hover {
  text-decoration: underline;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ccc;
}

.divider:not(:empty)::before {
  margin-right: 0.25em;
}

.divider:not(:empty)::after {
  margin-left: 0.25em;
}

.social-login-options {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.social-icon {
  font-size: 24px;
  cursor: pointer;
}

.social-icon:hover {
  color: #fff;
}

/* Media Query */
@media (max-width: 800px) {
  .auth-onboard-manager {
    width: 100%;
    height: 200vh;
    display: flex;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .auth-onboard-manager {
    flex-direction: column;
    height: auto;
  }

  .auth-onboard-manager-main,
  .auth-login-section {
    width: 100%;
  }

  .auth-onboard-manager-main {
    height: 200px;
  }

  .welcome-content {
    position: relative;
    transform: none;
    padding: 20px;
    top: 30px;
  }
}

.login-manager {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
}

.login-box {
  width: 100%;
  background: #ffffff;
  text-align: center;
}

.logo img {
  width: 80px;
  margin-bottom: 20px;
}

.welcome-text {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333333;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 0.95rem;
  color: #666666;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
  position: relative;
}

.sign-in-input {
  width: 100%;
  padding: 15px 15px;
  font-size: 0.95rem;
  border: 1px solid #cccccc;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s;
}

.sign-in-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.3);
}

.password-group {
  display: flex;
  align-items: center;
}

.password-toggle {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #666666;
}

.password-toggle i {
  font-size: 1rem;
}

.forgot-password {
  text-align: right;
  margin-bottom: 20px;
}

.forgot-password a {
  color: #007bff;
  font-size: 0.9rem;
  text-decoration: none;
}

.btn-primary {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #ffffff;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 15px;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.divider {
  font-size: 0.9rem;
  color: #666666;
  margin: 15px 0;
}

.btn-secondary {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #cccccc;
  background: #ffffff;
  color: #333333;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-secondary:hover {
  background-color: #f1f1f1;
}

.google-icon {
  width: 18px;
  margin-right: 10px;
}

.signup-text {
  font-size: 0.9rem;
  color: #666666;
}

.signup-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

}


/* Mobile Devices (<= 576px) */
@media (max-width: 576px) {
  .onboard-ui-manager .auth-onboard-manager-main {
    left: 0px;
    right: 0px;
    height: auto;
}
.onboard-image{
  width: 150px;
}
.form-control-merge{
  width: 200px;
}
.brands {
  display: grid !important
;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto auto auto !important;
  gap: 20px;
}
}

/* Tablet Devices (577px - 992px) */
@media (min-width: 577px) and (max-width: 992px) {
  .onboard-ui-manager .auth-onboard-manager-main {
    left: 0px;
    right: 0px;
    height: auto;
   }
   .onboard-image{
    width: 100px;
  }
  .brands {
    display: grid !important
;
    justify-content: center;
    align-items: center;
    grid-template-columns: auto auto auto !important;
    gap: 20px;
  }
}

/* Laptop Devices (993px - 1200px) */
@media (min-width: 993px) and (max-width: 1200px) {
  .onboard-ui-manager .auth-onboard-manager-main {
    left: 0px;
    right: 0px;
    height: auto;
}
.brands {
  display: grid !important
;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto auto auto !important;
  gap: 20px;
}
}

</style>
