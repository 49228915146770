let AffilatedMap = {
    "AffilatedMapOutcome": {
        "default": {
                "spend":"Spends",
                "impressions": "Impression",
                "clicks": "Clicks",
                "ctr": "CTR",
                "conversions": "Leads",
                "cvr": "CVR",
                "cpl": "CPL",
                "cpc": "CPC",
                "cpm": "CPM",
               
        }
    }
  }
  export default AffilatedMap